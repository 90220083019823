import Vue from 'vue'
import store from '../store'
import axios from 'axios'

/* AUTH */

export const login = async data => {
  const { email, password } = data

  return await Vue.core.post(
    '/users/login',
    { email, password },
    {
      headers: {
        'User-Type': 'DMC'
      }
    }
  )
}

export const logout = async () => {
  return await Vue.core.put('/users/logout')
}

export const resetPassword = async email => {
  return await Vue.core.post('/users/reset-password', { email })
}

export const getCurrentUser = async () => {
  return await Vue.core.get('/users/me')
}

export const getCustomers = async keyword => {
  return await Vue.core.post('/queries', {
    microservice: 'CUDIO',
    type: 'DATA',
    model: 'CUSTOMER',
    skip: 0,
    relations: [
      {
        model: 'DISTRIBUTOR'
      },
      {
        model: 'INTEGRATOR'
      }
    ],
    where: {
      name: {
        contains: keyword,
        mode: 'insensitive'
      }
    },
    //   owner: {
    //     name: { contains: owner }
    //   }
    // },
    select: {
      id: true,
      name: true
      // addresses: { include: { country: true } },
      // name: true,
      // createdAt: true,
      // status: true,
      // owner: true,
      // integrator: true
    }
  })
}

export const getSignals = async params => {
  const { page: skip, limit: take, ...query } = params
  let newQuery = {}
  console.log('QUERY:::', query)
  // Object.keys(query).forEach(key => {
  //   const [field, ...paths] = key.split('.')

  //   if (paths.length) {
  //     newQuery[field] = {
  //       path: paths,
  //       equals: query[key]
  //     }
  //   } else {
  //     newQuery[key] = query[key]
  //   }
  // })

  if (query?.premise) {
    newQuery.premise = {
      is: {
        id: query?.premise.id
      }
    }
  }

  return await Vue.core.post('queries', {
    microservice: 'SIGNAL',
    type: 'DATA',
    model: 'SIGNAL',
    where: Object.keys(newQuery).length ? newQuery : undefined,
    orderBy: [{ createdAt: 'desc' }],
    skip: (parseInt(skip) - 1) * take || 0,
    take: parseInt(take) || undefined
  })
}

export const getPremise = async id => {
  return await Vue.core.get(`/premises/${id}`)
}

export const getTask = async id => {
  return await Vue.core.post('/queries', {
    microservice: 'TASK',
    type: 'DATA',
    model: 'TASK',
    where: {
      id
    }
  })
}

export const getCustomerNotes = async id => {
  return await Vue.core.post('/queries', {
    microservice: 'CUDIO',
    type: 'DATA',
    model: 'NOTE',
    orderBy: [{ createdAt: 'desc' }],
    skip: 0,
    relations: [
      {
        model: 'DISTRIBUTOR'
      },
      {
        model: 'INTEGRATOR'
      },
      {
        model: 'CUSTOMER'
      },
      {
        model: 'PREMISE'
      }
    ],
    where: {
      premise: {
        id
      }
    }
  })
}

export const addCustomerNote = async ({ user, premise, ...payload }) => {
  return await Vue.core.post('/notes', {
    ...payload,
    user: {
      connect: {
        id: user
      }
    },
    premise: {
      connect: {
        id: premise
      }
    }
  })
}

export const removeCustomerNote = async id => {
  return await Vue.core.delete(`/notes/${id}`)
}

export const updateTask = async (taskId, payload) => {
  return await Vue.core.patch(`/tasks/${taskId}`, payload)
}

export const getTasks = async params => {
  const { page: skip, limit: take, status, category, ...query } = params
  let newQuery = {}

  Object.keys(query).forEach(key => {
    const [field, ...paths] = key.split('.')
    newQuery[field] = {
      path: paths,
      string_contains: query[key]
    }
  })

  return await Vue.core.post('queries', {
    microservice: 'TASK',
    type: 'DATA',
    model: 'TASK',
    where: {
      ...newQuery,
      status,
      category
    },
    orderBy: [{ createdAt: 'desc' }],
    skip: (parseInt(skip) - 1) * take || 0,
    take: parseInt(take) || undefined
  })
}

export const assignTask = async id => {
  return await Vue.core.get(`/tasks/${id}/assign`)
}

export const unassignTask = async id => {
  return await Vue.core.get(`/tasks/${id}/unassign`)
}

export const postponeTask = async ({ id, payload }) => {
  return await Vue.core.patch(`/tasks/${id}/postpone`, payload)
}

export const startCall = async (phoneNumber, taskId) => {
  return Vue.core.post(`/tasks/${taskId}/call`, {
    phoneNumber
  })
}

export const endCall = async (phoneNumber, taskId) => {
  return Vue.core.post(`/tasks/${taskId}/call`)
}

export const getIntegrator = async id => {
  return await Vue.core.get(`/integrators/${id}`)
}

export const getAlotechSessionKey = async mail => {
  return await axios.get(
    `https://biges.alo-tech.com/api/?function=login&email=${mail}&app_token=ahRzfm11c3RlcmktaGl6bWV0bGVyaXIfCxISVGVuYW50QXBwbGljYXRpb25zGICAtMegnYMLDKIBEmJpZ2VzLmFsby10ZWNoLmNvbQ`
  )
}
export const createTicket = async payload => {
  return await Vue.core.post('/tickets', payload)
}

export const addNoteToActions = async ({ id, note }) => {
  return await Vue.core.post(`/tasks/${id}/actions`, {
    type: 'NOTE',
    description: note
  })
}
