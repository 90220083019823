import Vue from 'vue'

export default {
  namespaced: true,
  actions: {
    createTicket(_, data) {
      return Vue.prototype.$logs.post(`/tickets`, data)
    }
  }
}
