<template>
  <div>
    <TableAtomic
      :rows="array"
      :columns="columns"
      :loading="loading"
      :options="{ column: { icon: false } }"
      :emptyMessage="$t('signals.no-signal')"
      :styles="{ borderBottom: '1px solid #cecece', color: '#fff' }"
    >
      <template slot="row">
        <tr
          @click="$emit('onClick', item)"
          v-for="(item, index) in array"
          :key="index + 'row'"
          class="grid-table__line"
          :style="taskColor(item.category)"
        >
          <td>
            <span class="text__primary">
              {{
                dateConvertToFriendlyString(
                  item.createdAt,
                  $store.state.auth.user.timezone
                )
              }}
            </span>
          </td>
          <td>
            <p class="text__primary">
              {{ item.customer.name }}
            </p>
          </td>
          <td></td>
          <td>
            <p class="text__primary">
              {{ item.premise.name }}
            </p>
          </td>
          <td>
            <p v-if="item.device" class="text__primary">
              {{ $t(`hardware_type.${item.device.type}`) }}
              <!-- {{ item.device.hardware_type }} -->
            </p>
          </td>
          <td>
            <!-- <span v-if="item.data">
              {{ item.description }}
              <br />
              <span v-if="item.data.partition && item.data.partition_name">
                {{ $t('words.partition') }}: {{ item.data.partition }} /
                {{ item.data.partition_name }}
              </span>
              <br />
              <span v-if="item.data.user">
                {{ $tc('words.user', 1) }}:
                <span style="text-transform:capitalize">
                  {{ item.data.user }}
                </span>
              </span>
            </span> -->
            <span>{{ item.description }}</span>
          </td>
          <td></td>

          <td>
            <span class="highlight-border" style="text-align:center">
              {{ $t(`signals.${item.category}`) }}
            </span>
          </td>
          <td></td>
        </tr>
      </template>
    </TableAtomic>
  </div>
</template>

<script>
import { TableAtomic } from '@/plugins/components'

export default {
  name: 'TableSignals',
  props: {
    array: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    array(val) {
      console.log(val)
    }
  },

  data() {
    return {
      columns: {
        created_at: this.$t('form.signal-date'),
        name: this.$tc('words.customer-name', 2),
        empty: '',
        premise: this.$tc('words.premise', 1),
        device: this.$tc('words.device', 1),
        description: this.$t('words.description'),
        empty2: '',
        signal_category: this.$t('form.signal-category'),
        '': ''
      }
    }
  },
  methods: {
    handleClickRowEdit(rowItem) {
      this.$emit('onEdit', rowItem)
    },
    taskColor(category) {
      // "arm"
      // "burglary"
      // "panic"
      // "duress"
      // "tamper"
      // "health"
      // "fire"
      // "gas"
      // "flood"
      // "heat"
      // "hardware"
      // "test"

      let color = '#ffffff00'
      if (category === 'arm') {
        color = '#f8678440'
      } else if (category === 'healt_panic') {
        color = '#8c89e850'
      } else if (category === 'burglary_alarm') {
        color = '#8c89e860'
      } else if (category === 'hardware') {
        color = '#ff9e5f20'
      } else if (category === 'heat') {
        color = '#5ed3ee20'
      } else if (category === 'healt') {
        color = '#cccccc20'
      }
      return {
        backgroundColor: color
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.grid-table {
  &__line {
    min-height: auto !important;
    padding: 10px 20px !important;
  }
}
</style>
