<template>
  <div class="action-card">
    <div class="action-card__icon">
      <img
        v-if="['note', 'postponed'].includes(data.type)"
        :src="require(`@/assets/image/operator-action-${data.type}.svg`)"
      />
      <img v-else src="@/assets/image/operator-action-redirect.svg" alt="" />
    </div>
    <div class="action-card__content">
      <div class="action-card__header">
        <p class="action-card__header__primary-text">
          {{ data.user.name }}
        </p>
        <p class="action-card__header__secondary-text">
          {{ data.createdAt | ago($store.state.locale.lang) }}
        </p>
      </div>
      <div class="action-card__description">
        <p v-if="actionType">
          <span>{{ actionType }}</span>
          <span v-if="data.description"><br />{{ data.description }}</span>
        </p>
        <p v-if="data.dialed_number" style="margin-top:5px">
          <b>{{ $t('operation.past-action.dialed_number') }}:</b>
          {{ data.dialed_number }}
        </p>
        <div
          v-if="data.service_provider_call_recording_url"
          style="margin-top:5px"
        >
          <b>{{ 'Görüşme Kaydı' }}:</b>
          <span @click="isShowRecord = !isShowRecord" class="btn-audio">
            {{ !isShowRecord ? 'Göster' : 'Gizle' }}
          </span>
          <div v-if="isShowRecord" style="padding-top:5px">
            <audio controls class="audio">
              <source :src="recordURL" type="audio/mpeg" />
            </audio>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'ActionCard',
  data() {
    return {
      recordURL: '',
      isShowRecord: false
    }
  },
  props: {
    data: {}
  },
  methods: {
    ...mapActions({
      getCallRecord: 'observation/getCallRecord'
    })
  },
  computed: {
    ...mapState('auth', ['token']),
    actionType() {
      if (this.data.type) {
        if (this.$t(`operation.past-action.type.${this.data.type}`)) {
          return this.$t(`operation.past-action.type.${this.data.type}`)
        } else {
          return this.data.type
        }
      } else {
        return null
      }
    },
    operator() {
      if (this.data.user.first_name || this.data.user.last_name) {
        return `${this.data.user.first_name} ${this.data.user.last_name}`
      } else {
        return this.$t(`operation.past-action.${'System'}`)
      }
    }
  },
  created() {
    if (this.data.service_provider_call_recording_id) {
      let data = {
        taskId: this.$route.params.id,
        token: this.token,
        uudi: this.data.service_provider_call_recording_id
      }
      this.getCallRecord({
        taskId: this.$route.params.id,
        token: this.token,
        uuid: this.data.service_provider_call_recording_id
      }).then(resp => {
        if (resp.data.success === true) this.recordURL = resp.data.recording_url
      })
    }
    this.interval = setInterval(() => this.$forceUpdate(), 1000)
  },
  filters: {
    ago(value, lang) {
      return moment(value)
        .locale(lang)
        .format('DD.MM.Y, HH:mm')
    }
  }
}
</script>
<style lang="scss" scoped>
.audio {
  height: 25px !important;
}
.btn-audio {
  cursor: pointer;
  color: var(--color-blue);
  &:hover {
    text-decoration: underline;
  }
}
</style>
