<template>
  <div class="event-detail-content">
    <div class="event-detail-content__left">
      <!-- header -->
      <div class="event-detail-content__header">
        <p class="event-detail-content__badge" v-on:click="test">
          {{ taskCategoryNaming(data.category) }}
          <!-- <span v-if="data.zone && data.zone.name">- {{ data.zone.name }}</span>
          <span v-if="data.area && data.area.name">- {{ data.area.name }}</span> -->
        </p>
        <p class="event-detail-content__time">
          <img
            src="@/assets/image/clock-stroke.svg"
            class="event-detail-content__icon"
          />
          {{ dateConvertToMonthWithTime(data.createdAt) }}
        </p>
      </div>

      <!-- content -->
      <div class="event-detail-content__column">
        <div>
          <!-- account manager -->
          <div class="event-detail-content__table-row">
            <p class="event-detail-content__table-row__key">
              {{ $tc(`words.customer`, 1) }}:
            </p>
            <p class="event-detail-content__table-row__value">
              <a
                v-if="data.customer"
                class="dist-link"
                :href="createUrl('customers', data.customer.id)"
                target="_blank"
                rel="noopener noreferrer"
              >
                {{ data.customer.name }}
              </a>
            </p>
          </div>

          <!-- <div v-if="data.subscriber" class="event-detail-content__table-row">
            <p class="event-detail-content__table-row__key">
              {{ $t(`informed.subscriber`) }}:
            </p>
            <p class="event-detail-content__table-row__value">
              {{ data.subscriber }}
            </p>
          </div> -->

          <!-- premise -->
          <div class="event-detail-content__table-row">
            <p class="event-detail-content__table-row__key">
              {{ $tc('words.premise', 1) }}:
            </p>
            <p class="event-detail-content__table-row__value">
              {{ $t(`premiseTypes.${data.premise.type}`) }} /
              {{ data.premise.name }} - {{ data.area && data.area.name }}
            </p>
          </div>

          <!-- address -->
          <div class="event-detail-content__table-row">
            <p class="event-detail-content__table-row__key">
              {{ $t(`words.address`) }}:
            </p>
            <p class="event-detail-content__table-row__value">
              {{ data.premise.address.city }} /
              {{ data.premise.address.country }}
            </p>
          </div>

          <div
            v-if="data.premise.address.name"
            class="event-detail-content__table-row"
          >
            <p class="event-detail-content__table-row__key">
              {{ $t(`form.address-name`) }}:
            </p>
            <p class="event-detail-content__table-row__value">
              {{ data.premise.address.name }}
            </p>
          </div>

          <!-- security type -->
          <div
            v-if="data.premise && data.premise.securityType"
            class="event-detail-content__table-row"
          >
            <p class="event-detail-content__table-row__key">
              {{ $t(`securityTypes.label`) }}:
            </p>
            <p class="event-detail-content__table-row__value">
              {{ $t(`securityTypes.${data.premise.securityType}`) }}
            </p>
          </div>

          <!-- device -->
          <div v-if="data.device" class="event-detail-content__table-row">
            <p class="event-detail-content__table-row__key">
              {{ $tc(`words.device`, 1) }}:
            </p>
            <p class="event-detail-content__table-row__value">
              {{ $t(`hardware_type.${data.device.type}`) }}
              {{ data.device.vendor }} -
              {{ data.device.model }}
            </p>
          </div>

          <div
            v-if="
              data.device.type === 'INTRUSION' &&
                data.signal.data &&
                data.signal.data.partition_name &&
                data.signal.data.partition
            "
            class="event-detail-content__table-row"
          >
            <p class="event-detail-content__table-row__key">
              {{ $tc(`words.partition`, 1) }}:
            </p>
            <p class="event-detail-content__table-row__value">
              ({{ data.signal.data.partition }})
              {{ data.signal.data.partition_name }}
            </p>
          </div>

          <div
            v-if="
              data.device.type === 'INTRUSION' &&
                data.signal.data &&
                data.signal.data.zone_name &&
                data.signal.data.zone
            "
            class="event-detail-content__table-row"
          >
            <p class="event-detail-content__table-row__key">
              {{ $tc(`words.zone`, 1) }}:
            </p>
            <p class="event-detail-content__table-row__value">
              ({{ data.signal.data.zone }}) {{ data.signal.data.zone_name }}
            </p>
          </div>

          <!-- ticket -->
          <!-- <div class="event-detail-content__table-row">
            <p class="event-detail-content__table-row__key">
              {{ $t(`words.technic-service`) }}:
            </p>
            <img
              v-if="isLoadingTicket"
              class="loader dark"
              size="16"
              src="@/assets/image/loader.png"
            />
            <p
              v-if="!isLoadingTicket"
              class="event-detail-content__table-row__value"
            >
              <span v-if="ticket.total > 0" class="dot-green"></span>
              {{
                ticket.total > 0
                  ? $tc(`words.total-ticket-count`, 1, { count: ticket.total })
                  : $t('words.none')
              }}
            </p>
          </div> -->

          <!-- notes -->
          <!-- <div class="event-detail-content__subtext">
            <span
              v-if="data.account && data.account.note"
              class="event-detail-content__subtext__item"
            >
              {{ data.account.note }}
            </span>
          </div> -->
        </div>
        <div class="event-detail-content__middle-line"></div>
        <div>
          <div class="event-detail-content__table-row">
            <p class="event-detail-content__table-row__key">
              {{ $tc(`words.seller`, 1) }}:
            </p>
            <p class="event-detail-content__table-row__value">
              <a
                class="dist-link"
                :href="createUrl('integrators', data.integrator.id)"
                target="_blank"
                rel="noopener noreferrer"
              >
                {{ data.integrator.title }}
              </a>
            </p>
          </div>
          <div class="event-detail-content__table-row">
            <p class="event-detail-content__table-row__key">
              {{ $tc(`words.name`, 1) }}:
            </p>
            <p class="event-detail-content__table-row__value">
              {{ data.integrator.name }}
            </p>
          </div>
          <div class="event-detail-content__table-row">
            <p class="event-detail-content__table-row__key">
              {{ $tc(`words.telephone`, 1) }}:
            </p>
            <p class="event-detail-content__table-row__value">
              {{ data.integrator.phonenumber }}
            </p>
          </div>
          <div class="event-detail-content__table-row">
            <p class="event-detail-content__table-row__key">
              {{ $tc(`words.email`, 1) }}:
            </p>
            <p class="event-detail-content__table-row__value">
              {{ data.integrator.email }}
            </p>
          </div>
          <div class="event-detail-content__table-row">
            <p class="event-detail-content__table-row__key">
              {{ $tc(`words.attendant-name`, 1) }}:
            </p>
            <p
              v-if="data.authorized"
              class="event-detail-content__table-row__value"
            >
              {{ data.authorized.name }}
            </p>
          </div>
          <div v-if="data.authorized" class="event-detail-content__table-row">
            <p class="event-detail-content__table-row__key">
              {{ $tc(`words.attendant-phone`, 1) }}:
            </p>
            <p class="event-detail-content__table-row__value">
              {{ data.authorized.phoneNumber }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ObservationEventDetailContent',
  props: {
    data: {},
    isTicketCreated: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    isTicketCreated: function() {
      if (this.isTicketCreated) {
        this.ticket.total++
        this.$emit('onUpdated', false)
      }
    }
  },
  data() {
    return {
      isLoadingTicket: true,
      ticket: {
        total: 0,
        data: []
      }
    }
  },
  computed: {
    taskColor() {
      let color = '#fff'
      if (this.data.category === 'fire') {
        color = '#f86784'
      } else if (this.data.category === 'security') {
        color = '#8c89e8'
      } else if (this.data.category === 'ambulance') {
        color = '#ff9e5f'
      } else if (this.data.category === 'technic') {
        color = '#5ed3ee'
      } else if (this.data.category === 'other') {
        color = '#ccc'
      }
      return color
    }
  },
  methods: {
    createUrl(type, id) {
      const env = process.env.NODE_ENV
      const path = `${type}/${id}`

      if (env === 'production') {
        return `https://distributor.hybrone.com/${path}`
      } else if (env === 'development') {
        return `https://test.distributor.hybrone.com/${path}`
      }

      return `https://${env}.distributor.hybrone.com/${path}`
    },
    test() {
      console.log(this.data)
    }
  },
  created() {
    // let status = ['new,team_sent,waiting', 'not_contact_with_customer']
    // let params = new URLSearchParams()
    // params.append('premise_id', this.data.premise.id)
    // params.append('statuses', status.join())
    // params.append('limit', 1)
    // this.$store
    //   .dispatch('customer/getCustomerTickets', params)
    //   .then(r => {
    //     this.ticket.total = r.headers?.total || 0
    //     this.ticket.data = r.data || []
    //     this.isLoadingTicket = false
    //   })
    //   .catch(() => {
    //     this.isLoadingTicket = false
    //   })
  }
}
</script>

<style lang="scss" scoped>
.dist-link {
  color: var(--button-primary);

  &:hover {
    text-decoration: underline;
  }
}
</style>
