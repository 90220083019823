<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 10C1 5.03562 5.03562 1 10 1C14.9644 1 19 5.03562 19 10C19 14.9644 14.9644 19 10 19C5.03562 19 1 14.9644 1 10Z"
      :stroke="fill"
      stroke-width="2"
    />
    <path
      d="M7 9.5L9.5 12.5L14.5 7"
      :stroke="fill"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
<script>
export default {
  name: 'SvgIconSignal',
  props: {
    size: {
      type: Number,
      default: 20
    },
    fill: {
      type: String,
      default: '#000'
    }
  }
}
</script>
