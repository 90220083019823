<template>
  <div class="page-inner">
    <span
      class="pusher-status"
      :class="{
        'pusher-status--active': status.success,
        'pusher-status--error': status.error
      }"
    >
      {{ $tc('words.pusher-status', status.code) }}
    </span>
    <div class="wrapper --padding-bottom-m" style="margin-top: 60px">
      <table-past-signals :array="sortedSignals"></table-past-signals>
    </div>
  </div>
</template>

<script>
import { io } from 'socket.io-client'
export default {
  name: 'LiveSignals',
  data() {
    return {
      status: {
        code: 1,
        success: false,
        error: false
      },
      signals: [],
      socket: null
    }
  },
  computed: {
    sortedSignals() {
      return this.signals.sort((a, b) => {
        const dateA = new Date(a.createdAt)
        const dateB = new Date(b.createdAt)
        return dateB - dateA
      })
    }
  },
  methods: {
    receivedMessage(signal) {
      const signalIndex = this.signals.indexOf(s => s.id === signal.id)

      if (signalIndex >= 0) {
        this.signals[signalIndex] = signal
      } else {
        if (signal.createdAt.charAt(signal.createdAt.length - 1) !== 'Z') {
          signal.createdAt += 'Z'
        }
        this.signals.push(signal)
      }
    }
  },
  created() {
    // Socket
    this.socket = io(`${process.env.VUE_APP_CORE_URL}:8010`, {
      transports: ['websocket', 'polling', 'flashsocket'],
      auth: { token: `Bearer ${this.$store.state.auth.user.token}` }
    })
    this.socket.emit('msgToSignalServerDMC', this.$store.state.auth.user.dmcId)

    this.socket.on('msgToSignalClientDMC', message => {
      console.log(message)
      this.receivedMessage(message.data)
    })

    // On Connect
    this.socket.on('connect', () => {
      this.status = {
        code: 0,
        success: true,
        error: false
      }
    })

    // On Connect Error
    this.socket.on('connect_error', () => {
      this.status = {
        code: 2,
        success: false,
        error: true
      }
    })
  },
  destroyed() {
    this.socket.disconnect()
  }
}
</script>

<style lang="scss" scoped>
.pusher-status {
  position: absolute;
  top: 90px;
  left: 30px;
  z-index: 99;
}

.page-inner {
  padding-bottom: 100px;
}
</style>
