<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <g fill="none" fill-rule="evenodd">
      <circle cx="8" cy="8" r="7" :class="className" fill-opacity=".2" />
      <circle cx="8" cy="8" r="4" :class="className" />
      <path d="M0 0H24V24H0z" />
    </g>
  </svg>
</template>
<script>
export default {
  name: 'SvgIconDotFill',
  props: {
    className: {
      type: String,
      default: 'fill-default'
    }
  }
}
</script>
<style lang="scss" scoped>
.fill-default {
  fill: #7a818f;
}
.fill-red {
  fill: #dc2e2e;
}
.fill-orange {
  fill: #f3a53c;
}
.fill-green {
  fill: #7fb91b;
}
.fill-blue {
  fill: #45bcd8;
}
</style>
