<template>
  <ul class="pagination">
    <li class="pagination-item">
      <button
        type="button"
        @click="onClickFirstPage"
        :disabled="isInFirstPage"
        aria-label="Go to first page"
      >
        {{ $t('button.first') }}
      </button>
    </li>

    <li class="pagination-item">
      <button
        type="button"
        @click="onClickPreviousPage"
        :disabled="isInFirstPage"
        aria-label="Go to previous page"
      >
        {{ $tc('button.back', 1) }}
      </button>
    </li>

    <li
      v-for="(page, index) in pages"
      :key="index + '-pagination'"
      class="pagination-item"
    >
      <button
        type="button"
        @click="onClickPage(page.name)"
        :disabled="page.isDisabled"
        :class="{ active: isPageActive(page.name) }"
        :aria-label="`Go to page number ${page.name}`"
      >
        {{ page.name }}
      </button>
    </li>

    <li class="pagination-item">
      <button
        type="button"
        @click="onClickNextPage"
        :disabled="isInLastPage"
        aria-label="Go to next page"
      >
        {{ $tc('button.next', 1) }}
      </button>
    </li>

    <li class="pagination-item">
      <button
        type="button"
        @click="onClickLastPage"
        :disabled="isInLastPage"
        aria-label="Go to last page"
      >
        {{ $t('button.last') }}
      </button>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    maxVisibleButtons: {
      type: Number,
      required: false,
      default: 5
    },
    totalPages: {
      type: Number,
      required: true
    },
    total: {
      type: Number,
      required: true
    },
    currentPage: {
      type: Number,
      required: true,
      default: 1
    },
    perPage: {
      type: Number,
      required: true
    }
  },
  computed: {
    startPage() {
      if (this.currentPage === 1) {
        return 1
      }

      if (this.currentPage === this.totalPages) {
        return this.totalPages - this.maxVisibleButtons + 1
      }

      return this.currentPage - 1
    },
    endPage() {
      return Math.min(
        this.startPage + this.maxVisibleButtons - 1,
        this.totalPages
      )
    },
    pages() {
      const range = []

      for (let i = this.startPage; i <= this.endPage; i += 1) {
        if (i > 0)
          range.push({
            name: i,
            isDisabled: i === this.currentPage
          })
      }

      return range
    },
    isInFirstPage() {
      return this.currentPage === 1
    },
    isInLastPage() {
      return this.currentPage === this.totalPages
    }
  },
  methods: {
    onClickFirstPage() {
      this.updateQuery(1)
    },
    onClickPreviousPage() {
      this.updateQuery(this.currentPage - 1)
    },
    onClickPage(page) {
      this.updateQuery(page)
    },
    onClickNextPage() {
      this.updateQuery(this.currentPage + 1)
    },
    onClickLastPage() {
      this.updateQuery(this.totalPages)
    },
    isPageActive(page) {
      let queryPage = parseInt(this.$route.query.page)
      if (queryPage) {
        return queryPage == page
      }
      return this.currentPage === page
    },
    updateQuery(page) {
      this.$emit('onChange', page)
      this.$router
        .replace({
          query: {
            ...this.$route.query,
            page: !page ? this.currentPage : page
          }
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
.pagination {
  list-style-type: none;
}

.pagination-item {
  display: inline-block;

  button {
    cursor: pointer;
    padding: 5px;
    margin-right: 5px;
    background-color: transparent;
    border-radius: 3px;
    color: var(--text-color--gray);
    border: 1px solid var(--input-border--light-black) !important;
  }

  &:last-child {
    button {
      margin-right: 0;
    }
  }
}

.active {
  background-color: var(--button-secondary) !important;
  color: var(--text-color--white) !important;
}
</style>
