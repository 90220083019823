<template>
  <div class="table__row">adads</div>
</template>

<script>
export default {
  name: 'TableRow',
  props: {
    data: {}
  }
}
</script>
