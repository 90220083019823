<template>
  <div
    class="statistic-button"
    :class="{
      'statistic-button--active': item.route === $route.params.category
    }"
  >
    <div class="statistic-button__title">{{ item.title }}</div>
    <!-- <div class="statistic-button__value">{{ item.value }} Alarm</div> -->
  </div>
</template>

<script>
export default {
  name: 'StatisticButton',
  props: {
    item: {}
  }
}
</script>
