<template>
  <div class="observation-detail-container" v-if="!isLoading">
    <observation-close-modal
      @onModalActionCreate="onModalActionCreate"
    ></observation-close-modal>
    <!-- left panel -->
    <div class="observation-detail-container__grid">
      <observation-past-signals
        :pastSignals="signals"
      ></observation-past-signals>
    </div>
    <!-- content -->
    <div class="observation-detail-container__content frame">
      <!-- task detail right buttons -->
      <ul class="right-button-grid">
        <li v-for="(button, index) in formButtonList" :key="button.id">
          <component
            :class="{
              active: index + 1 == showedModalIndex,
              disabled: data.status === 'informed' && index + 1 === 3
            }"
            @click.native="button.click()"
            :is="button.component"
          ></component>
        </li>
      </ul>
      <!-- task details -->
      <observation-event-detail
        :isInformed="data.status === 'CLOSED'"
        :isClicked="isClicked"
        :data="{ ...data, authorized }"
        :isTicketCreated="isTicketCreated"
        @changeTaskStatus="changeTaskStatus"
        @taskPostpone="taskPostpone"
        @onUpdated="value => (isTicketCreated = value)"
        :isCalling="isCalling"
      ></observation-event-detail>
      <observation-past-actions
        @onNoteCreated="onNoteCreated"
        :actions="actions === null ? [] : actions"
      ></observation-past-actions>
    </div>
    <!-- right panel -->
    <div class="observation-detail-container__grid frame">
      <!-- call actions -->
      <observation-detail-call
        v-if="showedModalIndex == 1"
        :isInformed="data.status === 'CLOSED'"
        @calledPerson="calledPerson"
        @onNoteCreated="onNoteCreated"
        @setIsCalling="setIsCalling"
        :data="callList"
      ></observation-detail-call>
      <!-- new ticket -->
      <observation-create-ticket
        v-if="showedModalIndex == 3"
        :data="data"
        @onClose="closeModal(1)"
        @onCreated="handleTicketCreated"
        @reset="resetTicketForm"
      ></observation-create-ticket>
      <!-- add note to customer -->
      <observation-create-customer-note
        v-if="showedModalIndex == 2"
        :notes="notes"
        :premise="data.premise.id"
        @onClose="closeModal(1)"
        @onUpdateNote="updateNote"
      ></observation-create-customer-note>
    </div>
    <!-- @onClosed="calledPerson = {}" -->

    <call-modal
      v-if="called"
      @onClosed="calledPerson({})"
      :calledPerson="called"
    ></call-modal>

    <!-- <modal
      @closed="closeNote = { note: '', error: false }"
      name="task-confirmation-modal"
    >
      <div class="task-confirmation">
        <span>{{ $t('confirmation.informed') }}</span>

        <textarea
          v-model.trim="closeNote.note"
          class="confirmation-note"
          id=""
          rows="4"
          :placeholder="$t('confirmation.note')"
        />
        <span class="note-error" v-if="closeNote.error">
          {{ closeNote.error }}
        </span>

        <div
          class="input-group margin-top-30"
          style="display: flex; justify-content: center;"
        >
          <button class="button button--outline" @click="hideModal">
            {{ $t('words.cancel') }}
          </button>
          <button class="button button--dark" @click="closeTask">
            {{ $t('words.save') }}
          </button>
        </div>
      </div>
    </modal> -->
  </div>
</template>

<script>
import moment from 'moment'
import { mapState, mapActions } from 'vuex'

import {
  ObservationPastSignals,
  ObservationEventDetail,
  ObservationPastActions,
  ObservationDetailCall,
  SvgIconObservationNote,
  SvgIconObservationRedirect,
  SvgIconObservationService,
  CallModal,
  ObservationCreateTicket,
  ObservationCreateCustomerNote
} from '@/plugins/components'
import {
  getCustomerNotes,
  getIntegrator,
  getPremise,
  getSignals,
  getTask,
  updateTask,
  postponeTask,
  assignTask,
  unassignTask
} from '../services/core'
import observationCloseModal from './observation-close-modal.vue'

export default {
  components: { observationCloseModal },
  name: 'ObservationDetail',
  data() {
    return {
      actions: [],
      authorized: {},
      subscriber: null,
      isClicked: false,
      channel: {},
      called: {},
      notes: [],
      data: {},
      callList: [],
      closeNote: {
        note: '',
        error: false
      },
      callEndTypes: [
        'call_hang_up',
        'missing_call',
        'completed_call',
        'call_not_completed'
      ],
      isNoteCreated: false,
      isCalling: false,
      recentSignals: {},
      isTicketCreated: false,
      isLoading: true,
      showedModalIndex: 2,
      allowedRouteNames: ['live-signals'],
      signalsActions: [],
      signals: [],
      signals_action: [],
      formButtonList: [
        {
          id: 'redirect',
          component: SvgIconObservationRedirect,
          click: () => {
            this.showedModalIndex = 1
          }
        },
        {
          id: 'note',
          component: SvgIconObservationNote,
          click: () => {
            this.showedModalIndex = 2
          }
        },
        {
          id: 'service',
          component: SvgIconObservationService,
          click: () => {
            if (this.data.status !== 'informed') {
              this.showedModalIndex = 3
            }
          }
        }
      ]
    }
  },
  computed: {
    ...mapState('auth', ['distributorId', 'user', 'timezone'])
  },
  methods: {
    closeModal(index) {
      this.showedModalIndex = index || 1
    },
    resetTicketForm() {
      this.showedModalIndex = 2
    },
    setIsCalling(calling) {
      this.isCalling = calling
    },
    updateNote() {
      getCustomerNotes(this.data.premise.id)
        .then(({ data }) => {
          const availableNotes = []
          data.data.result[0].data.forEach(item => {
            if (item.expireAt) {
              if (moment(item.expireAt).local() > moment()) {
                availableNotes.push(item)
              }
            } else {
              availableNotes.push(item)
            }
          })
          this.notes = availableNotes
        })
        .catch(() => (this.notes = []))
    },
    calledPerson(data) {
      this.called = {}
      setTimeout(() => {
        this.called = { ...data }
      }, 200)
    },
    onNoteCreated() {
      this.isNoteCreated = true
      getTask(this.$route.params.id)
        .then(({ data }) => {
          this.data = data.data.result[0].data[0]
          this.actions = this.data.actions.reverse()
        })
        .catch(err => console.log(err))
    },
    async closeTask() {
      try {
        if (!this.closeNote.note) {
          this.closeNote.error = this.$t('confirmation.note-error')
          return
        }

        const { status } = await this.taskStatus([
          this.$route.params.id,
          'informed'
        ])

        if (status === 200) {
          await this.addTaskActionNote({
            id: this.$route.params.id,
            type: 'note',
            description: this.closeNote.note
          })

          this.hideModal()
          this.$router.push({
            name: 'observation',
            params: { category: 'general' }
          })
        }
      } catch (err) {
        console.log(err)
      }
    },
    hideModal() {
      this.$modal.hide('task-confirmation-modal')
    },
    // async changeTaskStatus({ status, taskId }) {
    //   try {
    //     this.isClicked = true
    //     if (status === 'leave') {
    //       if (!confirm(this.$t(`confirmation.${status}`))) {
    //         this.isClicked = false
    //         return
    //       }
    //     }

    //     if (status === 'informed' && !this.isNoteCreated) {
    //       this.$modal.show('task-confirmation-modal')
    //       this.isClicked = false
    //       return
    //     }

    //     if (status !== 'leave') {
    //       await this.taskStatus([taskId, status])
    //     }

    //     if (status === 'waiting_feedback' || status === 'leave') {
    //       await this.$store.dispatch('observation/taskUnassign', taskId)
    //     }

    //     this.$router.push({
    //       name: 'observation',
    //       params: { category: 'general' }
    //     })
    //   } catch (error) {
    //     this.isClicked = false
    //   }
    // },
    async changeTaskStatus({ type, ...payload }) {
      try {
        this.isClicked = true

        if (type === 'postpone') {
          await postponeTask({ id: this.data.id, payload })
        } else if (type === 'close') {
          // await updateTask(this.data.id, payload)
          console.log('test')
          this.$modal.show('size-modal', {
            text: 'I am a tiny dialog box.<br/>And I render <b>HTML!</b>',
            payload,
            dataId: this.data.id
          })
          return
        } else if (type === 'unassign') {
          await unassignTask(this.data.id)
        }

        this.$router.push({
          name: 'observation',
          params: { category: 'general' }
        })
      } catch (err) {
        console.log(err)
      } finally {
        this.isClicked = false
      }
    },
    async onModalActionCreate(payload) {
      try {
        console.log(payload)
        return
        // await updateTask(this.data.id, payload)

        // this.$router.push({
        //   name: 'observation',
        //   params: { category: 'general' }
        // })
      } catch (err) {
        console.log(err)
      } finally {
        this.isClicked = false
      }
    },
    taskPostpone({ date, taskId }) {
      this.isClicked = true
      let postponedTime = moment(date).toISOString()
      this.postpone([taskId, postponedTime])
        .then(response => {
          // show notif

          this.$notify({
            text: `${this.$tc('operation.postponed', 1, {
              date: this.dateConvertToFriendlyString(date, this.timezone)
            })}`
          })

          // redirect to observation page
          this.$router.push({
            name: 'observation',
            params: { category: 'general' }
          })
        })
        .catch(err => (this.isClicked = false))
    },
    handleTicketCreated(value) {
      this.isTicketCreated = value
      this.showedModalIndex = 1
      this.$notify({
        type: 'success',
        text: 'Teknik servis başarıyla oluşturuldu.'
      })
    },
    async checkAssigned(isInformed) {
      try {
        if (
          !isInformed &&
          this.data.assignee?.id !== this.$store.state.auth.user.id
        ) {
          const { data } = await assignTask(this.data.id)
          if (data.success) {
            const task = await getTask(this.$route.params.id)
            this.data = task.data.data.result[0].data[0]
            this.actions = this.data.actions.reverse()
          }
        }
      } catch (err) {
        this.$router.push('/observation/general')
      }
    },
    async fetchTaskDetail() {
      try {
        const task = await getTask(this.$route.params.id)
        this.data = task.data.data.result[0].data[0]
        this.actions = this.data.actions.reverse()

        const integrator = await getIntegrator(this.data.integrator.id)
        this.authorized = integrator.data.data.result[0].authorized

        const premise = await getPremise(this.data.premise.id)
        this.callList = premise.data.data.result[0].contacts

        const signals = await getSignals({
          page: 1,
          limit: 50,
          'premise.id': this.data.premise.id,
          createdAt: {
            gte: moment(this.signalCreatedDate(this.data)).subtract(2, 'hours'),
            lte: moment(this.signalCreatedDate(this.data)).add(2, 'hours')
          }
        })
        this.signals = signals.data.data.result[0]

        const notes = await getCustomerNotes(this.data.premise.id)

        if (notes.data.success) {
          const availableNotes = []
          notes.data.data.result[0].data.forEach(item => {
            if (item.expireAt) {
              if (moment(item.expireAt).local() > moment()) {
                availableNotes.push(item)
              }
            } else {
              availableNotes.push(item)
            }
          })
          this.notes = availableNotes
        }
      } catch (err) {
        console.log({ ...err })
        // this.$router.push('/observation/general')
      }
    },
    signalCreatedDate(task) {
      if (!task?.signal?.createdAt) {
        return task.createdAt
      }

      return task.signal.createdAt
    }
  },

  created() {
    this.fetchTaskDetail().then(() => {
      this.checkAssigned(this.$route.path.includes('informed'))
      this.isLoading = false
    })
  }
}
</script>

<style lang="scss" scoped>
.task-confirmation {
  display: flex;
  height: 100%;
  text-align: center;
  padding: 50px;
  flex-direction: column;
}
.note-error {
  color: red;
  font-size: 12px;
  margin-top: 5px;
  text-align: left;
}
.confirmation-note {
  width: 100%;
  max-width: unset;
  margin-top: 25px;
  color: black;
  resize: none;
}
.modal-buttons {
  display: flex;

  &:first-child {
    margin-left: 10px;
  }
}
</style>
