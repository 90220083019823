<template>
  <div class="ticket-modal-wrapper">
    <div class="past-signals-title">
      {{ $tc('operation.create-service-request', 2) }}
    </div>
    <div class="input-group margin-top-30">
      <label class="label">{{ $tc('words.customer-name', 2) }}</label>
      <input type="text" class="input" :value="data.customer.title" readonly />
    </div>
    <div class="input-group margin-top-30">
      <label class="label">{{ $tc('words.premise', 1) }}</label>
      <input type="text" class="input" :value="data.premise.name" readonly />
    </div>
    <div class="input-group margin-top-30">
      <label class="label">{{ $tc('words.device', 1) }}</label>
      <input
        type="text"
        class="input"
        :value="
          `${data.device.name} ${$t(`hardware_type.${data.device.type}`)}`
        "
        readonly
      />
    </div>
    <!-- <div class="input-group margin-top-30">
      <label class="label">{{ $tc('form.device', 2) }}</label>
      <SelectBox
        v-if="deviceList.length > 0"
        :options="deviceList"
        @change="changeDevice"
        ref="device"
      ></SelectBox>
    </div> -->
    <!-- <div class="input-group margin-top-30">
      <label class="label">{{ $t('words.area') }}</label>
      <selectbox-multiple
        v-model="selectedAreas"
        :options="optionsAreas"
        ref="areaSelectbox"
      ></selectbox-multiple>
    </div> -->
    <!-- <div class="input-group margin-top-30">
      <label class="label">{{ $tc('form.category', 2) }}</label>
      <SelectBox
        :options="optionFilterList"
        @change="changeServiceCategory"
        ref="category"
      ></SelectBox>
    </div> -->
    <!-- <div class="input-group margin-top-30">
      <label class="label">{{ $t('form.signal-interruption') }}</label>
      <selectbox-multiple
        :disabled="isSelectedAllSignals"
        v-model="selectedSignals"
        :options="optionsSignals"
        ref="signalSelectbox"
      ></selectbox-multiple>

      <label class="flex row checkbox" for="signals">
        <input type="checkbox" id="signals" v-model="isSelectedAllSignals" />
        <div class="checkmark"></div>
        {{ $t('operation.signal-interruption-checkbox') }}
      </label>
    </div> -->
    <div class="input-group margin-top-30">
      <label class="label">{{ $t('words.description') }}</label>
      <textarea
        class="textarea"
        style="max-width:100%"
        v-model="description"
      ></textarea>
    </div>
    <div
      class="input-group margin-top-30"
      style="display: flex; justify-content: flex-end;"
    >
      <button class="button button--outline" @click="$emit('onClose', true)">
        {{ $t('words.cancel') }}
      </button>
      <button
        class="button button--dark button-center"
        @click="onSubmit"
        :disabled="isSubmit"
      >
        <img
          v-if="isSubmit"
          class="loader"
          size="16"
          src="@/assets/image/loader.png"
        />

        {{ $t('words.create') }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { SIGNAL_TYPE } from '@/constants'
import { createTicket } from '../../../services/core'

export default {
  name: 'ObservationCreateTicket',
  props: {
    data: {}
  },
  computed: {
    ...mapState('auth', ['user'])
  },
  data() {
    return {
      description: '',
      isSubmit: false,
      notes: '',
      isSelectedAllSignals: false,
      optionsSignals: [...SIGNAL_TYPE].map(key => ({
        text: this.$t(`signals.${key}`),
        value: key
      })),
      deviceList: [],
      selectedDeviceId: null,
      optionsAreas: [],
      selectedAreas: [],
      selectedSignals: [],
      category: '',
      optionFilterList: [
        { text: this.$t('words.select'), key: '' },
        { text: this.$t('ticket_category.fault'), key: 'fault' },
        { text: this.$t('ticket_category.maintenance'), key: 'maintenance' },
        {
          text: this.$t('ticket_category.signal_interruption'),
          key: 'signal_interruption'
        }
      ]
    }
  },
  methods: {
    changeServiceCategory(e) {
      this.category = e.key
    },
    changeDevice(device) {
      this.selectedDeviceId = device.key
    },
    resetForm() {
      // this.category = ''
      // this.notes = ''
      // this.isSelectedAllSignals = false
      // this.selectedSignals = []
      // this.$refs.signalSelectbox.selected = []
      // this.$refs.category.signalSelectbox = null
      this.$emit('reset')
    },
    onSubmit() {
      //  console.log('category', this.category)
      if (!this.description) {
        alert(this.$t('error.selectServiceType'))
        return
      }

      const payload = {
        status: 'NEW',
        category: 'SERVICE',
        description: this.description,
        device: {
          id: this.data.device.id
        },
        premise: {
          id: this.data.premise.id
        },
        customer: {
          id: this.data.customer.id
        },
        integrator: {
          id: this.data.integrator.id
        },
        distributor: {
          id: this.data.distributor.id
        }
      }

      console.log(payload)
      createTicket(payload)
        .then(({ data }) => {
          if (data.success) {
            this.$emit('onCreated')
          }
        })
        .catch(err => console.log(err))
    },
    async getAreas() {
      let { data, status } = await this.getPremiseAreas({
        premise_id: this.data.premise.id
      })
      if (status == 200) {
        this.optionsAreas = [...data].map(item => ({
          text: item.name,
          value: item.id
        }))
      }
    }
  },
  created() {
    // console.log(this.data)
    // this.getAreas()
    // this.getDevices(this.data.premise.id).then(response => {
    //   let devices = response.data.map(item => {
    //     return {
    //       text: `${item.name} (${this.$t(
    //         `hardware_type.${item.hardware_type}`
    //       )})`,
    //       key: item.id
    //     }
    //   })
    //   this.deviceList = devices
    // })
  }
}
</script>

<style lang="scss" scoped>
input {
  font-size: 12px !important;
  font-weight: 300;
}
.button-center {
  display: flex;
  align-items: center;
}
</style>
