<template>
  <div class="collapser-grid">
    <header @click="isShow = !isShow">
      <p>{{ options.name }}</p>
      <svg-icon-arrow
        :aria-direction="!isShow ? 'up' : null"
        :size="10"
        :fill="'#11a3d2'"
      ></svg-icon-arrow>
    </header>
    <section v-if="isShow">
      <div class="checkbox-grid">
        <label v-for="item in options.keys" :key="item.id" class="checkbox">
          <input
            @change="handleChange($event, item)"
            :value="item.value"
            :checked="filtered.includes(item.id)"
            type="checkbox"
          />
          <span class="checkmark"></span>
          <p>{{ item.label }}</p>
        </label>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'FilterCollapser',
  props: {
    isClearAll: {
      type: Boolean,
      default: false
    },
    options: {
      type: Object,
      default: () => ({
        name: 'Device',
        keys: [
          { label: 'Hırsız paneli', id: 'PANEL', value: false },
          { label: 'camera', id: 'CAMERA', value: false }
        ]
      })
    }
  },
  watch: {
    isClearAll: function(newValue, oldValue) {
      if (newValue) {
        this.filtered = []
        this.options.keys.filter(key => {
          key.value = false
        })
        this.updateQuery()
      }
    }
  },
  data() {
    return {
      isShow: true,
      filtered: []
    }
  },
  methods: {
    updateQuery() {
      let queryPayload = {
        query: { ...this.$route.query }
      }

      queryPayload.query[this.options.key] = this.filtered.toString()

      if (!this.filtered.length) {
        delete queryPayload.query[this.options.key]
      }

      this.$router.replace(queryPayload)
    },
    addItem(key) {
      let queryPayload = {
        query: { ...this.$route.query }
      }

      if (!this.filtered.includes(key)) {
        this.filtered.push(key)
        this.updateQuery()
      }
    },
    removeItem(key) {
      if (this.filtered.includes(key)) {
        this.filtered.splice(this.filtered.indexOf(key), 1)
        this.updateQuery()
      }
    },
    handleChange(input, item) {
      let isChecked = input.target.checked || false
      this.$emit('onChangeItem', { ...item, isChecked })
      if (isChecked) {
        this.addItem(item.id)
      } else {
        this.removeItem(item.id)
      }
    }
  },
  created() {
    let query = this.$route.query[this.options.key]
    if (query) {
      if (query.split(',')) {
        this.options.keys.forEach(key => {
          if (query.split(',').includes(key.id)) {
            key.value = true
            this.filtered.push(key.id)
          }
        })
      } else {
        this.filtered.push(query)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.collapser-grid {
  display: grid;
  grid-template-rows: auto;
  grid-row-gap: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.058);
  padding-bottom: 15px;

  &:last-child {
    border: none;
  }

  p {
    user-select: none;
  }

  header {
    display: grid;
    grid-template-columns: 1fr auto;
    cursor: pointer;

    p {
      font-size: 13px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #11a3d2;
    }
  }

  section {
    display: grid;
    grid-template-rows: auto;
    max-height: 200px;
    overflow: auto;

    p {
      font-size: 12px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
    }
  }
}
</style>
