<template>
  <div class="row signals-wrapper">
    <ul class="signal-action-button">
      <li
        class="signal-action-button__item"
        :class="{ 'signal-action-button__item--active': play == item.isPlay }"
        v-for="(item, key) in data"
        :key="key + 0"
        @click="$emit('signalFlowController', item.isPlay)"
      >
        <img :src="item.icon" />
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'SignalsHeader',
  props: {
    play: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      data: [
        {
          icon: require('@/assets/image/signal-play.svg'),
          isPlay: true
        },
        {
          icon: require('@/assets/image/signal-stop.svg'),
          isPlay: false
        }
      ]
    }
  }
}
</script>
