<template>
  <div class="form-grid form-grid--login">
    <p v-if="!!message" class="message">{{ message }}</p>
    <template v-if="!message">
      <label class="label-input" for="email">
        <input
          class="auth-input"
          v-model.trim="form.email"
          :placeholder="$t('words.mail')"
          :disabled="isLoading"
          type="email"
          id="email"
        />
      </label>
      <Button
        class="fill secondary"
        @click="submit()"
        :loading="isLoading"
        :text="$t('button.send')"
      ></Button>
    </template>
  </div>
</template>

<script>
import { resetPassword } from '../../services/core'

export default {
  name: 'FormForgot',

  data() {
    return {
      message: null,
      form: {
        email: null
      },
      isLoading: false
    }
  },
  methods: {
    async submit() {
      this.$emit('onError', null)
      this.message = null
      this.isLoading = true
      if (this.formController()) {
        try {
          await resetPassword(this.form.email)
          this.message = this.$t('forgot-password.successMessages.send-mail')
        } catch (error) {
          this.$emit(
            'onError',
            error?.response?.data?.message ||
              this.$t('forgot-password.validationErrors.mail-send')
          )
        }

        this.isLoading = false
      } else {
        this.isLoading = false
      }
    },
    formController() {
      if (!this.validEmail(this.form.email)) {
        this.$emit(
          'onError',
          this.$t('forgot-password.validationErrors.mail-invalid')
        )
        return false
      }
      return true
    }
  },
  created() {
    this.$emit('onError', null)
  }
}
</script>

<style lang="scss" scoped>
.message {
  font-size: 16px;
  line-height: 1.6;
  color: white;
}

.flex {
  display: flex;
}

.row {
  flex-direction: row;
}

.column {
  flex-direction: column;
}

.margin-center-10 {
  margin: 10px 0;
}

.action-grid {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.checkbox-remember {
  align-items: center;

  input {
    width: max-content;
    margin-right: 5px;
  }
  p {
    color: var(--text-color--white);
    font-size: 14px;
  }
}
</style>
