<template>
  <div
    @click="assignToMeAndRedirectToTaskDetails"
    class="observation-card"
    :class="{
      'observation-card--filled': item.messages && item.messages.length > 1
    }"
  >
    <!-- <div class="observation-card__assign-button">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="10"
        height="10"
        viewBox="0 0 10 10"
      >
        <path
          fill="#CFD1D7"
          fill-rule="nonzero"
          d="M10 5a.446.446 0 0 1-.455.455h-4.09v4.09A.446.446 0 0 1 5 10a.446.446 0 0 1-.455-.455v-4.09H.455A.446.446 0 0 1 0 5c0-.258.197-.455.455-.455h4.09V.455C4.545.197 4.742 0 5 0s.455.197.455.455v4.09h4.09c.258 0 .455.197.455.455z"
        />
      </svg>
    </div> -->
    <div class="observation-card__badge" :style="{ background: taskColor }">
      {{ taskCategoryNaming(item.category) }}
    </div>
    <!-- <div class="observation-card__circle" :style="{ background: taskColor }">
      <p class="observation-card__circle-p"></p>
    </div> -->
    <div class="observation-card__text">
      {{ item.customer && item.customer.name }}
    </div>
    <div
      v-if="item.premise"
      class="observation-card__text observation-card__text--soft"
    >
      <!-- {{ item.premise.address.city }}
      <i v-if="item.premise.address.city">/</i> -->
      {{ $tc('words.premise', 1) }}: {{ item.premise.name }}
      <span v-if="item.area && item.area.name"> ({{ item.area.name }})</span>
    </div>
    <div class="observation-card__bottom">
      <div class="observation-card__bottom__text">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="12"
          viewBox="0 0 12 12"
        >
          <path
            :fill="taskColor"
            fill-rule="nonzero"
            d="M6 0C2.69 0 0 2.69 0 6s2.69 6 6 6 6-2.69 6-6-2.69-6-6-6zm0 .75A5.244 5.244 0 0 1 11.25 6 5.244 5.244 0 0 1 6 11.25 5.244 5.244 0 0 1 .75 6 5.244 5.244 0 0 1 6 .75zm0 1.5a.375.375 0 0 0-.375.375V6c0 .139.076.257.188.322l2.595 1.5c.18.104.412.045.516-.135a.373.373 0 0 0-.14-.51L6.374 5.784V2.625A.375.375 0 0 0 6 2.25z"
          />
        </svg>
        <span>{{ item.createdAt | ago($store.state.locale.lang) }}</span>
        <span v-if="item.postponedAt">--> {{ $t(`words.postponed`) }}</span>
      </div>
      <div class="observation-card__bottom__text">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="12"
          viewBox="0 0 12 12"
        >
          <path
            :fill="taskColor"
            fill-rule="nonzero"
            :stroke="taskColor"
            d="M10.677 0h-9.34C.6 0 0 .62 0 1.378v5.595C0 7.73.601 8.35 1.336 8.35h5.212l2.659 2.673V8.351h1.457c.734 0 1.336-.62 1.336-1.378V1.378C12.013.62 11.412 0 10.677 0z"
          />
        </svg>
        <span>{{ taskStatusNaming(item.status) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import moment from 'moment'
import { assignTask } from '../../../services/core'

export default {
  name: 'ObservationCard',
  props: {
    item: {}
  },
  computed: {
    taskColor() {
      let color = '#fff'
      if (this.item.category === 'FIRE') {
        color = '#f86784'
      } else if (this.item.category === 'SECURITY') {
        color = '#8c89e8'
      } else if (this.item.category === 'AMBULANCE') {
        color = '#ff9e5f'
      } else if (this.item.category === 'TECHNIC') {
        color = '#5ed3ee'
      } else if (this.item.category === 'OTHER') {
        color = '#ccc'
      }
      return color
    }
  },
  methods: {
    assignToMeAndRedirectToTaskDetails() {
      this.$emit('click', this.item.id)
    }
  },
  created() {
    this.interval = setInterval(() => this.$forceUpdate(), 1000)
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  filters: {
    ago(value, lang) {
      return moment(value)
        .locale(lang)
        .format('DD.MM.Y, HH:mm')
    }
  }
}
</script>
