<template>
  <table class="table">
    <thead class="thead">
      <tr class="table-row">
        <th
          class="table-row__item"
          v-for="(item, key) in columns"
          :key="key + 0"
          :style="{ width: width[key] + '%' }"
        >
          {{ item }}
        </th>
      </tr>
    </thead>
    <tbody class="tbody">
      <tr class="table-row" v-for="(item, index) in data" :key="index + 0">
        <td
          class="table-row__item"
          v-for="(field, index) in fields"
          :key="index + 0"
          :style="{ width: width[index] + '%' }"
        >
          {{ renderDatasField(item, field) }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'Table',
  props: {
    data: {},
    fields: {},
    columns: {},
    width: {}
  },
  methods: {
    renderDatasField(data, field) {
      if (typeof field === 'object') {
        let objectField = Object.keys(field)
        let objectValue = Object.values(field)
        if (data[objectField][0]) {
          return data[objectField][0][field[objectField]]
        }
        if (typeof objectValue[0] === 'object') {
          let nestedField = Object.keys(objectValue[0]).toString()
          return Object.values(data[objectField][nestedField]).toString()
        }
        return data[objectField][objectValue]
      } else {
        if (field === 'created_at') {
          return this.dateConvertToMonthWithTime(data[field])
        } else {
          return data[field]
        }
      }
    }
  }
}
</script>
