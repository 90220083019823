<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="size"
    :height="size"
    viewBox="0 0 8 5"
  >
    <path
      :fill="fill"
      d="M1.06.373l2.928 3.15L6.957.445c.184-.191.487-.196.678-.013l.02.021c.194.211.19.537-.01.742L4.697 4.241c-.384.397-1.017.408-1.414.024l-.038-.04L.346 1.1C.152.889.157.566.356.363c.19-.192.498-.194.69-.006l.014.016z"
    />
  </svg>
</template>
<script>
export default {
  name: 'SvgIconArrow',
  props: {
    size: {
      type: Number,
      default: 12
    },
    fill: {
      type: String,
      default: '#FFF'
    },
    stroke: {
      type: String,
      default: '#FFF'
    },
    strokeWidth: {
      type: String,
      default: '.1'
    },
    direction: {
      type: String,
      default: 'down'
    }
  }
}
</script>

<style lang="scss">
svg {
  &[aria-direction='up'] {
    transform: rotate(180deg);
  }
}
</style>
