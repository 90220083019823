<template>
  <div class="avatar-navbar">
    <div class="brightness">
      {{ getFirstLetters(user.name) }}
    </div>
    <ul>
      <li>
        <div class="text">{{ user.name }}</div>
      </li>
      <li>
        <!-- <div class="operator-extension-text">
          {{ user.job.operator_extension }}
        </div> -->
      </li>
    </ul>
    <ul class="navbar__dropdown">
      <li class="navbar__dropdown__item" @click="goToDistributor">
        {{ $t('words.distributor') }}
      </li>
      <hr />
      <li class="navbar__dropdown__item" @click="signOut">
        {{ $t('words.log-out') }}
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { logout } from '../../services/core'
export default {
  name: 'HeaderAvatar',
  computed: {
    ...mapState('auth', ['user'])
  },
  methods: {
    ...mapActions({
      logout: 'auth/logout'
    }),
    getFirstLetters(name) {
      const splitted = name.split(' ')

      if (splitted.length === 1)
        return splitted[0].substring(0, 2).toUpperCase()

      return (
        splitted[0].charAt(0) + splitted[splitted.length - 1].charAt(0)
      ).toUpperCase()
    },
    signOut() {
      logout()
        .then(() => {
          this.logout()
        })
        .catch(err => console.log(err))
    },
    goToDistributor() {
      if (window) window.open(this.externalRoute())
    },
    externalRoute() {
      const env = process.env.NODE_ENV

      if (env === 'production') {
        return 'https://distributor.hybrone.com/?redirect=Customer'
      } else if (env === 'development') {
        return 'https://test.distributor.hybrone.com/?redirect=Customer'
      }

      return `https://${env}.distributor.hybrone.com/?redirect=Customer`
    }
  }
}
</script>
<style lang="css" scoped>
.operator-extension-text {
  font-size: 10px;
  color: darkgray;
}
</style>
