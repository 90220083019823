<template>
  <div class="grid">
    <div class="grid__left-section">
      <!-- customer -->
      <input-autocomplete
        class="grid__left-section__item"
        :id="'customer'"
        ref="customerSelect"
        @input="handleCustomerInput"
        :data="customerList"
        :loading="isCustomersLoading"
        :placeholder="$t('signals.filter.search-customer')"
        :value="customerName"
        @updateValue="text => (customerName = text)"
      >
        <div>
          <li
            @click="
              selectCustomer(customer.id)
              customerName = customer.name
              customerList = []
            "
            v-for="(customer, index) in customerList"
            :key="index + '-c'"
          >
            {{ customer.name }}
          </li>
        </div>
      </input-autocomplete>

      <!-- location -->
      <!-- <input-search
        :id="'keyword'"
        :delay="1000"
        @input="text => handleInput('keyword', text)"
        class="grid__left-section__item"
        :value="keyword"
        @updateValue="text => (keyword = text)"
        :placeholder="$t('form.location')"
      ></input-search> -->

      <!-- date range -->
      <!-- <Dropdown
        @onClose="handleOnCloseDate"
        :text="displayedDateRange"
        class="grid__left-section__item"
      >
        <filter-date
          ref="filterDate"
          @onChange="handleChangeDate"
        ></filter-date>
      </Dropdown> -->

      <!-- qualifier -->
      <!-- <div class="grid__left-section__item input-group" style="max-width:270px">
        <SelectBox
          :options="qualifierOptions"
          @change="handleChangeQualifier"
          ref="qualifier"
          :selectedItemIndex="
            $route.query.qualifier
              ? qualifier.includes($route.query.qualifier)
                ? qualifier.indexOf($route.query.qualifier)
                : 0
              : 0
          "
        ></SelectBox>
      </div> -->

      <!-- clear all -->
      <div
        class="button button--filter grid__left-section__item"
        @click="resetFilter()"
        style="float:inherit;width:max-content"
      >
        {{ $t('signals.filter.clear-all') }}
      </div>

      <!-- advanced filter -->
      <!-- <filter-advanced
        :filters="filterAdvanced"
        :clearFields="[...filterAdvanced].map(filter => filter.key)"
        class="grid__left-section__item"
        @onChangeItem="$emit('onChangeItem', $event)"
      ></filter-advanced> -->

      <!-- export table  -->
      <!-- <div
        class="button button--filter grid__left-section__item"
        style="float:inherit;width:max-content"
      >
        <svg-icon-export></svg-icon-export>
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import {
  Dropdown,
  InputSearch,
  InputAutocomplete,
  FilterDate,
  FilterAdvanced,
  Selectbox
} from '@/plugins/components'
import { getCustomers } from '../../../services/core'

export default {
  name: 'FiltersPastSignals',
  props: {
    total: {
      type: Number,
      default: 1
    },
    filterAdvanced: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    limit() {
      return this.$route.query.limit
        ? Math.ceil(parseInt(this.$route.query.limit))
        : 50
    },
    customer() {
      if (!this.customers.length) return null
      return this.customerList.filter(
        customer => customer.id == this.$route.query.account_id
      )[0]
    }
    // customers() {
    //   return this.customerList.filter(customer => {
    //     customer.displayName = `${customer.name} ${
    //       customer.manager && customer.manager.first_name
    //         ? `(${customer.manager.first_name}  ${customer.manager.last_name})`
    //         : ''
    //     }`
    //     return customer
    //   })
    // }
  },
  data() {
    return {
      customerName: null,
      isCustomersLoading: false,
      customerList: [],
      qualifier: ['restore', 'event'],
      keyword: null,
      displayedDateRange: this.$t('signals.filter.select-date'),
      qualifierOptions: [
        {
          text: this.$t('signals.filter.qualifier.choose'),
          value: null,
          key: 'null'
        },
        {
          text: this.$t('signals.filter.qualifier.event'),
          value: { qualifier: 'true' },
          key: 'event'
        },
        {
          text: this.$t('signals.filter.qualifier.restore'),
          value: { qualifier: 'restore' },
          key: 'restore'
        }
      ]
    }
  },
  methods: {
    ...mapActions({
      // getCustomers: 'customer/getCustomers'
    }),
    reload() {
      if (location) location.reload()
    },
    resetFilter() {
      let query = { ...this.$route.query, page: 1 }
      delete query['customer.id']
      delete query.keyword
      delete query.from_created_at
      delete query.to_created_at
      delete query.qualifier

      this.$router.push({ query })
      this.$refs.customerSelect.dropdownText = this.$t(
        'signals.filter.select-customer'
      )
      this.displayedDateRange = this.$t('signals.filter.select-date')
      this.reload()
    },
    addQueryParams(query) {
      this.$router.push({
        query: {
          ...this.$route.query,
          ...query
        }
      })
    },
    removeQueryParams(key) {
      let query = { ...this.$route.query }
      delete query[key]
      this.$router.replace({ query })
    },
    handleChangeQualifier(data) {
      if (data.value) this.addQueryParams(data.value)
      if (!data.value) this.removeQueryParams('qualifier')
      this.$emit('onChange')
    },
    selectCustomer(id) {
      this.addQueryParams({ 'customer.id': id, page: 1 })
      this.removeQueryParams('premise_id')
      this.$emit('onChange')
    },
    handleInput(field, keyword) {
      let query = {}
      query[field] = keyword.trim()
      if (keyword && keyword != '') {
        this.addQueryParams(query)
      } else {
        this.removeQueryParams(field)
      }
      this.$emit('onChange')
    },
    handleChangeDate(date) {
      let { from_created_at, to_created_at } = date
      this.displayedDateRange = `${
        from_created_at ? this.dateConvertToFriendlyDate(from_created_at) : ''
      } ${
        to_created_at
          ? ' - ' + this.dateConvertToFriendlyDate(to_created_at)
          : ''
      }`

      if (!from_created_at && !to_created_at) {
        this.displayedDateRange = this.$t('signals.filter.select-date')
      }
    },
    handleOnCloseDate() {
      let { from_created_at, to_created_at } = this.$route.query
      if (from_created_at || to_created_at) {
        this.addQueryParams({ page: 1 })
        this.$emit('onChange')
      }
    },
    handleCustomerInput(keyword) {
      if (keyword && keyword.length > 2) {
        this.fetchCustomers(keyword)
        return
      }

      if (!keyword) {
        this.removeQueryParams('account_id')
        this.$emit('onChange')
      }
    },
    async fetchCustomers(keyword) {
      this.isCustomersLoading = true
      let { data } = await getCustomers(keyword)
      if (data.success) {
        this.customerList = data.data.result[0].data
      }
      this.isCustomersLoading = false
    },
    async fetchDatas() {
      await this.fetchCustomers()
    }
  },
  created() {
    let { from_created_at, to_created_at, keyword } = this.$route.query
    if (keyword) this.keyword = keyword
    this.handleChangeDate({
      from_created_at,
      to_created_at
    })
  }
}
</script>

<style lang="scss" scoped>
.grid {
  width: 100%;
  // height: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;

  &__left-section,
  &__right-section {
    display: flex;
    align-items: center;
    flex-direction: row;
  }

  &__left-section {
    width: 100%;

    &__item {
      margin-right: 15px;
    }
  }
  &__right-section {
    &__item {
      margin-left: 15px;
    }
  }
}
</style>
