import Vue from 'vue'
import AppHeader from '@/components/header/app-header.vue'
import Button from '@/components/atomic/button.vue'
import HeaderNavbar from '@/components/header/header-navbar.vue'
import HeaderAvatar from '@/components/header/header-avatar.vue'
import StatisticButton from '@/components/views/observation/statistic-button.vue'
// import FormForgot from '@/components/form/form-forgot.vue'
// import FormResetPassword from '@/components/form/form-reset-password.vue'
import ObservationHeader from '@/components/views/observation/observation-header.vue'
import ObservationCard from '@/components/views/observation/observation-card.vue'
import ObservationPastSignals from '@/components/views/observation/observation-past-signals.vue'
import ObservationEventDetail from '@/components/views/observation/observation-event-detail.vue'
import ObservationPastActions from '@/components/views/observation/observation-past-actions.vue'
import SignalCard from '@/components/views/observation/signal-card.vue'
import ActionCard from '@/components/views/observation/action-card.vue'
import TableSignals from '@/components/views/signals/table-signals.vue'
import TablePastSignals from '@/components/views/past-signals/table-past-signals.vue'
import ObservationEventDetailContent from '@/components/views/observation/observation-event-detail-content.vue'
import ObservationDetailCall from '@/components/views/observation/observation-detail-call.vue'
import StatusButton from '@/components/views/observation/status-button.vue'
import ObservationCreateTicket from '@/components/views/observation/observation-create-ticket.vue'
import ObservationCreateCustomerNote from '@/components/views/observation/observation-create-customer-note.vue'
import SignalsHeader from '@/components/views/signals/signals-header'
import Table from '@/components/table/table'
import TableAtomic from '@/components/atomic/table'
import TableRow from '@/components/table/table-row'
import RadioGroup from '@/components/atomic/radio-group.vue'
import RadioButton from '@/components/atomic/radio-button.vue'
import Input from '@/components/atomic/input.vue'
import InputSearch from '@/components/atomic/input-search.vue'
import InputAutocomplete from '@/components/atomic/input-autocomplete.vue'
import CallModal from '@/components/modal/call-modal.vue'
import PageLoader from '@/components/atomic/page-loader.vue'
import Pagination from '@/components/atomic/pagination.vue'
import SelectboxMultiple from '@/components/atomic/selectbox-multiple.vue'
import Selectbox from '@/components/atomic/selectbox.vue'
import Dropdown from '@/components/atomic/dropdown.vue'

import FilterAdvanced from '@/components/atomic/filter-advanced.vue'
import FilterCollapser from '@/components/atomic/filter-collapser.vue'
import FilterDate from '@/components/atomic/filter-date.vue'

import SvgIconArrowFill from '@/components/icons/svg-icon-arrow-fill.vue'
import SvgIconArrow from '@/components/icons/svg-icon-arrow.vue'
import SvgIconSignal from '@/components/icons/svg-icon-signal.vue'
import SvgIconClock from '@/components/icons/svg-icon-clock.vue'
import SvgIconDotFill from '@/components/icons/svg-icon-dot-fill.vue'
import SvgIconObservationNote from '@/components/icons/svg-icon-observation-note.vue'
import SvgIconObservationRedirect from '@/components/icons/svg-icon-observation-redirect.vue'
import SvgIconObservationService from '@/components/icons/svg-icon-observation-service.vue'
import SvgIconExport from '@/components/icons/svg-icon-export.vue'
import SvgIconFilter from '@/components/icons/svg-icon-filter.vue'

const components = [
  AppHeader,
  Button,
  HeaderNavbar,
  HeaderAvatar,
  StatisticButton,
  // FormForgot,
  // FormResetPassword,
  ObservationHeader,
  ObservationCard,
  ObservationPastSignals,
  ObservationEventDetail,
  ObservationPastActions,
  SignalCard,
  ActionCard,
  TableSignals,
  TablePastSignals,
  ObservationEventDetailContent,
  ObservationDetailCall,
  StatusButton,
  ObservationCreateTicket,
  ObservationCreateCustomerNote,
  SignalsHeader,
  Table,
  TableAtomic,
  TableRow,
  RadioGroup,
  RadioButton,
  Input,
  InputSearch,
  InputAutocomplete,
  CallModal,
  PageLoader,
  Pagination,
  Notification,
  SelectboxMultiple,
  Selectbox,
  Dropdown,
  FilterAdvanced,
  FilterCollapser,
  FilterDate,
  SvgIconArrowFill,
  SvgIconArrow,
  SvgIconSignal,
  SvgIconClock,
  SvgIconDotFill,
  SvgIconObservationNote,
  SvgIconObservationRedirect,
  SvgIconObservationService,
  SvgIconExport,
  SvgIconFilter
]

components.forEach(component => {
  Vue.component(component.name, component)
})

export {
  AppHeader,
  Button,
  HeaderNavbar,
  HeaderAvatar,
  StatisticButton,
  // FormForgot,
  // FormResetPassword,
  ObservationHeader,
  ObservationCard,
  ObservationPastSignals,
  ObservationEventDetail,
  ObservationPastActions,
  SignalCard,
  ActionCard,
  TableSignals,
  TablePastSignals,
  ObservationEventDetailContent,
  ObservationDetailCall,
  StatusButton,
  ObservationCreateTicket,
  ObservationCreateCustomerNote,
  SignalsHeader,
  Table,
  TableAtomic,
  TableRow,
  RadioGroup,
  RadioButton,
  Input,
  InputSearch,
  InputAutocomplete,
  CallModal,
  PageLoader,
  Pagination,
  SelectboxMultiple,
  Selectbox,
  Dropdown,
  FilterAdvanced,
  FilterCollapser,
  FilterDate,
  SvgIconArrowFill,
  SvgIconArrow,
  SvgIconSignal,
  SvgIconClock,
  SvgIconDotFill,
  SvgIconObservationNote,
  SvgIconObservationRedirect,
  SvgIconObservationService,
  SvgIconExport,
  SvgIconFilter
}
