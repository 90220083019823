<template>
  <div
    @click="setIsOpen(isOpen)"
    :class="colorClassName"
    class="signal-card-grid"
  >
    <div
      class="signal-card"
      v-tooltip.bottom="{
        content: $t(`signals.${data.category}`),
        delay: 500
      }"
    >
      <svg-icon-arrow-fill
        v-if="isDetails"
        :className="colorClassName"
        :rotate="isOpen ? 'up' : 'down'"
      />
      <div v-else></div>
      <div>
        <div v-if="data.createdAt" class="signal-card__time">
          <img
            src="@/assets/image/clock-stroke.svg"
            class="signal-card__icon"
          />
          {{ data.createdAt | ago($store.state.locale.lang) }}
        </div>
        <div class="signal-card__description">
          {{ data.description }}
          <div v-if="data.data" class="signal-card__description__tag-grid">
            <div v-for="(value, key) in data.data" :key="key">
              <span
                class="signal-card__description__tag-grid__item"
                v-if="!isObject(value)"
              >
                <span class="signal-card__description__tag-grid__item__label">
                  <span class="item-key">{{ $t(`signal_data.${key}`) }}</span>
                </span>
                <p>
                  <span>{{ value }}</span>
                </p>
              </span>
            </div>
          </div>
        </div>
      </div>

      <img
        v-if="data.device && data.device.type"
        v-tooltip="$t(`hardware_type.${data.device.type}`)"
        :src="
          hardwareTypes.includes(data.device.type)
            ? require(`@/assets/image/devices/${data.device.type}.svg`)
            : null
        "
      />
    </div>

    <!-- <div v-if="isOpen" class="signal-card-grid__past-signals-grid">
      <div v-if="isLoading" class="signal-card-grid__past-signals-grid__row">
        <img class="loader dark" size="16" src="@/assets/image/loader.png" />
      </div>
      <div
        v-if="!isLoading && !pastSignals.length"
        class="signal-card-grid__past-signals-grid__row"
      >
        <svg-icon-dot-fill :className="colorClassName" />
        <p>{{ $t('operation.operator-history-not-found') }}</p>
      </div>
      <div
        v-for="(item, index) in pastSignals"
        :key="index + '-detail-item'"
        class="signal-card-grid__past-signals-grid__row"
      >
        <svg-icon-dot-fill :className="colorClassName" />
        <p>{{ item.description }}</p>
        <p v-if="item.created_at">
          {{ item.created_at | ago($store.state.locale.lang) }}
        </p>
      </div>
    </div> -->
  </div>
</template>

<script>
import moment from 'moment'
import { SvgIconArrowFill, SvgIconDotFill } from '@/plugins/components'
import { HARDWARE_TYPE } from '@/constants'
import filterCollapserVue from '../../atomic/filter-collapser.vue'

export default {
  name: 'SignalCard',
  props: {
    data: {
      showSection: filterCollapserVue
    },
    isDetails: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    colorClassName() {
      if (this.data.signal_category == 'alarm') return 'fill-red'
      if (this.data.signal_category == 'hardware') return 'fill-orange'
      if (this.data.signal_category == 'operational') return 'fill-default'
      if (this.data.trigger == 'user' || this.data.signal_category == 'arm')
        return 'fill-blue'
      if (this.data.qualifier == 'restore') return 'fill-green'
      if (this.data.task_category != '') return 'fill-red'
      return 'fill-default'
    }
  },
  data() {
    return {
      hardwareTypes: ['INTRUSION', 'ACCESS_CONTROL', 'FIRE', 'IPC'],
      isLoading: false,
      isOpen: false,
      pastSignals: []
    }
  },
  methods: {
    async getDetails() {
      this.isLoading = true
      try {
        let { data } = await this.$store.dispatch(
          'observation/getPastSignalsDetails',
          this.data.publish_id
        )
        if (data) {
          console.log('Bu bölümde Event Detail kısmı verilecek')
          console.log(data)
          this.pastSignals = data
        }
      } catch (error) {
        console.log(error)
      }
      this.isLoading = false
    },
    setIsOpen(boolean) {
      this.isOpen = !boolean
      if (!this.isDetails) return
      // this.isOpen = boolean
      if (this.isOpen) {
        this.getDetails()
      }
    },
    displayZone(data) {
      if (data?.user) {
        if (data.user === 'mobile') {
          return this.$t('words.mobile')
        } else if (data.user !== 'mobile' && data.zone) {
          return `${data.zone}. ${this.$tc('words.user', 1)}`
        }
      } else {
        return `${data.zone}. ${this.$tc('words.zone', 1)}`
      }
    },
    isObject(value) {
      return typeof value === 'object'
    }
  },
  toggle() {
    this.showSection = !this.showSection
  },
  created() {
    // this.interval = setInterval(() => this.$forceUpdate(), 1000)
  },
  filters: {
    ago(value, lang) {
      return moment(value)
        .locale(lang)
        .format('DD.MM.Y, HH:mm')
    }
  }
}
</script>
