<template>
  <div class="form-grid form-grid--login">
    <p v-if="!!successMessage" class="message">{{ successMessage }}</p>
    <template v-if="!successMessage">
      <label class="label-input" for="password">
        <input
          class="auth-input"
          v-model.trim="form.password"
          :placeholder="$t('words.password')"
          :disabled="isLoading"
          type="password"
          id="password"
        />
      </label>
      <label class="label-input" for="repassword">
        <input
          class="auth-input"
          v-model.trim="form.passwordRepeat"
          :placeholder="$t('words.repassword')"
          :disabled="isLoading"
          type="password"
          id="repassword"
        />
      </label>
      <Button
        @click="submit()"
        class="fill secondary"
        :disabled="!token"
        :loading="isLoading"
        :text="
          $route.name == 'new-password'
            ? $t('button.create')
            : $t('button.save')
        "
      ></Button>
    </template>
  </div>
</template>

<script>
import { Button } from '@/plugins/components'

export default {
  name: 'FormResetPassword',
  computed: {
    token() {
      if (this.$route.query.reset_password_token)
        return this.$route.query.reset_password_token
      return null
    }
  },
  data() {
    return {
      errorMessage: null,
      successMessage: null,
      form: {
        password: null,
        passwordRepeat: null
      },
      isLoading: false
    }
  },
  methods: {
    isPassValid() {
      try {
        this.errorMessage = null
        if (!this.token) return
        if (!this.validPassword(this.form.password))
          this.errorMessage = this.$t(
            'forgot-password.validationErrors.regex-password'
          )
        if (this.form.password != this.form.passwordRepeat)
          this.errorMessage = this.$t('forgot-password.validationErrors.match')
      } catch (error) {
        console.log('invalid form')
      }

      if (this.errorMessage) this.$emit('onError', this.errorMessage)
      return !this.errorMessage
    },
    submit() {
      this.$emit('onError', null)
      this.isLoading = true
      if (this.isPassValid()) {
        let payload = {
          password: this.form.password,
          reset_password_token: this.token
        }

        this.$store
          .dispatch('password/sendNewPassword', payload)
          .then(r => {
            this.successMessage = this.$t(
              'forgot-password.successMessages.password-change'
            )
            setTimeout(() => {
              this.$emit('onError', '')
              this.navigate()
            }, 4000)
          })
          .catch(e => {
            this.errorMessage =
              e?.response?.data?.message ||
              this.$t('forgot-password.validationErrors.password-change')
            this.$emit(
              'onError',
              e?.response?.data?.message || this.errorMessage
            )

            this.$notify({
              type: 'warn',
              text: this.errorMessage
            })
            this.isLoading = false
          })
      } else {
        this.isLoading = false
      }
    },
    navigate() {
      this.$router.push({ name: 'login' })
    }
  },
  created() {
    this.$emit('onError', null)
    if (!this.token) {
      this.errorMessage = this.$t('forgot-password.validationErrors.token')
      this.$emit('onError', this.errorMessage)
    }
  }
}
</script>

<style lang="scss" scoped>
.message {
  font-size: 16px;
  line-height: 1.6;
  color: white;
}

.flex {
  display: flex;
}

.row {
  flex-direction: row;
}

.column {
  flex-direction: column;
}

.margin-center-10 {
  margin: 10px 0;
}

.action-grid {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.checkbox-remember {
  align-items: center;

  input {
    width: max-content;
    margin-right: 5px;
  }
  p {
    color: var(--text-color--white);
    font-size: 14px;
  }
}
</style>
