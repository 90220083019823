<template>
  <svg
    class="default"
    width="36"
    height="36"
    viewBox="-11 -5 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.273 15.776l-2.178-1.522c-.285-.198-.618-.303-.963-.303-.549 0-1.064.27-1.378.72l-.505.724c-.848-.568-1.793-1.362-2.694-2.263-.901-.9-1.695-1.846-2.263-2.693l.724-.506c.76-.53.947-1.58.417-2.341L3.91 5.414c-.318-.455-.831-.726-1.373-.726-.188 0-.372.032-.547.097-.2.074-.385.164-.566.278l-.301.212c-.075.059-.145.122-.212.19-.367.366-.628.83-.774 1.38-.627 2.35.926 5.902 3.864 8.84 2.467 2.468 5.431 4 7.736 4 .394 0 .766-.045 1.105-.135.549-.147 1.013-.408 1.38-.774.067-.067.13-.137.199-.226l.212-.302c.104-.167.194-.352.268-.551.256-.693-.002-1.483-.63-1.921z"
      class="fill-default"
      stroke-width="0."
    />
  </svg>
</template>
<script>
export default {
  name: 'SvgIconObservationRedirect',
  props: {
    className: {
      type: String,
      default: 'fill-default'
    }
  }
}
</script>
<style lang="scss" scoped>
.default {
  cursor: pointer;
  background-color: #fff;
  border-radius: 50px;
  box-shadow: 0 2px 3px #00000040;
  transition: var(--transition-hover-background);

  &.active {
    background-color: #000940;
    .fill-default {
      stroke: #fff;
    }
  }

  &:hover {
    background-color: #000940;
    .fill-default {
      stroke: #fff;
    }
  }

  .fill-default {
    stroke: #0a0e24;
  }
}
</style>
