<template>
  <div class="half_height">
    <TableAtomic
      :rows="array"
      :columns="columns"
      :loading="loading"
      :options="{ column: { icon: false } }"
      :styles="{
        backgroundColor: '#eaebf1',
        borderBottom: '1px solid #cecece',
        color: '#fff'
      }"
    >
      <template slot="row">
        <tr
          @click="$emit('onClick', item)"
          v-for="(item, index) in array"
          :key="index + 'row'"
          class="grid-table__line"
        >
          <td>
            {{
              dateConvertToFriendlyString(
                item.createdAt,
                $store.state.auth.user.timezone
              )
            }}
          </td>
          <td>
            <p v-if="item.customer" class="text__primary">
              {{ item.customer.name }}
            </p>
            <p
              v-if="item.customer && item.customer.owner"
              class="text__secondary"
            >
              {{ item.customer.owner.name }}
            </p>
            <!-- <p
              v-if="item.premise.address.city && item.premise.address.province"
              class="text__secondary"
            >
              {{ item.premise.address.city }} /
              {{ item.premise.address.province }}
            </p> -->
          </td>
          <td>
            <template v-if="item.device">
              <p class="text__primary">
                {{ $t(`hardware_type.${item.device.type}`) }}
              </p>
              <p class="text__secondary">
                {{ item.device.vendor }}
                {{ item.device.model }}
              </p>
            </template>
          </td>
          <td>
            <template v-if="item.premise">
              <p class="text__primary">
                {{ item.premise.name }} {{ item.area && `- ${item.area.name}` }}
              </p>
              <p class="text__secondary">
                {{ $t(`premiseTypes.${item.premise.type}`) }}
              </p>
            </template>
          </td>

          <td v-if="item.description">
            <!-- <span v-if="item.data">
              {{ item.description }}
              <br />
              <span v-if="item.data.partition && item.data.partition_name">
                {{ $t('words.partition') }}: {{ item.data.partition }} /
                {{ item.data.partition_name }}
              </span>
              <br />
              <span v-if="item.data.user">
                {{ $tc('words.user', 1) }}:
                <span style="text-transform:capitalize">
                  {{ item.data.user }}
                </span>
              </span>
            </span> -->
            <span
              >{{ item.description }}.
              <span v-if="item && item.data && item.data.user_name">
                {{ item.data.user_name }}
              </span>
            </span>
          </td>
          <td>
            <span>
              {{ $t(`signals.filter.qualifier.${item.isRestore}`) }}
            </span>
          </td>
          <td v-if="item.category">
            <span class="highlight-border">
              {{ $t(`signals.${item.category}`) }}
            </span>
          </td>
          <td></td>
        </tr>
      </template>
    </TableAtomic>
  </div>
</template>

<script>
import { TableAtomic } from '@/plugins/components'

export default {
  name: 'TablePastSignals',

  props: {
    array: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      columns: {
        'created-at': this.$t('signals.table.created-at'),
        customer: this.$t('signals.table.customer'),
        device: this.$t('signals.table.device'),
        premise: this.$t('signals.table.premise'),
        description: this.$t('words.description'),
        qualifier: this.$t('signals.filter.qualifier.type'),
        'signal-category': this.$t('signals.table.signal-category'),
        '': ''
      }
    }
  },
  methods: {
    handleClickRowEdit(rowItem) {
      this.$emit('onEdit', rowItem)
    }
  }
}
</script>

<style lang="scss" scoped>
.grid-table {
  &__line {
    min-height: auto !important;
    padding: 10px 20px !important;
  }
}
.half_height {
  height: 100%;
}
</style>
