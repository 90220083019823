<template>
  <div class="page-inner" style="height:850px">
    <div class="wrapper --padding-bottom-m fix-height">
      <filter-base
        @onChange="onFilterUpdate"
        :filterAdvanced="filterAdvanced"
        @onChangeItem="selectedCat($event)"
        style="margin-bottom: 20px"
      ></filter-base>
      <table-past-signals
        :loading="isLoading"
        :array="tableData"
      ></table-past-signals>
      <div class="pagination-grid">
        <pagination
          v-if="total > limit"
          :total-pages="Math.ceil(total / limit)"
          :total="total"
          :per-page="limit"
          :current-page="currentPage"
          @onChange="onPageChange"
        ></pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import {
  HARDWARE_TYPE,
  DEVICE_VENDOR_MODEL,
  PREMISE_TYPE,
  SIGNAL_TYPE,
  SIGNAL_CATEGORY,
  SIGNAL_TYPE_CAT
} from '@/constants'
import FilterBase from '@/components/views/past-signals/filter-base.vue'
import { getSignals, getCustomers } from '../services/core'

import {
  SignalsHeader,
  TablePastSignals,
  Pagination
} from '@/plugins/components'

export default {
  name: 'Signals',
  components: {
    FilterBase
  },
  computed: {
    ...mapState('auth', ['distributorId'])
  },
  data() {
    return {
      catTypes: [...SIGNAL_TYPE_CAT],
      signalCategory: [],
      limit: 10,
      tableData: [],
      isLoading: false,
      total: 0,
      currentPage: this.$route.query.page || 1,
      filterAdvanced: [
        {
          name: this.$t('signals.filter.device-type'),
          key: 'hardware_types',
          keys: [...HARDWARE_TYPE].map(type => ({
            id: type,
            label: this.$t(`hardware_type.${type}`),
            value: false
          }))
        },
        {
          name: this.$t('signals.filter.device-vendor'),
          key: 'hardware_vendors',
          keys: Object.keys({ ...DEVICE_VENDOR_MODEL })
            .map(hardware_type =>
              Object.keys(DEVICE_VENDOR_MODEL[hardware_type])
            )
            .flat()
            .map(vendor => ({
              id: vendor,
              label: vendor,
              value: false
            }))
            .sort((a, b) => {
              if (a.label < b.label) return -1
              if (a.label > b.label) return 1
              return 0
            })
        },
        {
          name: this.$t('signals.filter.signal-category'),
          key: 'signal_categories',
          keys: [...SIGNAL_CATEGORY]
            .map(key => ({
              id: key,
              label: this.$t(`signals.${key}`),
              value: key
            }))
            .sort((a, b) => {
              if (a.label < b.label) return -1
              if (a.label > b.label) return 1
              return 0
            })
        },
        {
          name: this.$t('signals.filter.signal-type'),
          key: 'signal_types',
          keys: [...SIGNAL_TYPE_CAT]
            .map(item => ({
              id: item.value,
              label: this.$t(`signals.${item.value}`),
              value: item.value,
              cat_name: item.key
            }))
            .sort((a, b) => {
              if (a.label < b.label) return -1
              if (a.label > b.label) return 1
              return 0
            })
        },
        {
          name: this.$t('technic-service.premise-type'),
          key: 'premise_types',
          keys: [...PREMISE_TYPE]
            .map(type => ({
              id: type,
              label: this.$t(`words.${type}`),
              value: false
            }))
            .sort((a, b) => {
              if (a.label < b.label) return -1
              if (a.label > b.label) return 1
              return 0
            })
        }
      ]
    }
  },
  watch: {
    signalCategory(cat) {
      if (cat.length > 0) {
        let filteredSignalTypes = []
        var categories = this.catTypes
          .map(item => ({
            id: item.value,
            label: this.$t(`signals.${item.value}`),
            value: item.value,
            cat_name: item.key
          }))
          .sort((a, b) => {
            if (a.label < b.label) return -1
            if (a.label > b.label) return 1
            return 0
          })

        categories.forEach(item => {
          if (cat.includes(item.cat_name.toLowerCase())) {
            filteredSignalTypes.push(item)
          }
        })

        this.filterAdvanced[3].keys = filteredSignalTypes
        // this.catTypes = filteredSignalTypes
      } else {
        this.filterAdvanced[3].keys = [...SIGNAL_TYPE_CAT]
          .map(item => ({
            id: item.value,
            label: this.$t(`signals.${item.value}`),
            value: item.value,
            cat_name: item.key
          }))
          .sort((a, b) => {
            if (a.label < b.label) return -1
            if (a.label > b.label) return 1
            return 0
          })
      }
    }
  },
  methods: {
    selectedCat(val) {
      if (SIGNAL_CATEGORY.includes(val.value)) {
        if (val.isChecked) {
          if (!this.signalCategory.includes(val.value)) {
            this.signalCategory.push(val.value)
          }
        } else {
          if (this.signalCategory.includes(val.value)) {
            this.signalCategory.splice(
              this.signalCategory.indexOf(val.value),
              1
            )
          }
        }
      }
    },
    onPageChange(page) {
      this.currentPage = parseInt(page)
      this.addQueryParams({ page })
      this.getCustomerSignals()
    },
    addQueryParams(query) {
      this.$router.push({
        query: {
          ...this.$route.query,
          ...query
        }
      })
    },
    async getCustomerSignals() {
      this.isLoading = true
      this.tableData = []
      try {
        let { data } = await getSignals({
          page: this.currentPage,
          limit: this.limit,
          ...this.$route.query
        })
        console.log('istek')

        if (data.success) {
          this.tableData = data.data.result[0].data
          this.total = data.data.result[0].count
        }

        this.isLoading = false
      } catch (error) {
        this.isLoading = false
      }
    },
    onFilterUpdate() {
      this.currentPage = parseInt(this.$route.query.page)
      this.getCustomerSignals()
    }
  },
  created() {
    let signalCategories = this.$route.query['signal_categories']
    if (signalCategories) {
      if (Array.isArray(signalCategories)) {
        signalCategories.forEach(item => {
          if (SIGNAL_CATEGORY.includes(item.value)) {
            this.signalCategory.push(item.value)
          }
        })
      } else {
        if (SIGNAL_CATEGORY.includes(signalCategories)) {
          this.signalCategory.push(signalCategories)
        }
      }
    }

    this.getCustomerSignals()

    let queryPage = parseInt(this.$route.query.page)
    if (queryPage) {
      this.currentPage = queryPage
    } else {
      this.currentPage = 1
    }
  }
}
</script>

<style lang="scss">
.pagination-grid {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-top: 20px;
}

.fix-height {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 20px 29px !important;
}
</style>
