<template>
  <div class="form-grid form-grid--login">
    <label class="label-input" for="email">
      <input
        class="auth-input"
        v-model.trim="form.email"
        :placeholder="$t('words.mail')"
        :disabled="isLoading"
        type="email"
        id="email"
      />
    </label>
    <label class="label-input" for="password">
      <input
        class="auth-input"
        @keyup.enter="submit"
        v-model.trim="form.password"
        :placeholder="$t('words.password')"
        :disabled="isLoading"
        type="password"
        id="password"
      />
    </label>
    <div class="flex row action-grid margin-center-10">
      <label class="flex row checkbox-remember" for="remember">
        <input
          class="auth-input"
          @keyup.enter="submit"
          type="checkbox"
          v-model="isRemember"
          id="remember"
        />
        <p>{{ $t('words.remember-me') }}</p>
      </label>
      <button
        class="btn-link btn-link--color-white"
        @click="$router.push({ name: 'login-forgot' })"
      >
        {{ $t('words.forgot-password') }}
      </button>
    </div>
    <Button
      class="fill secondary"
      @click="submit"
      :loading="isLoading"
      :text="$t('button.login')"
    ></Button>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { login, getCurrentUser } from '../../services/core'

export default {
  name: 'FormLogin',
  data() {
    return {
      isRemember: false,
      form: {
        email: null,
        password: null
      },
      isLoading: false
    }
  },
  methods: {
    ...mapActions({
      setAuthUser: 'auth/setAuthUser',
      setToken: 'auth/setToken'
    }),
    async submit(e) {
      e.preventDefault()
      try {
        this.$emit('onError', null)
        this.isLoading = true

        const formErrorMessages = this.formController()

        if (formErrorMessages.length > 0) {
          this.isLoading = false

          formErrorMessages.forEach(msg => {
            this.$emit('onError', msg)
          })
        } else {
          const { data } = await login(this.form)

          if (!data.success) {
            this.$emit('onError', data.message.en)
            this.isLoading = false
            return
          }

          this.setToken(data.data.token)

          const me = await getCurrentUser()

          this.setAuthUser(me.data.data.result[0])

          this.isLoading = false

          this.$router.push({
            name: 'observation',
            params: { category: 'general' }
          })
        }
      } catch (err) {
        this.$emit('onError', err)
        this.isLoading = false
      }
    },
    formController() {
      let errorsList = []
      if (!this.validEmail(this.form.email)) {
        errorsList.push(this.$i18n.t('navigation.login.email-wrong'))
      }
      if (!this.form.password) {
        errorsList.push(this.$i18n.t('navigation.login.password-wrong'))
      }
      if (this.form.password && this.form.password.length < 4) {
        errorsList.push(this.$i18n.t('navigation.login.password-wrong'))
      }
      return errorsList
    }
  }
}
</script>

<style lang="scss" scoped>
.flex {
  display: flex;
}

.row {
  flex-direction: row;
}

.column {
  flex-direction: column;
}

.margin-center-10 {
  margin: 10px 0;
}

.action-grid {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.checkbox-remember {
  align-items: center;

  input {
    width: max-content;
    margin-right: 5px;
  }
  p {
    color: var(--text-color--white);
    font-size: 14px;
  }
}
</style>
