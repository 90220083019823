<template>
  <div class="grid-date">
    <div class="input-group input-group--full">
      <label for="from_created_at" class="label">{{
        $t('signals.filter.start-date')
      }}</label>
      <flat-pickr
        class="input input--full input--dark"
        v-model="date.from_created_at"
        @input="handleInput"
        :config="{
          ...datePickrConfigNormal,
          maxDate: date.to_created_at,
          onChange: (selectedDate, dateStr) =>
            setFormatAndEmit({ from_created_at: setTimes('from', dateStr) })
        }"
        name="from_created_at"
      ></flat-pickr>
    </div>
    <div class="input-group input-group--full">
      <label for="to_created_at" class="label">{{
        $t('signals.filter.end-date')
      }}</label>
      <flat-pickr
        class="input input--full input--dark"
        v-model="date.to_created_at"
        @input="handleInput"
        :config="{
          ...datePickrConfigNormal,
          maxDate: new Date(Date.now()),
          onChange: (selectedDate, dateStr) =>
            setFormatAndEmit({ to_created_at: setTimes('to', dateStr) })
        }"
        name="to_created_at"
      ></flat-pickr>
    </div>

    <button
      v-for="(day, key) in dateButtons"
      :key="key"
      @click="setDates(day)"
      class="button button--filter button--filter--fill"
    >
      {{ $t(`signals.filter.${key}`) }}
    </button>
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import moment from 'moment-timezone'

export default {
  name: 'FilterDate',
  components: {
    flatPickr
  },
  data() {
    return {
      date: {
        from_created_at: '',
        to_created_at: ''
      },
      dateButtons: {
        today: 1,
        'last-week': 7,
        'last-week-2': 14,
        'last-month': 30
      }
    }
  },
  methods: {
    addQueryParams(query) {
      this.$router
        .replace({
          query: {
            ...this.$route.query,
            ...query
          }
        })
        .catch(err => {})
    },
    removeQueryParams(key) {
      let query = { ...this.$route.query }
      delete query[key]
      this.$router.replace({ query })
    },
    setFormatAndEmit(date) {
      this.addQueryParams(date)
      this.$emit('onChange', this.date)
    },
    handleInput(e) {
      this.$emit('onChange', this.date)
    },
    setDates(day) {
      let t = new Date(Date.now())
      let today = moment(t)
      // today.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })

      if (day == 1) {
        this.date = {
          to_created_at: '',
          from_created_at: today.toISOString()
        }

        return
      }
      this.date = {
        to_created_at: today.toISOString(),
        from_created_at: today
          .subtract(day, 'days')
          .startOf('day')
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
          .toISOString()
      }
    },
    setTimes(type, dateStr) {
      if (type === 'from') {
        return moment(dateStr)
          .set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0
          })
          .toISOString()
      }
      if (type === 'to') {
        if (moment(dateStr).isSame(new Date(), 'day')) {
          return moment().toISOString()
        } else {
          return moment(dateStr)
            .set({
              hour: 23,
              minute: 59,
              second: 59,
              millisecond: 999
            })
            .toISOString()
        }
      }
    }
  },
  created() {
    let { from_created_at, to_created_at } = this.$route.query
    this.date = {
      from_created_at,
      to_created_at
    }
    console.log(this.date)
  }
}
</script>

<style lang="scss" scoped>
.grid-date {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  padding: 15px;
}
</style>
