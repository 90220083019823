import moment from 'moment-timezone'

export default {
  methods: {
    taskCategoryNaming(value) {
      if (value === 'FIRE') {
        return this.$t('words.fire-signal')
      } else if (value === 'SECURITY') {
        return this.$t('words.secure-signal')
      } else if (value === 'AMBULANCE') {
        return this.$t('words.panic-signal')
      } else if (value === 'TECHNIC') {
        return this.$t('words.technic-signal')
      } else if (value === 'OTHER') {
        return this.$t('words.other')
      } else {
        return value
      }
    },
    /**
     * on observation card component. status translator
     * @param {String} value
     */
    taskStatusNaming(value) {
      if (value === 'OPEN') {
        return this.$t('words.new')
      } else if (value === 'leave') {
        return this.$t('words.leave-task')
      } else if (value === 'redirected') {
        return this.$t('operation.redirected')
      } else if (value === 'informed_person_in_charge') {
        return this.$t('operation.information')
      } else if (value === 'cannot_be_reach_person_in_charge') {
        return this.$t('operation.failed')
      } else if (value === 'POSTPONED') {
        return this.$t('operation.delay')
      } else if (value === 'waiting_feedback') {
        return this.$t('operation.waiting')
      } else if (value === 'ASSIGNED') {
        return this.$t('operation.assigned')
      } else if (value === 'CLOSED') {
        return this.$t('operation.informed')
      } else {
        return value
      }
    },
    /**
     * Customer premise type names localization
     * @param {String} value
     */
    premiseTypeNaming(value) {
      if (value == 'home') return this.$tc('words.premise-type', 0)
      if (value == 'work_place') return this.$tc('words.premise-type', 1)
      if (value == 'depot') return this.$tc('words.premise-type', 2)
      return value
    }
  }
}
