<template>
  <ul class="navbar">
    <li
      class="navbar__item"
      :class="{ 'navbar__item--active': $route.name.includes(item.route) }"
      v-for="(item, index) in menu"
      :key="index"
      @click="visitPage(item)"
    >
      <img :src="item.icon" class="navbar__item__icon" />
      <span class="navbar__item__text">{{ item.title }}</span>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'HeaderNavbar',
  data() {
    return {
      menu: [
        {
          title: this.$t('words.tasks'),
          icon: require('@/assets/image/alarms-white.svg'),
          route: 'observation'
        },
        {
          title: this.$t('words.past-tasks'),
          icon: require('@/assets/image/alarms-white.svg'),
          route: 'informed'
        },
        {
          title: this.$t('words.signals'),
          icon: require('@/assets/image/signals-white.svg'),
          route: 'live-signals'
        },
        {
          title: this.$t('words.past-signals'),
          icon: require('@/assets/image/signals-white.svg'),
          route: 'past-signals'
        },
        {
          title: this.$t('words.customers'),
          icon: require('@/assets/image/customers-white.svg'),
          externalRoute: this.externalRoute()
        }
      ]
    }
  },
  methods: {
    visitPage(page) {
      if (page.externalRoute) {
        window.open(page.externalRoute)
        return
      }
      if (this.$route.name == page.route) return
      this.$router.push({ name: page.route, params: { category: 'general' } })
    },
    externalRoute() {
      const env = process.env.NODE_ENV

      if (env === 'production') {
        return 'https://distributor.hybrone.com/?redirect=Customer'
      } else if (env === 'development') {
        return 'https://test.distributor.hybrone.com/?redirect=Customer'
      }

      return `https://${env}.distributor.hybrone.com/?redirect=Customer`
    }
  }
}
</script>
