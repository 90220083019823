<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 10C1 5.03562 5.03562 1 10 1C14.9644 1 19 5.03562 19 10C19 14.9644 14.9644 19 10 19C5.03562 19 1 14.9644 1 10Z"
      :stroke="fill"
      stroke-width="2"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.80331 4.545C9.40998 4.545 9.09164 4.865 9.09164 5.25833V11.6733C9.09164 11.9367 9.23498 12.1617 9.44664 12.285L14.38 15.135C14.7216 15.3333 15.1633 15.22 15.36 14.88C15.5566 14.5383 15.4333 14.1083 15.0933 13.9117L10.5166 11.2617V5.25667C10.5166 4.86333 10.1966 4.54333 9.80331 4.54333V4.545Z"
      :fill="fill"
    />
  </svg>
</template>
<script>
export default {
  name: 'SvgIconClock',
  props: {
    size: {
      type: Number,
      default: 20
    },
    fill: {
      type: String,
      default: '#000'
    }
  }
}
</script>
