import Vue from 'vue'

export default {
  namespaced: true,
  actions: {
    searchCustomer(_, keyword) {
      return Vue.prototype.$http.get(
        `/accounts?keyword=${keyword}&statistics=true&limit=20`
      )
    },
    getCustomers(_, keyword) {
      return Vue.prototype.$http.get(`/accounts?keyword=${keyword}`)
    },
    getCustomerList(_) {
      return Vue.prototype.$http.get(`/accounts?statistics=true&limit=20`)
    },
    getCustomer(_, id) {
      return Vue.prototype.$http.get(`/accounts/${id}`)
    },
    getCustomerStats(_, id) {
      return Vue.prototype.$http.get(`/stats/accounts/${id}`)
    },
    /**
     * @param {Object} data {key:value} e.g. keys: premise_id, account_id
     */
    getCustomerDevices(_, data) {
      return Vue.prototype.$http.get(
        `/devices?${Object.keys(data).toString()}=${Object.values(
          data
        ).toString()}`
      )
    },
    getCustomerTickets(_, data) {
      console.log(data)
      return Vue.prototype.$logs.get(`/tickets`, { params: data })
    },
    getCustomerUsers(_, id) {
      return Vue.prototype.$http.get(`/users?joined_account_id=${id}`)
    },
    getPremise(_, premiseId) {
      return Vue.prototype.$http.get(`/premises/${premiseId}`)
    },
    getDevices(_, premiseId) {
      return Vue.prototype.$http.get(`/devices?premise_id=${premiseId}`)
    },
    getPremiseAreas(_, { premise_id }) {
      return Vue.prototype.$http.get(`/areas?premise_id=${premise_id}`)
    },
    getCustomersPremise(_, id) {
      return Vue.prototype.$http.get(`/premises?account_id=${id}`)
    },
    getCustomersTask(_, id) {
      return Vue.prototype.$logs.get(`/tasks?account_id=${id}`)
    },
    getSignals(_, params) {
      return Vue.prototype.$logs.get(`/messages`, { params })
    },
    addCustomerNote(_, data) {
      let { account_id, expire_at, description, category } = data
      let payload = { expire_at, category, description }
      if (!expire_at) delete payload.expire_at
      return Vue.prototype.$http.post(`/accounts/${account_id}/notes`, payload)
    },
    deleteCustomerNote(_, data) {
      let { account_id, id } = data
      return Vue.prototype.$http.delete(`/accounts/${account_id}/notes/${id}`)
    }
  }
}
