<template>
  <div class="past-actions-wrapper">
    <ul class="past-actions-list">
      <div class="past-actions-title">
        {{ $t('operation.operator-history') }}
        <div class="past-actions-title__note">
          <textarea
            v-model.trim="text"
            :placeholder="$t('words.enter-note')"
            class="textarea"
            style="max-width:100%"
          ></textarea>
          <Button
            @click="handleClickSave()"
            class="fill primary"
            :disabled="!text"
            height="30"
            :class="{ fill: text }"
            :text="$t('words.save')"
          ></Button>
        </div>
      </div>
      <li
        class="past-actions-list__item"
        v-for="(item, key) in actions"
        :key="key + '-past-action-item'"
      >
        <action-card :data="item"></action-card>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { ActionCard } from '@/plugins/components'
import { addNoteToActions } from '../../../services/core'

export default {
  name: 'ObservationPastActions',
  props: {
    actions: { type: Array, default: () => [] }
  },
  data() {
    return {
      text: null
    }
  },
  methods: {
    ...mapActions({
      addTaskActionNote: 'observation/addTaskActionNote'
    }),
    handleClickSave() {
      // console.log(this.data)
      if (!this.$route.params.id || !this.text) return

      addNoteToActions({ id: this.$route.params.id, note: this.text })
        .then(({ data }) => {
          if (data.success) {
            this.text = ''
            this.$emit('onNoteCreated')
          }
        })
        .catch(err => console.log(err))
    }
  }
}
</script>
