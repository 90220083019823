import Axios from 'axios'

const versionController = async ({ store }) => {
  if (!localStorage) return

  // const { version } = require('../../package.json')
  const protocol = process.env.NODE_ENV === 'development' ? 'http' : 'https'
  const url = `${protocol}://${window.location.host}/release.txt`
  const { data: version } = await Axios.get(url)
  console.log({ url, version })
  const storedVersion = localStorage.getItem('version') || ''

  if (version !== storedVersion) {
    localStorage.setItem('version', version)
    if (store.state.auth.token) store.dispatch('auth/versionUpgrade', version)
    if (window) window.location.reload()
  }
}

export default versionController
