import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    tasks: []
  },
  actions: {
    listTasks({ commit }, filter) {
      const query = {
        statuses: filter.status.join(),
        ...filter
      }

      if (filter.category != null && filter.category) {
        query['categories'] = filter.category
      }

      delete query.category
      delete query.status

      return Vue.prototype.$logs.get(`/tasks`, {
        params: query
      })
    },
    getSignals({ commit }, category) {
      console.log(category)
      let url = `/tasks?statuses=new,waiting_feedback&&sort_by=priority_level `
      if (category) {
        url += `&category=${category}`
      }
      console.log(url)
      return Vue.prototype.$http.get(url)
    },
    getSignalsByAssigned({ commit }) {
      return Vue.prototype.$logs
        .get(`/tasks?statuses=assigned`)
        .then(response => {
          commit('SET_TASK_LIST', response.data)
          return response
        })
    },
    getSignal(context, taskId) {
      console.log(taskId)
      return Vue.prototype.$logs.get(`/tasks/${taskId}`)
    },
    taskAssign(context, taskId) {
      return Vue.prototype.$logs.put(`/tasks/${taskId}/assign`)
    },
    taskUnassign(context, taskId) {
      return Vue.prototype.$logs.put(`/tasks/${taskId}/unassign`)
    },
    changeTaskStatus(context, [taskId, status]) {
      return Vue.prototype.$logs.put(`/tasks/${taskId}/change_status`, {
        status: status
      })
    },
    taskPostpone(context, [taskId, time]) {
      return Vue.prototype.$logs.patch(`/tasks/${taskId}/postpone`, {
        postponed_at: time
      })
    },
    call(context, [taskId, phone_number, token]) {
      return Vue.prototype.$switchboard.post(`/tasks/${taskId}/call`, null, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Target-Call': `${phone_number}`
        }
      })
    },
    getCallRecord(context, { taskId, token, uuid }) {
      return Vue.prototype.$switchboard.get(`/tasks/${taskId}/call/recording`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Call-Recording-URL': `${uuid}`
        }
      })
    },
    getCallHook(context, data) {
      let { phone_number, token } = data

      console.log(phone_number, token)
      return Vue.prototype.$switchboard.get(`/webhooks/bulutfon/call`, null, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Target-Call': phone_number
        }
      })
    },
    getObservationTaskStats(context, { statuses, isShowDmc }) {
      return Vue.prototype.$logs.get(
        `/tasks/stats/categories?statuses=${statuses.join()}`,
        {
          params: {
            is_show_dmc: isShowDmc || null
          }
        }
      )
    },
    addTaskActionNote(context, { id, type, description }) {
      return Vue.prototype.$logs.put(`/tasks/${id}/actions/`, {
        type,
        description
      })
    },
    // /tasks/actions?puhlish_id=''
    getPastSignalsDetails(_, publish_id) {
      return Vue.prototype.$logs.get(`/tasks/actions/${publish_id}`)
    },
    getPastSignals({ state }, [to_created_at, device_id, from_created_at]) {
      let search = new URLSearchParams()
      search.append('limit', 30)
      search.append('to_created_at', to_created_at)
      search.append('device_ids', device_id)
      search.append('from_created_at', from_created_at)
      return Vue.prototype.$logs.get(`/messages`, {
        params: search
      })
    },
    getPanelConfig(_, deviceId) {
      return Vue.prototype.$panel.get(`/devices/` + deviceId)
    }
  },
  mutations: {
    SET_TASK_LIST(state, payload) {
      for (let i = 0; i < payload.length; i++) {
        state.tasks.push(payload[i])
      }
    }
  }
}
