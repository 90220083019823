<template>
  <div class="call-modal" :class="{ 'call-modal--active': isOpen }">
    <template v-if="!isCall">
      <img
        v-tooltip="$t('words.close') + ' (ESC)'"
        src="@/assets/image/close.svg"
        class="call-modal__close"
        @click="close()"
      />
      <div class="call-modal__person">
        {{ $t('operation.call-message') }}
      </div>
      <div class="call-modal__button call-modal__button--normal" @click="call">
        {{ $tc('operation.call-button', 1) }}
      </div>
    </template>
    <template v-else>
      <div class="call-modal__person">
        {{ calledPerson && calledPerson.name }}
        ({{ calledPerson && calledPerson.phone }})
      </div>
      <div class="call-modal__pin">
        {{ pinHidden(pin) }}
      </div>
      <div class="call-modal__button" @click="handleClose">
        <img
          src="@/assets/image/call-exit.svg"
          class="call-modal__button__icon"
        />
        {{ $tc('operation.call-button', 2) }}
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { startCall } from '../../services/core'

export default {
  name: 'CallModal',
  props: {
    calledPerson: {},
    pin: {}
  },
  computed: {
    ...mapState('auth', ['token']),
    ...mapState('global', ['callNumber'])
  },
  watch: {
    calledPerson: function(value) {
      if (value) {
        this.isOpen = true
      } else {
        this.isOpen = false
      }
    }
  },
  data() {
    return {
      isOpen: false,
      isCall: false
    }
  },
  methods: {
    ...mapActions({
      // call: 'observation/call',
      setCallNumber: 'global/setCallNumber',
      addTaskActionNote: 'observation/addTaskActionNote',
      getCallRecord: 'observation/getCallRecord'
    }),
    async call() {
      try {
        // console.log(
        //   'call started',
        //   this.calledPerson.phone,
        //   this.$route.params.id
        // )

        const { data } = await startCall(
          this.calledPerson.phone,
          this.$route.params.id
        )

        if (data.success) {
          this.isCall = true
          document.addEventListener('keyup', this.handleKeyUp)
        }
      } catch (err) {
        console.log(err)
        this.isCall = false
      }
    },
    close() {
      this.isOpen = false
      this.setCallNumber('')
    },
    pinHidden(text) {
      let pin = ''
      if (text) {
        for (let i = 0; i < text.length - 2; i++) {
          pin += '*'
        }
        return pin + text.substr(-2)
      }
    },
    handleClose() {
      // this.$emit('onClosed')
      this.isOpen = false
      this.isCall = false
      document.removeEventListener('keyup', this.handleKeyUp)
    },
    handleKeyUp(event) {
      let { keyCode } = event
      if (keyCode == 27) this.isOpen = false
    }
  },
  beforeDestroy() {
    document.removeEventListener('keyup', this.handleKeyUp)
  }
}
</script>
