<template>
  <div id="app">
    <vue-progress-bar></vue-progress-bar>
    <notifications classes="hybone-notification" position="bottom right" />
    <app-header v-if="isLoggedIn"></app-header>
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { AppHeader } from '@/plugins/components'
import VueProgressBar from 'vue-progressbar'

export default {
  name: 'App',
  computed: {
    ...mapState('auth', ['isLoggedIn'])
  }
}
</script>

<style lang="scss">
@import './assets/stylesheet/style';
</style>
