import router from '@/router'
import Vue from 'vue'
import { uuid } from 'vue-uuid'
import moment from 'moment-timezone'

import i18n from '@/plugins/i18n'

export default {
  namespaced: true,
  state: {
    isLoggedIn: false,
    token: '',
    distributorId: '',
    user: {},
    timezone: '',
    language: ''
  },
  actions: {
    versionUpgrade({ commit }, version) {
      alert(i18n.t('userAlerts.newVersionAvailable', { version: version }))
      commit('SET_IS_LOGGED_IN', false)
      commit('RESET_AUTHENTICATION')
      localStorage.removeItem('vuex')
      router.push({ name: 'login' })
    },
    logout({ commit }) {
      commit('SET_IS_LOGGED_IN', false)
      commit('RESET_AUTHENTICATION')
      router.push({ name: 'login' })
    },
    setToken({ commit }, token) {
      commit('SET_TOKEN', token)
    },
    setAuthUser({ commit }, data) {
      let payload = { ...data }
      let lang = navigator.language.split('-')[0].toLocaleUpperCase()
      let timezone = moment.tz.guess()
      if (!payload.language) {
        payload.language = lang
      }
      if (!payload.timezone) {
        payload.timezone = timezone
      }
      if (!payload.currency) {
        payload.currency = 'TRY'
      }
      this.dispatch('locale/setLang', payload.language.toLowerCase())
      commit('SET_AUTH_USER', payload)
      commit('SET_DISTRIBUTOR_ID', payload.distributorId)
      commit('SET_IS_LOGGED_IN', true)
    },
    getUser({ commit }, id) {
      Vue.prototype.$http.get('/users/' + id).then(response => {
        commit('SET_USER', response.data)
        commit('SET_DISTRIBUTOR_ID', response.data.job.distributor_id)
      })
    }
  },
  mutations: {
    RESET_AUTHENTICATION(state) {
      state.user = {}
    },
    SET_IS_LOGGED_IN(state, payload) {
      state.isLoggedIn = payload
    },
    SET_TOKEN(state, token) {
      state.user.token = token
    },
    SET_AUTH_USER(state, payload) {
      state.user = { ...state.user, ...payload }
    },
    SET_DISTRIBUTOR_ID(state, payload) {
      state.distributorId = payload
    }
  }
}
