<template>
  <div class="login-grid">
    <div class="container">
      <span class="header-grid">
        <div class="login-logo"></div>
      </span>
      <div
        v-if="$route.name != 'login'"
        class="flex row action-grid margin-center-10"
      >
        <button
          class="btn-link btn-link--color-white"
          @click="$router.push({ name: 'login' })"
        >
          {{ $t('words.back-to-login') }}
        </button>
      </div>
      <div v-if="$route.name !== 'user-registration'" class="page-title">
        {{ $t(`navigation.${routerName}.title`) }}
      </div>
      <div v-if="$route.name == 'user-registration'" class="page-title">
        {{ $t(`forgot-password.titles.new`) }}
      </div>
      <!-- <router-view @onError="onError" /> -->

      <div v-if="$route.name === 'login'">
        <form-login />
      </div>
      <div v-if="$route.name.includes('forgot')">
        <form-forgot />
      </div>
      <p
        class="error-message"
        :style="{ visibility: errors.length > 0 ? 'visible' : 'hidden' }"
        v-html="join(errors)"
      ></p>
    </div>
    <div class="login-image"></div>
  </div>
</template>

<script>
import FormForgot from '../components/form/form-forgot.vue'
import formLogin from '../components/form/form-login.vue'
export default {
  name: 'login',
  components: { formLogin, FormForgot },
  computed: {
    routerName() {
      return this.$route.name
    },
    pageName() {
      return this.$route.name
    }
  },
  data() {
    return {
      errors: []
    }
  },
  methods: {
    join(value) {
      return value.join('<br/>')
    },
    onError(e) {
      if (!e) {
        this.errors = []
        return
      }
      this.errors.push(e)
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 300px;
  display: grid;
  grid-template-rows: auto auto auto;
  grid-row-gap: 30px;
  height: max-content;
  margin: auto;

  .page-title {
    color: var(--text-color--white);
    font-size: 21px;
    font-weight: 500;
  }
}

.header-grid {
  display: grid;
  grid-template-rows: auto auto;
  margin-bottom: 30px;
}

.error-message {
  color: var(--color-red);
  font-size: 14px;
  border-radius: 2px;
  line-height: initial;
  padding: 6px 8px;
  height: 3.4em;
}

.login-grid {
  width: 100vw;
  height: 100vh;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  background: var(--background-login-light);
}

.login-image {
  background: url('../assets/image/login-image.png'),
    var(--background-login-dark);
  background-size: 50%;
  background-position: center;
  background-repeat: no-repeat;
}

@media only screen and (max-width: 1024px) {
  .login-grid {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .login-image {
    display: none;
  }
  .container {
    position: relative;
    left: 0;
    top: 0;
  }
}
</style>
