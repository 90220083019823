<template>
  <div class="row statistic-wrapper">
    <div
      v-if="!$route.meta.isInformeds"
      class="pusher-status"
      :class="{
        'pusher-status--active': status.success,
        'pusher-status--error': status.error
      }"
    >
      {{ $tc('words.pusher-status', status.code) }}
    </div>
    <ul class="statistic-button-list">
      <li
        class="statistic-button-list__item"
        v-for="(key, index) in Object.keys(statButtons)"
        :key="index"
        @click="handleClickFilterButton(statButtons[key].route, key)"
      >
        <statistic-button :item="statButtons[key]"></statistic-button>
      </li>
      <li v-if="!$route.meta.isInformeds">
        <!-- checkbox -->
        <label class="flex row checkbox observation-checkbox" for="schedule">
          <input
            @change="handleOnlyPostponed"
            type="checkbox"
            id="schedule"
            v-model="isOnlyPostponed"
          />
          <div class="checkmark"></div>
          {{ $t(`words.only-postponed-tasks`) }}
        </label>
      </li>

      <slot name="filter"></slot>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'ObservationHeader',
  props: {
    isCheck: {
      type: Boolean,
      default: false
    },
    tasks: {},
    status: {},
    stats: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    // stats: {
    //   immediate: true,
    //   handler(newVal, oldVal) {
    //     if (Object.keys(newVal).length > 0) {
    //       Object.keys(newVal).forEach(key => {
    //         if (this.statButtons[key]) {
    //           this.statButtons[key].value = newVal[key]
    //         }
    //       })
    //     }
    //   }
    // }
  },
  data() {
    return {
      statButtons: {
        total: {
          title: this.$t('words.general'),
          value: 0,
          route: 'general'
        },
        fire: {
          title: this.$t('words.fire'),
          value: 0,
          route: 'fire'
        },
        ambulance: {
          title: this.$t('words.health'),
          value: 0,
          route: 'ambulance'
        },
        security: {
          title: this.$t('words.secure'),
          value: 0,
          route: 'security'
        },
        technic: {
          title: this.$t('words.technic'),
          value: 0,
          route: 'technic'
        }
      },
      isOnlyPostponed: false
    }
  },
  methods: {
    handleClickFilterButton(route, key) {
      this.$emit('clicked', { route, key })
      console.log({ route, key })
      // this.$router.push({
      //   name: 'observation',
      //   params: { category: route },
      //   query: this.$route.query
      // })
    },
    handleOnlyPostponed() {
      this.$emit('changePostponed', this.isOnlyPostponed)
    }
  },
  created() {
    // if (this.$route.query.filter) {
    //   this.isOnlyPostponed = true
    // }
    // this.handleOnlyPostponed()
  }
}
</script>
