<template>
  <details :ref="id">
    <summary :disabled="disabled">
      <input-search
        :id="id"
        :delay="delay"
        @input="handleInput"
        :placeholder="placeholder || $t('words.placeholder-search')"
        :loading="loading"
        :disabled="disabled"
        :value="value"
        @blur="closeModal"
        @updateValue="text => $emit('updateValue', text)"
      ></input-search>
    </summary>
    <div
      v-if="isOpen && data && data.length > 0"
      @click="handleClickPopup"
      class="popup"
    >
      <slot />
    </div>
  </details>
</template>

<script>
import { InputSearch } from '@/plugins/components'

export default {
  name: 'InputAutocomplete',
  components: { InputSearch },
  props: {
    id: {
      type: String,
      default: 'v-autocomplete'
    },
    delay: {
      type: Number,
      default: 1000
    },
    placeholder: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    data: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
    value: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      isOpen: false
    }
  },
  methods: {
    handleClickPopup() {
      this.closeModal()
    },
    handleInput(text) {
      let ref = this.$refs[this.id]

      if (text.length < 3) {
        this.isOpen = false
      } else {
        this.isOpen = true
      }

      this.$emit('input', text)

      if (ref) {
        ref.setAttribute('open', true)
      }
    },
    closeModal() {
      let ref = this.$refs[this.id]
      ref.removeAttribute('open')
    }
  }
}
</script>

<style lang="scss" scoped>
.popup {
  position: absolute;
  z-index: 99;
  width: 100%;
  border-radius: 2px;
  border: solid 1px var(--border-color);
  background-color: var(--background-color--main);
  padding: 5px;
  overflow: auto;
  max-height: 220px;

  li {
    width: 100%;
    height: 30px;
    border-radius: 2px;
    font-size: 12px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 22px;
    padding: 0px 5px;
    letter-spacing: normal;
    position: relative;
    user-select: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 270px !important;
    display: flex;
    align-items: center;
    cursor: pointer;

    &.disabled {
      opacity: 0.5;
    }

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
    }

    &:hover {
      background-color: rgba(17, 163, 210, 0.1);
      color: #11a3d2;
    }

    > span + span {
      margin-left: 5px;
    }
  }
}

details {
  display: flex;
  position: relative;
  width: 100%;
  max-width: 270px !important;

  summary {
    display: inline-block;
    user-select: none;
    list-style: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    &::-webkit-details-marker {
      display: none;
    }
  }

  &[open] > summary:before {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    display: block;
    cursor: default;
    content: ' ';
    background: transparent;
  }

  &[open] > summary {
    &__arrow {
      &--rotate {
        transform: rotate(180deg);
      }
    }
  }
}
</style>
