<template>
  <div class="past-signals-wrapper">
    <!-- <div
      v-if="recentSignals && recentSignals.messages"
      class="past-signals-title"
    >
      {{ $t('words.recent-signals-from-premise') }}
      {{
        recentSignals && recentSignals.messages
          ? `(${recentSignals.messages.length})`
          : ''
      }}
    </div> -->
    <!-- <ul v-if="recentSignals && recentSignals.messages" class="past-signal-list">
      <li
        class="past-signal-list__item"
        v-for="(item, key) in recentSignals.messages"
        :key="key + '-recent-signal-item'"
      >
        <signal-card :isDetails="false" :data="item"></signal-card>
      </li>
    </ul> -->
    <div class="past-signals-title">
      {{ $t('words.signal-history') }}
      {{ pastSignals.length ? `(${pastSignals.length})` : '' }}
    </div>
    <ul v-if="pastSignals" class="past-signal-list">
      <li
        class="past-signal-list__item"
        v-for="(item, key) in pastSignals"
        :key="key + '-past-signal-item'"
      >
        <signal-card :isDetails="false" :data="item"></signal-card>
      </li>
    </ul>
  </div>
</template>

<script>
import { SignalCard } from '@/plugins/components'

export default {
  name: 'ObservationPastSignals',
  props: {
    // recentSignals: {
    //   type: Object,
    //   default: () => {}
    // },
    pastSignals: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    recentSignals: function() {
      this.recentSignals.messages = this.recentSignals.messages.reverse()
    }
  }
}
</script>
