<template>
  <div class="status-button-parent">
    <div
      v-if="!data.isTime"
      class="status-button"
      :class="{ isPrimary: data.isPrimary }"
      @click="$emit('changeTaskStatus', data.status)"
    >
      <div class="status-button__title">
        {{ taskStatusNaming(data.status) }}
      </div>
    </div>

    <!-- for postponed -->
    <div
      v-if="data.isTime"
      @click.stop="!isClicked && (isShowPicker = true)"
      class="status-button"
      v-clickoutside="handleClickClosePicker"
    >
      <span
        class="input-group-btn status-button__title toggle-date-picker-postponed"
        role="button"
        data-toggle
      >
        {{ taskStatusNaming(data.status) }}
      </span>

      <div
        v-if="isShowPicker"
        class="status-button__times"
        style="width: 307.875px"
      >
        <div class="input-group">
          <flat-pickr
            v-model="pickerDate"
            :config="config"
            class="form-control"
            name="startDate"
          >
          </flat-pickr>
        </div>

        <Button
          :disabled="!dateStr"
          @click.stop="handlePickerConfirm()"
          width="310"
          class="fill primary btn-confirm"
          :text="$t('words.save')"
        ></Button>
        <Button
          @click.stop="handleClickClosePicker()"
          width="310"
          class="text primary btn-confirm"
          :text="$t('words.cancel')"
        ></Button>
      </div>
    </div>
  </div>
</template>

<script>
import { Button } from '@/plugins/components'
import minMaxTimePlugin from 'flatpickr/dist/plugins/minMaxTimePlugin.js'
import moment from 'moment'

export default {
  name: 'StatusButton',
  props: {
    data: {},
    isClicked: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isShowPicker: false,
      isChange: false,
      pickerDate: new Date(Date.now()),
      dateStr: null,
      config: {
        enableTime: 'true',
        time_24hr: true,
        minDate: 'today',
        maxDate: new Date().fp_incr(30),
        inline: true,
        altInputClass: 'input-date-picker-postponed',
        onValueUpdate: this.handleChangeDatePicker,
        plugins: [
          new minMaxTimePlugin({
            table: {
              [moment().format('YYYY-MM-DD')]: {
                minTime: Date.now(),
                maxTime: '23.59'
              }
            }
          })
        ]
      }
    }
  },
  methods: {
    handleClickClosePicker() {
      this.isShowPicker = false
    },
    handleChangeDatePicker(selectedDates, dateStr, instance) {
      if (moment(dateStr).isBefore(Date.now())) {
        this.dateStr = null
      } else {
        this.dateStr = dateStr
      }
    },

    handlePickerConfirm() {
      if (this.dateStr) {
        this.$emit('changeTaskStatus', this.dateStr)
        this.handleClickClosePicker()
      }
    }
  }
}
</script>

<style lang="scss">
.input-date-picker-postponed {
  visibility: hidden;
  z-index: 9999;
  position: fixed;
  top: 90px;
  width: 0px;
}

.toggle-date-picker-postponed {
  position: relative;
  z-index: 999;
  // for expand clickable area
  &::before {
    content: '';
    background-color: transparent;
    width: 200%;
    height: 200%;
    left: -65%;
    top: -50%;
    position: absolute;
  }
}
</style>
