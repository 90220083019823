<template>
  <div>
    <div class="page-inner --padding-bottom-m" style="float:none">
      <observation-header :stats="stats" @clicked="handleFilter">
        <details
          style="margin-left:40px"
          slot="filter"
          @toggle="onToggle"
          ref="advanced-filter-informed"
        >
          <summary class="button btn-filter">
            <svg-icon-filter
              :fill="isFiltered ? '#031141' : null"
            ></svg-icon-filter>
          </summary>
          <div v-if="isFilterOpen" class="popup">
            <div class="popup__grid">
              <div class="informed-filter-btn-group">
                <button @click="clearFilter" class="button button--outline">
                  {{ $t('signals.filter.clear-select') }}
                </button>
                <button @click="applyFilter" class="button button--dark">
                  {{ $t('signals.filter.save') }}
                </button>
              </div>

              <div
                v-for="(value, key) in filter"
                :key="key"
                class="input-group"
              >
                <label class="label">
                  {{ $t(`informed.${key}`) }}
                </label>
                <input type="text" class="input" v-model="filter[key]" />
              </div>
            </div>
          </div>
        </details>
      </observation-header>

      <page-loader v-if="status === 'loading'"></page-loader>

      <div v-if="status === 'not-found'" class="task-list__not-found">
        <svg-icon-signal :size="16" />
        <p>{{ $t('observation.not_found.informed') }}</p>
      </div>
      <transition-group
        v-show="status === 'success'"
        name="list"
        tag="ul"
        class="task-list "
      >
        <li
          class="task-list__item"
          v-for="(item, key) in data"
          :key="key + '-task'"
        >
          <!--  -->
          <observation-card
            :item="item"
            @click="goTaskDetail"
          ></observation-card>
        </li>
      </transition-group>

      <div class="informed-pagination">
        <pagination
          v-if="total > limit"
          :total-pages="Math.ceil(total / limit)"
          :total="total"
          :per-page="limit"
          :current-page="currentPage"
          @onChange="onPageChange"
        ></pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { getTasks } from '../services/core'
export default {
  name: 'InformedObservations',
  data() {
    return {
      data: [],
      stats: {},
      status: 'loading',
      total: null,
      limit: 20,
      currentPage: parseInt(this.$route.query.page) || 1,
      isFilterOpen: false,
      filter: {
        'customer.name': '',
        'customer.phoneNumber': '',
        'customer.email': '',
        'premise.name': ''
        // address: ''
      }
    }
  },
  computed: {
    isFiltered() {
      let filtered = false
      Object.keys(this.filter).forEach(key => {
        if (this.$route.query[key]) {
          filtered = true
          return
        }
      })

      return filtered
    }
  },
  methods: {
    ...mapActions({
      getObservationTaskStats: 'observation/getObservationTaskStats',
      listTasks: 'observation/listTasks'
    }),
    onToggle(event) {
      this.isFilterOpen = true
      if (event.target.getAttribute('open') != '') {
        this.isFilterOpen = false
      }
    },
    applyFilter() {
      let query = {}
      this.currentPage = 1

      Object.keys(this.filter)
        .filter(key => this.filter[key])
        .forEach(key => {
          query[key] = this.filter[key]
        })

      this.$router.replace({
        query: { ...query, page: 1 }
      })

      this.isFilterOpen = false
      this.$refs['advanced-filter-informed'].removeAttribute('open')

      this.getInformedTasks(1)
    },
    clearFilter() {
      let query = Object.assign({}, this.$route.query)

      Object.keys(this.filter).forEach(key => {
        if (query[key]) {
          delete query[key]
        }

        if (this.filter[key]) {
          this.filter[key] = ''
        }
      })

      this.$router.push({ name: 'informed', query })

      this.isFilterOpen = false
      this.$refs['advanced-filter-informed'].removeAttribute('open')

      this.getInformedTasks()
    },
    onPageChange(page) {
      this.currentPage = page
      this.$router.push({
        name: 'informed',
        query: { page, ...this.$route.query },
        params: this.$route.params
      })
      this.getInformedTasks(page)
    },
    handleFilter({ route, key }) {
      this.$router.replace({
        params: { category: route },
        query: { ...this.$route.query, page: 1 }
      })

      this.currentPage = 1
      this.getInformedTasks()
    },
    setTaskStats() {
      this.getObservationTaskStats({ statuses: ['informed'] })
        .then(res => (this.stats = res.data))
        .catch(err => console.log(err))
    },
    goTaskDetail(id) {
      this.$router.push({ path: `/informed/detail/${id}` })
    },
    // async getInformedTasks(page) {
    //   let category = this.$route.params.category

    //   if (category === 'general') {
    //     category = null
    //   }

    //   try {
    //     this.data = []
    //     this.status = 'loading'

    //     const { data, headers } = await this.listTasks({
    //       ...this.$route.query,
    //       status: ['informed'],
    //       category,
    //       page: page || this.$route.query.page || 1,
    //       limit: this.limit
    //     })

    //     if (!data.length) {
    //       this.status = 'not-found'
    //       return
    //     }

    //     this.total = parseInt(headers.total)
    //     this.data = data

    //     this.status = 'success'
    //   } catch (err) {
    //     console.log(err)
    //     this.status = 'error'
    //   }
    // }
    async getInformedTasks(page) {
      let category = this.$route.params.category

      const query = {
        page: page || this.$route.query.page || 1,
        limit: this.limit,
        category: category.toUpperCase(),
        ...this.$route.query
      }

      if (category === 'general') {
        delete query.category
      }

      try {
        // this.status = 'loading'
        const { data } = await getTasks({ ...query, status: 'CLOSED' })

        if (data.success) {
          this.data = data.data.result[0].data
          this.total = data.data.result[0].count

          if (this.data.length) {
            this.status = 'success'
          } else {
            this.status = 'not-found'
          }
        }
      } catch (err) {
        console.log(err)
        this.status = 'error'
      }
    }
  },
  created() {
    // this.setTaskStats()

    Object.keys(this.filter).forEach(key => {
      if (this.$route.query[key]) {
        this.filter[key] = this.$route.query[key]
      }
    })

    this.getInformedTasks()
  }
}
</script>

<style lang="scss" scoped>
.informed-pagination {
  display: flex;
  justify-content: flex-end;
  padding-right: 50px;
  margin-top: 40px;
}
.filter-container {
  padding: 15px 20px;
}

.informed-filter-btn-group {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
</style>
