<template>
  <svg
    class="default"
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="fill-default"
      d="M25.6133 11.335L23.6563 9.387C23.1393 8.871 22.2943 8.871 21.7773 9.387L10.7723 20.394C10.7333 20.433 10.7093 20.477 10.6893 20.526L9.01729 25.543C8.97729 25.666 9.00729 25.803 9.10129 25.897C9.16429 25.961 9.25329 26 9.34629 26C9.38129 26 9.42029 25.995 9.45529 25.98L14.4703 24.308C14.5203 24.293 14.5693 24.264 14.6033 24.225L25.6133 13.213C26.1293 12.697 26.1293 11.851 25.6133 11.335ZM9.88729 25.115L11.1663 21.279L13.7233 23.836L9.88729 25.115ZM14.3573 23.497L11.5003 20.639L20.0023 12.136L22.8593 14.994L14.3573 23.497V23.497ZM25.1213 12.73L23.3463 14.506L20.4893 11.649L22.2693 9.869C22.5193 9.617 22.9233 9.617 23.1743 9.869L25.1263 11.821C25.3723 12.076 25.3723 12.48 25.1213 12.731V12.73Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'SvgIconObservationNote',
  props: {
    className: {
      type: String,
      default: 'fill-default'
    }
  }
}
</script>
<style lang="scss" scoped>
.default {
  cursor: pointer;
  background-color: #fff;
  border-radius: 50px;
  box-shadow: 0 2px 3px #00000040;
  transition: var(--transition-hover-background);

  &.active {
    background-color: #000940;
    .fill-default {
      fill: #fff;
    }
  }

  &:hover {
    background-color: #000940;
    .fill-default {
      fill: #fff;
    }
  }
  .fill-default {
    fill: #0a0e24;
  }
}
</style>
