export default {
  methods: {
    /**
     * Custom form elements value controller
     * @param {Object} el
     */
    controllerValue(el) {
      if (
        (el.type == 'text' ||
          el.type == 'number' ||
          el.type == 'textarea' ||
          el.type == 'selectbox') &&
        !el.value
      ) {
        alert(`"${el.label}" boş bırakılamaz.`)
        return false
      }

      if (el.type == 'password' && !el.value) {
        alert(`"${el.label}" boş bırakılamaz.`)
        return false
      }

      if (el.id == 'phone_number' && el.value.length < 14) {
        alert('Lütfen geçerli bir telefon numarası giriniz.')
        return false
      }

      if (el.type == 'email' && !this.validEmail(el.value)) {
        alert('Lütfen geçerli bir e-posta adresi giriniz.')
        return false
      }
      return true
    },

    /**
     * Regex for e-mail
     * @param {String} email
     */
    validEmail(email) {
      // eslint-disable-next-line
      var re = /^\w+([\.+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
      return re.test(email)
    },

    /**
     * Regex for password
     * @param {String} pwd
     */
    validPassword(pwd) {
      // eslint-disable-next-line
      var re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/
      return re.test(pwd)
    },

    /**
     * Regex for hh:mm
     * @param {String} time
     */
    validTime(time) {
      // eslint-disable-next-line
      var re = /^(2[0-3]|[0-1]?[\d]):[0-5][\d]$/
      return re.test(time)
    },

    /**
     * parse number from string
     * @param {String} number
     */
    getNumberFromString(number) {
      let str = ''
      number.match(/\d+/g).map(i => (str += i))
      return str
    }
  }
}
