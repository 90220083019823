import store from '@/store'

export default function(to, from, next) {
  const auth = store.state.auth
  const isLoggedIn = auth.isLoggedIn

  if (typeof to.name === 'undefined') {
    if (!isLoggedIn) {
      next({ name: 'login' })
    } else {
      next({ name: 'observation' })
    }
  }

  if (isLoggedIn) {
    if (
      ['login-forgot', 'login-reset', 'user-registration'].includes(to.name)
    ) {
      next({ name: 'observation' })
    }
    next()
  } else {
    if (to.meta.requiresAuth) {
      next({
        name: 'login'
      })
    }
    next()
  }
}
