<template>
  <modal
    name="size-modal"
    classes="demo-modal-class"
    :min-width="200"
    :min-height="200"
    :scrollable="true"
    :reset="true"
    width="40%"
    height="auto"
    @before-open="beforeOpen"
  >
    <div class="size-modal-content">
      <div>
        Operatör Notu
      </div>
      <div class="input-group margin-top-30">
        <label class="label">{{ $t('words.description') }}</label>
        <textarea
          class="textarea"
          style="max-width:100%"
          v-model="description"
        ></textarea>
      </div>
      <button
        @click="submit()"
        class="button button--dark margin-top-30 margin-bottom-30"
      >
        {{ $t('words.create') }}
      </button>
    </div>
  </modal>
</template>
<script>
import { addNoteToActions, updateTask } from '../services/core'

export default {
  name: 'SizeModalTest',
  data() {
    return {
      description: '',
      payload: null,
      dataId: null
    }
  },
  methods: {
    async submit() {
      if (this.description.length === 0) {
        this.$notify({
          text: 'Lütfen bir mesaj yazınız.'
        })
        return
      }

      const created = await addNoteToActions({
        id: this.$route.params.id,
        note: this.description
      })

      if (created.data.success) {
        await updateTask(this.dataId, this.payload)
        this.$router.push({
          name: 'observation',
          params: { category: 'general' }
        })
        this.$modal.hide('size-modal')
      }
    },
    beforeOpen(event) {
      this.payload = event.params.payload
      this.dataId = event.params.dataId
    }
  }
}
</script>
<style>
.size-modal-content {
  padding: 10px;
  font-style: 13px;
}
.v--modal-overlay[data-modal='size-modal'] {
  background: rgba(0, 0, 0, 0.5);
}
.demo-modal-class {
  border-radius: 5px;
  background: #f7f7f7;
  box-shadow: 5px 5px 30px 0px rgba(46, 61, 73, 0.6);
  z-index: 999;
}
</style>
