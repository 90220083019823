<template>
  <div
    class="select-box"
    @click.stop="isShow = !isShow"
    :class="{ 'is-dark': $route.meta.theme == 'dark' }"
    :id="id"
    :ref="id"
  >
    <img class="arrow" :class="{ up: isShow }" src="@/assets/image/down.svg" />
    <span v-if="!selectedOption">{{
      options[selectedItemIndex].name || options[selectedItemIndex].text
    }}</span>
    <span v-if="selectedOption">{{
      selectedOption.name || selectedOption.text
    }}</span>
    <ul class="__popup" v-if="isShow">
      <li
        @click.stop="selectOption(opt, index)"
        :id="index + '-opt'"
        :class="{ 'is-active': index == currentIndex }"
        v-for="(opt, index) in options"
        :key="'opt-' + index"
      >
        {{ opt.text || opt.name }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'SelectBox',
  props: {
    id: {
      type: String,
      default: 'v-select-box'
    },
    options: {
      tpye: Array,
      default: () => []
    },
    selectedItemIndex: {
      type: Number,
      default: 0
    }
  },
  watch: {
    isShow: function() {
      if (this.isShow) this.addEvent()
      if (!this.isShow) this.removeEvent()
      // if (this.isShow) document.body.style.overflow = 'hidden'
      if (!this.isShow) document.body.style.overflow = 'auto'
    }
  },
  data() {
    return {
      isShow: false,
      selectedOption: null,
      currentIndex: -1,
      defualtOption: [
        {
          text: this.$i18n.t('button.choose'),
          value: null,
          selected: true
        }
      ]
    }
  },
  methods: {
    documentClick(e) {
      try {
        let el = this.$refs[this.id]
        let target = e.target
        if (el !== target && !el.contains(target)) {
          this.isShow = false
          this.currentIndex = -1
        }
      } catch (error) {
        // console.log(error)
      }
    },
    reset() {
      this.currentIndex = -1
      this.selectedOption = null
    },
    captureKey(e) {
      let key = e.key
      if (key == 'ArrowDown') this.step(1)
      if (key == 'ArrowUp') this.step(-1)
      if (key == 'Enter') this.selectOption(this.options[this.currentIndex])
    },
    step(i) {
      let max = this.options.length - 1
      if (this.currentIndex <= 0 && i < 0) {
        this.currentIndex = max
        this.scrollView()
        return
      }
      if (this.currentIndex >= max) {
        this.currentIndex = 0
        this.scrollView()
        return
      }
      if (this.currentIndex <= max) {
        this.currentIndex += i
        this.scrollView()
      }
    },
    scrollView() {
      let optItem = document.getElementById(this.currentIndex + '-opt')
      if (optItem)
        optItem.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'start'
        })
    },
    selectOption(opt, index) {
      this.selectedOption = opt
      this.isShow = false
      this.currentIndex = -1
      this.$emit('change', { ...opt, selectedIndex: index })
    },
    addEvent() {
      document.addEventListener('click', this.documentClick)
      document.addEventListener('keydown', this.captureKey)
      document.addEventListener('touchstart', this.documentClick)
    },
    removeEvent() {
      document.removeEventListener('touchstart', this.documentClick)
      document.removeEventListener('click', this.documentClick)
      document.removeEventListener('keydown', this.captureKey)
      document.body.style.overflow = 'auto'
    }
  },
  beforeDestroy() {
    this.removeEvent()
  }
}
</script>

<style lang="scss" scoped>
.select-box {
  width: 100%;
  height: 30px;
  border: solid 1px var(--input-border);
  border-radius: 3px;
  padding: 10px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  cursor: pointer;
  font-size: 12px;
  font-weight: 300;
  // color: var(--text-color--gray);

  &.is-dark {
    border: 1px solid var(--input-border--light);
    color: var(--text-color--white);
    .input-search {
      color: var(--text-color--white);
    }
    .__popup {
      background-color: var(--background-color--dark);
      border: 1px solid var(--input-border--light);
    }
    .arrow {
      filter: none;
    }
  }

  .arrow {
    position: absolute;
    right: 10px;
    transition: 0.2s ease;
    transform-origin: center;
    filter: brightness(0.5);

    &.up {
      transform: rotate(180deg);
    }
  }

  .__popup {
    position: absolute;
    height: auto;
    overflow-x: hidden;
    max-height: 246px;
    width: 100%;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    left: 0;
    top: 30px;
    animation: popupOpener 0.1s ease both;
    background-color: var(--background-color--main);
    border: 1px solid var(--input-border);
    z-index: 99;
    height: max-content;

    @keyframes popupOpener {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }

    li {
      padding: 10px;
      cursor: pointer;
      text-align: left;
      border-bottom: 1px solid var(--input-border--light-10);
      height: max-content;
      user-select: none;

      &.is-active {
        background: var(--background-color-list-item-select);
      }

      &:hover {
        background: var(--background-color-list-item-select);
      }
    }
  }
}
</style>
