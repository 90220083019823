import Vue from 'vue'
import Vuex from 'vuex'

import createPersistedState from 'vuex-persistedstate'

import global from '@/store/global'
import locale from '@/store/locale'
import auth from '@/store/auth'
import password from '@/store/password'
import observation from '@/store/observation'
import customer from '@/store/customer'
import ticket from '@/store/ticket'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    global,
    locale,
    auth,
    password,
    observation,
    customer,
    ticket
  },
  plugins: [
    createPersistedState({
      paths: ['auth', 'locale']
    })
  ],
  strict: process.env.NODE_ENV !== 'production'
})

export default store
