<template>
  <div class="page-inner --padding-bottom-m">
    <observation-header
      :status="status"
      :isCheck="showPostponed"
      @clicked="handleClickFilter"
      @changePostponed="handleChangePostponed"
    ></observation-header>

    <page-loader v-if="isLoading"></page-loader>

    <div v-if="isNotFound" class="task-list__not-found">
      <svg-icon-signal :size="16" />
      <p>{{ $t('observation.not_found.desc') }}</p>
    </div>

    <transition-group
      name="list"
      tag="ul"
      class="task-list "
      v-if="!isLoading && (filteredTasks || postponedTasks)"
    >
      <li
        class="task-list__item"
        v-for="(item, key) in showPostponed ? postponedTasks : filteredTasks"
        :key="key + '-task'"
      >
        <observation-card :item="item" @click="goTaskDetail"></observation-card>
      </li>
    </transition-group>
  </div>
</template>

<script>
import { assignTask, getTasks } from '../services/core'
import { io } from 'socket.io-client'
import moment from 'moment-timezone'
export default {
  name: 'Tasks',
  data() {
    return {
      status: {
        code: 1,
        success: false,
        error: false
      },
      showPostponed: false,
      socket: null,
      allTasks: [],
      isLoading: false
    }
  },
  computed: {
    filteredTasks() {
      const category = this.$route.params.category
      if (category === 'general') {
        return this.allTasks.filter(task => task.status !== 'POSTPONED')
      } else {
        return this.allTasks.filter(
          task =>
            task.category === category.toUpperCase() &&
            task.status !== 'POSTPONED'
        )
      }
    },

    postponedTasks() {
      const category = this.$route.params.category

      if (category === 'general') {
        return this.allTasks.filter(task => task.status === 'POSTPONED')
      } else {
        return this.allTasks.filter(
          task =>
            task.status === 'POSTPONED' &&
            task.category === category.toUpperCase()
        )
      }
    },

    isNotFound() {
      if (!this.isLoading) {
        if (this.showPostponed) {
          if (!this.postponedTasks.length) {
            return true
          }
        } else {
          if (!this.filteredTasks.length) {
            return true
          }
        }
      }

      return false
    }
  },
  methods: {
    async goTaskDetail(id) {
      try {
        const { data } = await assignTask(id)
        if (data.success) {
          this.$router.push({ path: `/observation/detail/${id}` })
        }
      } catch (err) {
        console.log(err)
      }
    },
    handleChangePostponed() {
      this.showPostponed = !this.showPostponed
    },
    handleClickFilter({ route }) {
      this.$router.push({
        name: 'observation',
        params: { category: route }
      })
    },
    connectSocket() {
      // Socket
      this.socket = io(`${process.env.VUE_APP_CORE_URL}:8010`, {
        transports: ['websocket', 'polling', 'flashsocket'],
        auth: { token: `Bearer ${this.$store.state.auth.user.token}` }
      })
      this.socket.emit('msgToTaskServerDMC', this.$store.state.auth.user.dmcId)

      this.socket.on('msgToTaskClientDMC', message => {
        console.log(message)
        this.receivedTask(message.data)
      })

      // On Connect
      this.socket.on('connect', () => {
        this.status = {
          code: 0,
          success: true,
          error: false
        }
      })

      // On Connect Error
      this.socket.on('connect_error', () => {
        this.status = {
          code: 2,
          success: false,
          error: true
        }
      })
    },
    receivedTask(task) {
      const tasks = [...this.allTasks]
      const taskIndex = tasks.findIndex(t => t.id === task.id)

      // Eger bu id'de bir task varsa
      if (taskIndex >= 0) {
        // Eger status open veya postponed ise var olani guncelle
        if (['OPEN', 'POSTPONED'].includes(task.status)) {
          tasks[taskIndex] = {
            ...task,
            id: task.id,
            createdAt: moment
              .utc(task.createdAt)
              .tz('Europe/Istanbul')
              .toISOString()
          }
        }

        // Eger status closed veta assigned ise taski sil
        else if (['CLOSED', 'ASSIGNED'].includes(task.status)) {
          tasks.splice(taskIndex, 1)
        }
      }
      // Eger bu id'de bir task yoksa
      else {
        // Eger status open veya postponed ise tasklere ekle
        if (['OPEN', 'POSTPONED'].includes(task.status)) {
          tasks.push({
            ...task,
            id: task.id,
            createdAt: moment
              .utc(task.createdAt)
              .tz('Europe/Istanbul')
              .toISOString()
          })
        }
      }

      this.allTasks = tasks
    },
    async fetchTasks() {
      try {
        const assignedToMe = await getTasks({
          page: 1,
          status: 'ASSIGNED',
          'assignee.id': this.$store.state.auth.user.id
        })

        const data = assignedToMe.data.data.result[0].data

        if (data.length) {
          this.$router.push(`/observation/detail/${data[0].id}`)
        } else {
          this.isLoading = true
          const opened = await getTasks({ page: 1, status: 'OPEN' })
          const postponed = await getTasks({ page: 1, status: 'POSTPONED' })

          this.allTasks = [
            ...opened.data.data.result[0].data,
            ...postponed.data.data.result[0].data
          ]
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    }
  },
  created() {
    this.isLoading = true
    this.connectSocket()

    this.fetchTasks()
  },
  destroyed() {
    this.socket.disconnect()
  }
}
</script>
