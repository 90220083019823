<template>
  <div class="input-search-grid" :id="id" :ref="id">
    <img class="search" src="@/assets/image/search-icon.png" />
    <input
      class="input-search"
      @input="getTypingEnd"
      :id="id + '-input'"
      type="text"
      v-model.trim="computedValue"
      :placeholder="placeholder"
      :disabled="disabled"
      autocomplete="off"
    />
    <img
      v-if="loading"
      class="loader dark"
      size="16"
      src="@/assets/image/loader.png"
    />
  </div>
</template>

<script>
export default {
  name: 'InputSearch',
  props: {
    id: {
      type: String,
      default: 'v-input-search'
    },
    delay: {
      type: Number,
      default: 0
    },
    placeholder: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    value: {
      type: [String, Number],
      default: ''
    }
  },
  computed: {
    computedValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('updateValue', value)
      }
    }
  },
  data() {
    return {
      // value: null,
      typingTimer: null
    }
  },
  methods: {
    getTypingEnd(e) {
      clearTimeout(this.typingTimer)
      this.typingTimer = setTimeout(() => {
        this.$emit('input', this.value)
      }, this.delay)
    },
    clearTimeout() {
      clearTimeout(this.typingTimer)
    }
  }
}
</script>

<style lang="scss" scoped>
.input-search-grid {
  width: 100%;
  max-width: 270px;
  height: 30px;
  border: solid 1px var(--input-border);
  background-color: transparent;
  border-radius: 3px;
  padding: 10px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  cursor: pointer;
  font-size: 14px;

  .input-search {
    background-color: transparent;
    outline: none;
    border: 0px;
    padding-left: 10px;
    width: 100%;
    height: 30px;

    &::placeholder {
      opacity: 0.9;
    }
  }
}
</style>
