<template>
  <label
    class="radio-button"
    :class="[
      { 'is-active': value === label },
      { 'is-disabled': isDisabled },
      { 'is-focus': focus }
    ]"
    role="radio"
    :aria-checked="value === label"
    :aria-disabled="isDisabled"
    :tabindex="tabIndex"
    @keydown.space.stop.prevent="value = isDisabled ? value : label"
  >
    <input
      class="radio-button__input"
      :value="label"
      type="radio"
      v-model="value"
      :name="name"
      :disabled="isDisabled"
      tabindex="-1"
      @change="handleChange"
      @focus="focus"
      @blur="blur"
    />

    <span class="radio-button__checkmark" @keydown.stop> </span>
    <template v-if="!$slots.default">
      {{ label }}
    </template>
    <slot></slot>
  </label>
</template>

<script>
export default {
  name: 'RadioButton',
  props: {
    disabled: Boolean,
    name: String,
    label: {}
  },
  computed: {
    value: {
      get() {
        return this._radioGroup.$attrs.value
      },
      set(value) {
        this._radioGroup.$emit('input', value)
      }
    },
    _radioGroup() {
      let parent = this.$parent
      while (parent) {
        if (parent.$options.name !== 'RadioGroup') {
          parent = parent.$parent
        } else {
          return parent
        }
      }
      return false
    },
    isDisabled() {
      return this.disabled || this._radioGroup.disabled
    },
    tabIndex() {
      return this.isDisabled || (this._radioGroup && this.value !== this.label)
        ? -1
        : 0
    }
  },
  data() {
    return {
      isFocus: false
    }
  },
  methods: {
    handleChange() {
      this.$emit('input', this.value)
    },
    focus(e) {
      this.isFocus = true
      this._radioGroup.$emit('focus', e)
    },
    blur(e) {
      this.isFocus = false
      this._radioGroup.$emit('blur', e)
    }
  }
}
</script>
