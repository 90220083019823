<template>
  <div class="input-grid" :class="{ disabled: disabled }">
    <div @click.stop="handleClickOpenDropdown()" class="input">
      <p v-if="selected.length == 0">{{ $t('words.choose') }}</p>
      <li
        v-for="(tag, tindex) in selected"
        :key="tindex + '-tag-item'"
        class="tag-item"
      >
        <button @click.stop="toggleAddItem(tag)" class="btn-delete">+</button>
        {{ tag.text }}
      </li>
    </div>
    <div v-if="isDropdown" v-clickoutside="handleClickOutside" class="dropdown">
      <li
        @click="toggleAddItem(item)"
        :class="{ 'is-active': false }"
        v-for="(item, index) in options"
        :key="index + '-drop-item'"
      >
        {{ item.text }}
      </li>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectboxMultiple',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default: () => []
    },
    initialState: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      isDropdown: false,
      selected: [...this.initialState]
    }
  },
  methods: {
    handleClickOutside() {
      this.isDropdown = false
    },
    handleClickOpenDropdown() {
      if (!this.disabled) {
        this.isDropdown = !this.isDropdown
      }
    },
    toggleAddItem(item) {
      if (this.disabled) return
      // item is added, remove item
      for (let i = 0; i < this.selected.length; i++) {
        const selectedItem = this.selected[i]
        if (selectedItem.value == item.value) {
          this.selected.splice(i, 1)
          this.$emit('input', this.selected)
          return
        }
      }
      // else add item
      this.selected.push(item)
      this.$emit('input', this.selected)
    }
  }
}
</script>

<style lang="scss" scoped>
.input-grid {
  position: relative;
  * {
    user-select: none !important;
  }

  &.disabled {
    opacity: 0.5;
  }

  .input {
    position: relative;
    width: 100%;
    border: solid 1px var(--input-border);
    border-radius: 3px;
    background: transparent;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 5px;
    min-height: 30px;
    font-size: 12px !important;
    font-weight: 300;
    cursor: pointer;
  }
  .dropdown {
    position: absolute;
    z-index: 99;
    top: 50px;
    left: 0;
    width: 100%;
    display: block;
    height: auto;
    max-height: 300px;
    border: 1px solid #e5e5ea;
    border-radius: 3px;
    background-color: white;
    overflow: auto;

    li {
      list-style-type: none;
      border-bottom: 1px solid #e5e5ea;
      padding: 8px;
      cursor: pointer;
      font-size: 12px !important;
      font-weight: 300;
      &.is-active {
        color: var(--text-color--blue);
      }

      &:hover {
        color: var(--text-color--blue);
      }
    }
  }

  .tag-item {
    list-style-type: none;
    background-color: var(--text-color--blue);
    color: white;
    cursor: pointer;
    border-radius: 3px;
    display: flex;
    padding: 5px;
    align-items: center;
    text-align: center;
    margin: 5px;
    position: relative;

    &:hover {
      .btn-delete {
        display: block;
      }
    }

    .btn-delete {
      cursor: pointer;
      transition: 0.2s ease;
      display: none;
      transform: rotate(45deg);
      width: 14px;
      height: 14px;
      font-size: 14px;
      line-height: 14px;
      font-weight: 500;
      border-radius: 50px;
      position: absolute;
      right: -5px;
      top: -5px;
      color: white;
      background-color: var(--text-color--blue);

      box-shadow: 0 0 0 2px white;
    }
  }
}
</style>
