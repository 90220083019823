export const USER_ROLES = [
  'general_manager',
  'manager',
  'technician'
  // 'operator',
  // 'salesman'
]

// export const HARDWARE_TYPE = [
//   'CAMERA',
//   'PANEL',
//   'SMARTBOX_IO_MODULE',
//   'VEHICLE_TRACKING',
//   'FIRE',
//   'ACCESS_CONTROL'
// ]

export const HARDWARE_TYPE = [
  'PANEL',
  'FIRE',
  'NVR',
  'IP_CAMERA',
  'ACCESS_CONTROL',
  'SMARTBOX_IO_MODULE',
  'SMART_PANEL',
  'SMARTHUB'
  // 'VEHICLE_TRACKING'
]

export const PREMISE_TYPE = ['home', 'work_place', 'depot']

export const HARDWARE_TYPE_PANEL = [
  'FIRE',
  'GAS_LEAK_DETECTOR',
  'FLOOD',
  'SMOKE_DETECTOR',
  'GLASS_REFRACTION',
  'HEAT_DETECTOR',
  'MAGNETIC_CONTACT',
  'MOTION_SENSOR',
  'SHOCK_SENSOR',
  'SIREN_SABOTAGE',
  'PANIC_BUTTON'
]

export const SIGNAL_CATEGORY = ['hardware', 'operational', 'alarm']

export const SIGNAL_TYPE = [
  'health_panic',
  'arm',
  'away_arm',
  'battery_low',
  'burglary_alarm',
  'bypass',
  'communication_trouble',
  'disarm',
  'duress',
  'fire_alarm',
  'flood_alarm',
  'gas_alarm',
  'isolate',
  'panic_alarm',
  'power_loss',
  'sensor_low_battery',
  'stay_arm',
  'system_trouble',
  'tamper',
  'zone_open',
  'heat',
  'test'
]

export const SIGNAL_TYPE_CAT = [
  { key: 'Alarm', value: 'burglary_alarm' },
  { key: 'Alarm', value: 'duress' },
  { key: 'Alarm', value: 'fire_alarm' },
  { key: 'Alarm', value: 'flood_alarm' },
  { key: 'Alarm', value: 'gas_alarm' },
  { key: 'Alarm', value: 'health_panic' },
  { key: 'Alarm', value: 'heat' },
  { key: 'Alarm', value: 'panic_alarm' },
  { key: 'Alarm', value: 'tamper' },
  { key: 'Hardware', value: 'battery_low' },
  { key: 'Hardware', value: 'communication_trouble' },
  { key: 'Hardware', value: 'gprs_communication' },
  { key: 'Hardware', value: 'power_loss' },
  { key: 'Hardware', value: 'sensor_low_battery' },
  { key: 'Hardware', value: 'system_trouble' },
  { key: 'Hardware', value: 'test' },
  { key: 'Operational', value: 'arm' },
  { key: 'Operational', value: 'away_arm' },
  { key: 'Operational', value: 'bypass' },
  { key: 'Operational', value: 'disarm' },
  { key: 'Operational', value: 'stay_arm' },
  { key: 'Operational', value: 'zone_open' }
]

export const TICKET_CATEGORY = [
  'signal_interruption',
  'new_device_request',
  'maintenance',
  'fault'
]

export const TICKET_TYPE = ['technic-service', 'discovery', 'new-device-setup']

export const DEVICE_VENDOR_MODEL = {
  CAMERA: {
    DAHUA: ['DEFAULT'],
    VGUARD: ['VG-32C2P']
  },
  SMARTBOX_IO_MODULE: {
    BIGES: ['V1'],
    Biges: ['smartbox_v1']
  },
  VEHICLE_TRACKING: {
    INFOTECH: ['DEFAULT']
  },
  ACCESS_CONTROL: {
    ZKTECO: ['DEFAULT']
  },
  PANEL: {
    Pyronix: ['Matrix 6'],
    DSC: [
      'DEFAULT',
      '1414',
      'WAP 304',
      'NETWOK',
      '864',
      '585',
      '1616',
      '1864',
      '1832',
      '832',
      '608'
    ],
    PARADOX: [
      'DEFAULT',
      '748',
      'EVO48',
      '1738',
      '1759',
      'ESPRIT 55',
      'SP5500',
      'SP-4000',
      '728',
      'SP6000',
      'SP7000',
      'MAGELLAN',
      'DIGIPLEX',
      'MG 5000',
      '1728',
      '738'
    ],
    CROW: [
      'DEFAULT',
      '4 ZONE PANEL',
      '64 ZONE',
      '8 ZONE PANEL',
      '16 ZONE PANEL'
    ],
    GUARD: ['DEFAULT', 'CA52', 'CA80', 'CA60+'],
    SIEMENS: ['DEFAULT', 'S-BOX'],
    'C&K SYSTEMS': ['DEFAULT', 'SYS-2316', 'SYS-236', 'SYS-238'],
    TEKNIM: ['DEFAULT', 'VAP416', 'VAS304', 'VAP304', 'VAP404'],
    VITEC: ['DEFAULT', 'VAP 304', 'VAP 416', 'AV 701', 'VAP 408'],
    PYRONIX: [
      'DEFAULT',
      'ATLAS 8',
      'ENFORCER',
      'MATRIX 6',
      'MATRIX 832',
      'MATRIX 848',
      'MATRIX 832+',
      'MATRIX 816',
      'MATRIX 424'
    ],
    ROKONET: ['DEFAULT', 'ORBIT 14'],
    JABLOTRON: ['DEFAULT', 'JA-63'],
    'GENERAL ELECTRIC': ['DEFAULT', 'NETWORK'],
    CADEX: ['DEFAULT', 'NX8', 'NX4'],
    FBI: [
      'DEFAULT',
      'OMNI 400',
      'VISTA 48',
      'OMNI 624',
      'OMNI 848',
      'OMNI 600'
    ],
    'CROW EBS': ['DEFAULT', '16 ZOON EX'],
    REKSOS: ['DEFAULT', '2444'],
    'VESTEL ANGEL': ['DEFAULT', 'VESTEL', '1832 GPRS'],
    'ELEKTRONIX LINE': ['DEFAULT', 'SUMMER 3208 GOLD'],
    KEST: ['DEFAULT', 'KS04W'],
    OMNI: ['DEFAULT', 'VISTA-SE'],
    TEXECOM: ['DEFAULT', 'PREMIER'],
    ORIX: ['DEFAULT'],
    'BOSCH 1.31': ['AMAX4000-1.31'],
    BOSCH: [
      'DEFAULT',
      'AMAX 2000',
      'AMAX 3000',
      'CC488P',
      'SOSYAL ALARM',
      'AMAX 4000',
      'AMAX 2100'
    ],
    SAVESA: ['DEFAULT', 'CA-60', 'CA-88'],
    WISETECH: ['DEFAULT', 'WS-244', 'KS'],
    HAMMERX7: ['DEFAULT', 'SY6218'],
    AMC: ['DEFAULT', 'C16'],
    NAPCO: ['DEFAULT', 'RPX-6'],
    SOS: ['DEFAULT'],
    KAREL: ['DEFAULT', 'APE137-8'],
    OPAX: ['DEFAULT', 'RP208', 'RP2444', 'RP 2546', 'RP2545'],
    BENTEL: ['DEFAULT', 'PENTAXL 3104'],
    INFINTY: ['DEFAULT'],
    PIMA: ['DEFAULT'],
    NEUTRON: ['DEFAULT'],
    NETELSAN: ['DEFAULT'],
    CERBER: ['DEFAULT', 'C 52', 'C 82'],
    AJAX: ['DEFAULT', 'HUB KABLOSUS'],
    DESI: ['DEFAULT']
  }
}
