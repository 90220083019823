<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="17"
    viewBox="0 0 18 17"
  >
    <g fill="none" fill-rule="evenodd">
      <g fill="#FFF" fill-rule="nonzero">
        <path
          d="M17.5 10c.284 0 .505.258.5.505V16c0 .261-.238.499-.5.499H.5c-.262 0-.5-.238-.5-.5v-5.495C-.004 10.24.236 10 .5 10s.504.241.5.505v4.997h16v-4.997c-.006-.283.218-.505.5-.505z"
          transform="translate(-1 -2) translate(1 2)"
        />
        <path
          d="M9 13c-.116 0-.27-.07-.344-.141l-4.25-4c-.193-.187-.215-.49-.02-.707.176-.195.517-.2.708-.02L8.5 11.343V.5c0-.276.224-.5.5-.5s.5.224.5.5v10.843l3.406-3.21c.19-.18.524-.168.707.019.194.197.177.523-.02.707l-4.25 4c-.115.117-.226.139-.343.14z"
          transform="translate(-1 -2) translate(1 2)"
        />
      </g>
      <path d="M0 0H20V20H0z" transform="translate(-1 -2)" />
    </g>
  </svg>
</template>
<script>
export default {
  name: 'SvgIconExport',
  props: {
    fill: {
      type: String,
      default: '#FFF'
    },
    stroke: {
      type: String,
      default: '#FFF'
    },
    strokeWidth: {
      type: String,
      default: '.1'
    }
  }
}
</script>
