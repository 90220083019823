<template>
  <svg
    class="default"
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.923 23.419L20.093 18.05L21.086 16.942L24.39 17.052C26.053 15.372 26.664 13.726 26.84 12.685C27.032 11.545 26.744 10.529 26.735 10.499L22.99 14.279L22.13 13.41L25.915 9.606C25.259 9.563 21.913 9.374 19.924 11.183L19.508 10.763C18.85 11.298 18.427 12.5 19.074 14.658L17.357 16.228L13.529 12.398L13.188 11.338L10.098 9L9 10.098L11.357 13.166L12.399 13.526L16.179 17.306C13.444 19.813 10.236 22.767 9.585 23.424C9.145 23.874 8.489 24.878 9.784 26.184C10.735 27.144 11.708 27.2 12.524 26.384C13.136 25.766 15.777 22.847 18.138 20.224L23.388 25.953L24.978 27L26.97 25.01L25.923 23.419ZM25.123 9.916L21.889 13.166L20.165 11.426C21.671 10.066 24.164 9.919 25.123 9.916ZM12.585 13.228L11.566 12.876L9.456 10.128L10.13 9.454L12.895 11.546L13.228 12.583L17.104 16.459L16.432 17.075L12.585 13.228V13.228ZM12.28 26.143C11.734 26.689 10.973 26.897 10.027 25.943C9.469 25.38 8.984 24.525 9.83 23.664C10.75 22.737 16.9 17.108 19.466 14.764C19.238 14.038 18.778 12.198 19.515 11.258L22.99 14.766L26.543 11.179C26.673 12.168 26.576 14.326 24.251 16.704L20.938 16.594C18.654 19.141 13.187 25.228 12.28 26.144V26.143ZM18.368 19.969L19.863 18.305L25.543 23.535L23.505 25.574L18.368 19.969ZM24.934 26.56L23.77 25.794L25.764 23.8L26.529 24.965L24.934 26.56Z"
      class="fill-default"
      stroke-width="0.2"
    />
  </svg>
</template>
<script>
export default {
  name: 'SvgIconObservationService',
  props: {
    className: {
      type: String,
      default: 'fill-default'
    }
  }
}
</script>
<style lang="scss" scoped>
.default {
  cursor: pointer;
  border-radius: 50px;
  box-shadow: 0 2px 3px #00000040;
  background-color: #fff;
  transition: var(--transition-hover-background);

  &.active {
    background-color: #000940;
    .fill-default {
      stroke: #fff;
      fill: #fff;
    }
  }

  &.disabled {
    opacity: 0.5;
  }

  &:not(.disabled):hover {
    background-color: #000940;
    .fill-default {
      fill: #fff;
      stroke: #fff;
    }
  }

  .fill-default {
    fill: #0a0e24;
    stroke: #0a0e24;
  }
}
</style>
