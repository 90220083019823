<template>
  <div :class="{ 'call-list-wrapper': true, disabled: isInformed }">
    <div class="call-list-title">{{ $t('words.call-redirect') }}</div>
    <ul class="call-button-list">
      <li
        class="call-button-list__item"
        v-for="(item, key) in emergencyNumbers"
        :key="key + 0"
        @click="fillSoftphoneInput(item.phone)"
      >
        <div class="call-button" v-tooltip="{ delay: 500, content: item.name }">
          <img src="@/assets/image/call.svg" class="call-button__icon" />
          <span class="call-button__text">{{ item.name }}</span>
        </div>
      </li>
    </ul>
    <template>
      <div class="container">
        <iframe
          ref="alotech"
          :src="'/view/index.html?sessionKey=' + queryString"
          width="100%"
          height="140"
          style="overflow: none"
          frameborder="0"
          v-if="queryString.length > 10"
        >
        </iframe>
      </div>
    </template>
    <div class="call-list-title">{{ $t('words.call-users') }}</div>
    <ul class="call-list">
      <li
        class="call-list__item"
        v-for="(item, key) in contacts"
        :key="key + 0"
        @click="fillSoftphoneInput(item.phoneNumber)"
      >
        <img src="@/assets/image/call-dark.svg" class="call-list__item__icon" />
        <div class="call-list__item__text">
          {{ item.name }}
          <span>{{ item.phoneNumber }}</span>
          <div v-if="item.passCode">
            <div style="display:flex;align-items:center">
              <span
                style="display: inline-flex"
                @click="e => changePassCodeVisibility(e, key)"
              >
                <SvgIconEye v-if="item.visible" />
                <SvgIconEye v-else :visible="true" />
              </span>
              <span style="margin-left:10px" v-if="!item.visible">****</span>
              <span style="margin-left:10px" v-else>{{ item.passCode }}</span>
            </div>
            <span>
              <span
                v-for="char in item.info"
                :key="char.index"
                style="margin-right: 20px"
              >
                <b>{{ char.index }}:</b> <span>{{ char.char }}</span>
              </span>
            </span>
          </div>
        </div>
        <div
          v-if="key <= 4"
          v-tooltip="$t(`words.shortcut`, { key: 'F' + (key + 1) })"
          class="call-list__item__text"
        >
          {{ key + 1 }}
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import {
  startCall,
  getAlotechSessionKey,
  addNoteToActions
} from '../../../services/core'
import SvgIconEye from '../../icons/svg-icon-eye.vue'

export default {
  name: 'ObservationDetailCall',
  components: { SvgIconEye },
  props: {
    data: {
      type: Array,
      default: () => []
    },
    callList: {
      type: Object,
      default: () => {}
    },
    isInformed: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('auth', ['token', 'user'])
  },
  data() {
    return {
      queryString: '',
      emergencyNumbers: this.$store.state.auth.user.dmc.config
        .emergency_phones,
      contacts: this.data
        .map(c => ({
          ...c,
          visible: false,
          info: this.generateRandom(2, c.passCode)
        }))
        .sort((a, b) => a.order - b.order)
    }
  },
  methods: {
    ...mapActions({
      setCallNumber: 'global/setCallNumber'
    }),
    call(phone, name) {
      // startCall(phone, this.$route.params.id)
      this.setCallNumber(phone)
      this.$emit('calledPerson', { name, phone })
    },
    fillSoftphoneInput(phone) {
      this.$refs.alotech.contentWindow.postMessage(
        { type: 'call', value: phone },
        '*'
      )
    },
    startCallViaSoftphone() {
      this.$refs.alotech.contentWindow.postMessage({ type: 'startCall' }, '*')
    },
    async initSoftPhone() {
      const { data } = await getAlotechSessionKey(this.user.email)
      this.queryString = data.session
    },
    handleKeyUp(event) {
      let { keyCode } = event

      let keys = {
        49: '0',
        50: '1',
        51: '2',
        52: '3',
        53: '4',
        54: '5',
        55: '6',
        56: '7',
        57: '8'
      }

      if (!keys[keyCode]) return

      let callUser = this.data[keys[keyCode]]

      if (!callUser) return

      this.fillSoftphoneInput(callUser.phoneNumber)
      this.startCallViaSoftphone()
    },
    generateRandom(quantity, passCode) {
      if (passCode) {
        const arr = []

        while (arr.length < quantity) {
          let candidateInt = Math.floor(Math.random() * passCode.length - 1) + 1
          let isValid =
            arr.map(item => item.index - 1).indexOf(candidateInt) === -1

          if (isValid) {
            arr.push({
              index: candidateInt + 1,
              char: passCode[candidateInt]
            })
          } else {
            console.log('duplicate')
          }
        }

        return arr.sort((a, b) => a.index - b.index)
      } else return []
    },
    changePassCodeVisibility(e, index) {
      e.stopPropagation()
      this.contacts[index].visible = !this.contacts[index].visible
    }
  },
  mounted() {
    if (!this.isInformed) {
      document.addEventListener('keyup', this.handleKeyUp)
    }
  },
  created() {
    this.initSoftPhone()
    const addNote = async message => {
      console.log(this.$route.params.id)
      try {
        await addNoteToActions({
          id: this.$route.params.id,
          note: message
        })
        noteCreated()
      } catch (e) {
        console.log(e)
      }
    }
    const notify = (type, message) => {
      this.$notify({
        type: type,
        text: message
      })
    }

    const noteCreated = () => {
      this.$emit('onNoteCreated')
    }

    const sendButtonStatus = type => {
      this.$refs.alotech.contentWindow.postMessage(
        { type: type, value: '' },
        '*'
      )
    }

    const toggleIsCalling = calling => {
      this.$emit('setIsCalling', calling)
    }

    window.onmessage = function(e) {
      if (e.data.type === 'unchangableStatus') {
        notify('warn', 'Operatör durumu güncellenemedi')
      }

      if (e.data.type === 'dial') {
        sendButtonStatus('disableCallButton')
        noteCreated()
        addNote('Arama yapılıyor: ' + e.data.value)
        toggleIsCalling(true)
        notify('warn', 'Arama yapılıyor...')
      }

      if (e.data.type === 'accept') {
        addNote('Görüşme başladı: ' + e.data.value)
        notify('warn', 'Görüşme başladı')
      }

      if (e.data.type === 'hangup') {
        let cause
        sendButtonStatus('enableCallButton')
        if (e.data.value.cause === 'Terminated') {
          cause = 'Başarılı'
        }

        if (e.data.value.cause === 'Canceled') {
          cause = 'Arama iptal edildi'
        }

        if (e.data.value.cause === 'Busy') {
          cause = 'Meşgul'
        }
        if (e.data.value.cause === 'Rejected') {
          cause = 'Reddedildi'
        }

        if (e.data.value.cause === 'Unavailable') {
          cause = 'Ulaşılamadı'
        }

        addNote('Görüşme sonlandı: ' + e.data.value.number + ' : ' + cause)
        toggleIsCalling(false)
        notify('warn', 'Görüşme sonlandı: ' + cause)
      }
    }
  },
  beforeDestroy() {
    document.removeEventListener('keyup', this.handleKeyUp)
  }
}
</script>
