import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from '@/store'
import http from '@/plugins/api.js'
import i18n from '@/plugins/i18n'
import clickoutside from '@/directives/clickoutside'
import date from '@/mixins/date.js'
import validations from '@/mixins/validations.js'
import translator from '@/mixins/translator.js'
import VersionController from '@/plugins/version-controller'
// import LogRocket from 'logrocket'
import 'hybrone-theme'
import { Col, Row } from 'hybrone-ui'
import VModal from 'vue-js-modal'
import VTooltip from 'v-tooltip'
import Notifications from 'vue-notification'
import VueProgressBar from 'vue-progressbar'
import * as VueGoogleMaps from 'vue2-google-maps'
import flatpickr from 'flatpickr'
import { Turkish } from 'flatpickr/dist/l10n/tr.js'
import UUID from 'vue-uuid'

flatpickr.localize(Turkish)

VersionController({ store })

Vue.directive('clickoutside', clickoutside)

Vue.component(Col.name, Col)
Vue.component(Row.name, Row)

Vue.mixin(validations)
Vue.mixin(translator)
Vue.mixin(date)

Vue.use(VModal)
Vue.use(UUID)
Vue.use(Notifications)
Vue.use(VueProgressBar, {
  color: '#11a3d2',
  failedColor: '#ff0033',
  thickness: '2px',
  transition: {
    speed: '0.2s',
    opacity: '0.6s',
    termination: 300
  },
  autoRevert: true,
  location: 'top',
  inverse: false
})
Vue.use(VTooltip)
Vue.use(i18n)
Vue.use(http)

// LogRocket.init('ykn52o/test-dmc')

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_TOKEN,
    libraries: 'places'
  },
  installComponents: true
})

Vue.config.productionTip = false

new Vue({
  router,
  http,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
