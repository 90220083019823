import Vue from 'vue'
import VueI18n from 'vue-i18n'
import store from '@/store'

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale:
    localStorage.getItem('lang') ||
    // store.state.locale.lang ||
    // navigator.language.substring(0, 2) ||
    'tr',
  fallbackLocale: 'tr',
  messages: loadLocaleMessages(),
  silentTranslationWarn: true,
  missing(locale, key) {
    return key.split('.').at(-1)
  }
})

Object.defineProperty(Vue.prototype, '$locale', {
  get: () => i18n.locale,
  set: lang => {
    if (lang && Object.keys(i18n.messages).includes(lang)) {
      i18n.locale = lang
      store.dispatch('locale/setLang', lang)
      localStorage.setItem('lang', lang)
      document.querySelector('html').setAttribute('lang', lang)
    }
  }
})

function loadLocaleMessages() {
  const locales = require.context(
    '@/locales',
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  )
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

export default i18n
