<template>
  <svg
    :class="rotate"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g fill="none" fill-rule="evenodd">
      <path
        fill="fill"
        d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0z"
      />
      <path
        :class="className"
        fill-rule="nonzero"
        d="M6.1 9.102c-.063.06-.1.141-.1.227 0 .085.036.167.1.226l5.656 5.35c.063.06.15.095.24.095s.177-.034.24-.095l5.656-5.35c.091-.08.128-.2.097-.314-.03-.113-.124-.202-.244-.231-.12-.029-.248.006-.332.092l-5.417 5.123L6.58 9.102c-.063-.06-.15-.095-.24-.095s-.176.034-.24.095h0z"
      />
      <path d="M0 0H24V24H0z" />
    </g>
  </svg>
</template>
<script>
export default {
  name: 'SvgIconArrowFill',
  props: {
    className: {
      type: String,
      default: 'fill-default'
    },
    rotate: {
      type: String,
      default: 'down'
    },
    fill: {
      type: String,
      default: '#F7F8F9'
    }
  }
}
</script>
<style lang="scss" scoped>
svg {
  transition: var(--transition-hover-background);
}
.down {
  transform: none;
}
.up {
  transform: rotate(180deg);
}
.fill-default {
  fill: #7a818f;
  stroke: #7a818f;
}
.fill-red {
  fill: #dc2e2e;
  stroke: #dc2e2e;
}
.fill-orange {
  fill: #f3a53c;
  stroke: #f3a53c;
}
.fill-green {
  fill: #7fb91b;
  stroke: #7fb91b;
}
.fill-blue {
  fill: #45bcd8;
  stroke: #45bcd8;
}
</style>
