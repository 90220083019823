<template>
  <div :class="['input', size ? 'input-' + size : '']">
    <img class="prefixIcon" v-if="prefixIcon" :src="prefixIcon" />
    <input
      :tabindex="tabindex"
      :type="type"
      :disabled="disabled"
      :id="id"
      :aria-label="label"
      :placeholder="placeholder"
      @input="_bounceTyping"
      @change="handleEvent"
      @focus="handleEvent"
      @blur="handleEvent"
      @keyup="handleEvent"
      @keydown="handleEvent"
      ref="input"
    />
  </div>
</template>

<script>
export default {
  name: 'Input',
  props: {
    value: {
      type: [String, Number],
      default: null
    },
    size: {
      type: String,
      default: 'mini',
      validator: function(value) {
        return ['mini', 'smaill', 'medium', 'large'].indexOf(value) !== -1
      }
    },
    id: String,
    type: {
      type: String,
      default: 'text'
    },
    label: String,
    disabled: Boolean,
    tabindex: String,
    prefixIcon: String,
    placeholder: String
  },
  data() {
    return {
      timeout: null,
      delayTyping: 500
    }
  },
  methods: {
    handleEvent(event) {
      this.$emit(event.type, event)
    },
    _bounceTyping(event) {
      clearTimeout(this.timeout)
      if (this.type == 'search') {
        this.timeout = setTimeout(() => {
          this.$emit(event.type, event.target.value)
        }, this.delayTyping)
      } else {
        this.$emit(event.type, event.target.value)
      }
    }
  }
}
</script>
