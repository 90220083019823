import axios from 'axios'
import Vue from 'vue'
import store from '@/store'
import i18n from '@/plugins/i18n'

const core = createInstance(`${process.env.VUE_APP_CORE_URL}/v1`)

function createInstance(baseURL) {
  return axios.create({
    baseURL,
    headers: {
      'Content-Type': 'application/json',
      'User-Type': 'DMC',
      Source: 'Web'
    },
    timeout: 45000
  })
}

core.interceptors.request.use(config => {
  Vue.prototype.$Progress.start()
  if (!config.url.includes('token')) {
    let token = store.state.auth.user.token
    config.headers['Authorization'] = `Bearer ${token}`
  }
  return config
})

core.interceptors.response.use(
  response => {
    Vue.prototype.$Progress.finish()
    return response
  },
  function(error) {
    Vue.prototype.$Progress.finish()
    if (!error.config.url.includes('token')) {
      Vue.prototype.$notify({
        title: error?.response?.data?.type || null,
        text:
          error?.response?.data?.message ||
          error?.response?.data?.error ||
          i18n.t('errorMessages.an-error-occurred')
      })
    }

    if (error?.response?.status === 401) {
      store.dispatch('auth/logout')
    }

    return Promise.reject(error.response)
  }
)

export default {
  install() {
    Vue.core = core
  }
}
