import Vue from 'vue'

export default {
  namespaced: true,
  actions: {
    sendPasswordResetMail(_, { email }) {
      return Vue.prototype.$http.post('/forgot_password', { email })
    },
    sendNewPassword(_, data) {
      let { reset_password_token, password } = data
      return Vue.prototype.$http.put('/reset_password', {
        reset_password_token,
        password
      })
    }
  }
}
