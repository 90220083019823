<template>
  <div class="ticket-modal-wrapper">
    <!-- start: create note elements -->
    <div class="past-signals-title">
      {{ $tc('operation.create-customer-note', 2) }}
    </div>

    <!-- category -->
    <div class="input-group margin-top-30">
      <label class="label">{{ $t('form.category') }}</label>
      <SelectBox
        ref="category"
        :options="categoryList"
        @change="handleChangeCategory"
      ></SelectBox>
    </div>

    <!-- description -->
    <div class="input-group margin-top-30">
      <label class="label">{{ $t('words.description') }}</label>
      <textarea
        class="textarea"
        style="max-width:100%"
        v-model.trim="form.content"
      ></textarea>
    </div>

    <!-- checkbox -->
    <label class="flex row checkbox" for="schedule">
      <input type="checkbox" id="schedule" v-model="isScheduleNote" />
      <div class="checkmark"></div>
      {{ $t('form.with-time') }}
    </label>

    <!-- date picker -->
    <div v-if="isScheduleNote" class="input-group margin-top-30">
      <label class="label">{{ $t('form.select-date') }}</label>
      <flat-pickr
        v-model="form.expireAt"
        :config="{ ...datePickrConfigWithTime, minDate: new Date(Date.now()) }"
        class="input"
        name="date"
        :placeholder="$t('form.date')"
      >
      </flat-pickr>
    </div>

    <div
      class="input-group margin-top-30"
      style="display: flex; justify-content: flex-end;"
    >
      <button class="button button--outline" @click="$emit('onClose', true)">
        {{ $t('words.cancel') }}
      </button>
      <button
        class="button button--dark"
        @click="onSubmit"
        :disabled="isSubmit"
      >
        <img
          v-if="isSubmit"
          class="loader"
          size="16"
          src="@/assets/image/loader.png"
        />

        {{ $t('words.create') }}
      </button>
    </div>
    <!-- end: create note elements -->

    <!-- start: note list -->

    <div
      v-if="notes"
      class="past-signals-title margin-top-30"
      style="display:inline-block"
    >
      {{ $t('operation.notes') }}
    </div>

    <div v-if="notes" class="note-grid margin-top-10">
      <li
        v-for="(item, index) in notes"
        :key="index + '-note-item'"
        class="note-grid__list-item"
      >
        <span class="note-grid__list-item__text">
          <button
            @click="handleRemoveNote(item.id)"
            class="note-grid__list-item__btn-delete"
          >
            {{ $t('button.delete') }}
          </button>

          <p class="note-grid__list-item__badge">
            {{ $t(`operation.note-category.${item.type}`) }}
          </p>

          <span class="note-grid__list-item__date --small   --margin-bottom">
            <div
              v-tooltip="item.user.name"
              v-if="item.user && item.user.name"
              class="--text-ellipsis"
            >
              <p class="note-grid__list-item__text-primary">
                {{ item.user.name }}
              </p>
            </div>

            <div style="opacity:.7">
              {{
                dateConvertToFriendlyString(
                  item.createdAt,
                  $store.state.auth.user.timezone
                )
              }}
            </div>
          </span>

          <p class="note-grid__list-item__description">
            {{ item.content }}
          </p>
        </span>
        <span v-if="item.expireAt" class="note-grid__list-item__date">
          <p>{{ $t('operation.expire_at') }}</p>
          {{
            dateConvertToFriendlyString(
              item.expireAt,
              $store.state.auth.user.timezone
            )
          }}
        </span>
      </li>
    </div>
    <!-- end: note list -->
  </div>
</template>

<script>
import { addCustomerNote, removeCustomerNote } from '../../../services/core'

export default {
  name: 'ObservationCreateCustomerNote',
  props: {
    notes: {
      type: Array,
      default: () => []
    },
    premise: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isSubmit: false,
      isScheduleNote: false,
      form: {
        type: null,
        content: null,
        expireAt: ''
      },
      categoryList: [
        'DEFAULT',
        'CUSTOM',
        'PASSWORD',
        'VACATION',
        'TECHNICAL',
        'SIGNAL_LOST',
        'ALARM',
        'CANCEL_REQUEST',
        'WORK_HOURS',
        'COMMUNICATION',
        'CHANGE_REQUEST'
      ].map(c => ({ text: this.$t(`operation.note-category.${c}`), key: c }))
    }
  },
  methods: {
    isValidForm() {
      let text = null // error message
      if (this.isScheduleNote) {
        if (!this.form.expireAt) text = this.$t('errorMessages.select-date')
      }
      if (!this.form.type) text = this.$t('errorMessages.select-category')
      if (!this.form.content) text = this.$t('errorMessages.enter-description')

      if (text) {
        this.$notify({
          text
        })
        return false
      }

      return true
    },
    resetForm() {
      this.$refs.category.reset()
      this.form.content = ''
      this.form.expireAt = ''
      this.isScheduleNote = false
    },
    async onSubmit() {
      if (!this.isValidForm()) return
      this.isSubmit = true
      const payload = { ...this.form }

      if (!this.isScheduleNote) {
        delete payload.expireAt
      }

      try {
        const { data } = await addCustomerNote({
          ...payload,
          user: this.$store.state.auth.user.id,
          premise: this.premise
        })

        if (data.success) {
          this.$notify({
            text: this.$t('operation.past-action.type.note')
          })
          this.$emit('onUpdateNote')
          this.resetForm()
        }
      } catch (err) {
        console.log(err)
      } finally {
        this.isSubmit = false
      }
    },
    handleChangeCategory(data) {
      let { key } = data
      this.form.type = key
    },
    async handleRemoveNote(id) {
      if (!confirm(this.$t('confirmation.delete'))) return

      try {
        await removeCustomerNote(id)
        this.$notify({
          text: this.$t('operation.past-action.type.note_delete')
        })
        this.$emit('onUpdateNote')
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
input {
  font-size: 12px !important;
  font-weight: 300;
}
.button {
  display: flex;
  align-items: center;
}
</style>
