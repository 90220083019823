export default {
  namespaced: true,
  state: {
    lang: 'tr',
    locales: ['tr', 'en']
  },
  getters: {
    lang: state => state.lang
  },
  mutations: {
    SET_LANG(state, payload) {
      state.lang = payload
      localStorage.setItem('lang', payload)
    }
  },
  actions: {
    setLang({ commit }, lang) {
      commit('SET_LANG', lang)
    }
  }
}
