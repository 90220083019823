<template>
  <details @toggle="onToggle" ref="details">
    <summary class="select" :disabled="disabled"
      >{{ text ? text : $t('dropdown.choose') }}
      <svg-icon-arrow-fill
        class="select__arrow"
        :fill="'transparent'"
      ></svg-icon-arrow-fill>
    </summary>
    <div v-if="isOpen" class="popup">
      <slot />
    </div>
  </details>
</template>

<script>
import { SvgIconArrowFill } from '@/plugins/components'

export default {
  name: 'Dropdown',
  props: {
    text: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isOpen: false
    }
  },
  methods: {
    onToggle(event) {
      this.isOpen = true
      if (event.target.getAttribute('open') != '') {
        this.$emit('onClose')
        this.isOpen = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.popup {
  position: absolute;
  z-index: 99;
  width: 100%;
  border-radius: 2px;
  border: solid 1px var(--input-border);
  background-color: var(--background-color--main);
}

details {
  display: flex;
  position: relative;
  width: 100%;
  max-width: 270px !important;

  summary {
    display: inline-block;
    user-select: none;
    list-style: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    &::-webkit-details-marker {
      display: none;
    }
  }

  &[open] > summary:before {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    display: block;
    cursor: default;
    content: ' ';
    background: transparent;
  }

  &[open] > summary {
    &__arrow {
      &--rotate {
        transform: rotate(180deg);
      }
    }
  }
}

.select {
  width: 100%;
  height: 30px;
  display: flex;
  border-radius: 2px;
  border: solid 1px var(--input-border);
  cursor: pointer;
  position: relative;
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 30px;
  letter-spacing: normal;
  padding: 0px 5px;
  user-select: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &[disabled='disabled'] {
    user-select: none;
    cursor: not-allowed;
    opacity: 0.4;
  }

  &--light {
    // border: solid 1px rgba(207, 209, 215, 0.5);
    color: #7a818f !important;

    > .select__arrow {
      fill: #7a818f;
    }
    > .select__list {
      background-color: #ffffff;
      > .select-option {
        color: #7a818f !important;
      }
    }
  }
  &__arrow {
    position: absolute;
    right: 5px;
    fill: #fff;

    &--rotate {
      transform: rotate(180deg);
    }
  }
  &__list {
    position: absolute;
    top: 30px;
    left: -1px;
    width: calc(100% + 2px);
    border-radius: 2px;
    border: solid 1px rgba(233, 235, 242, 0.2) !important;
    background-color: #0a0f28 !important;
    padding: 5px;
    z-index: 9;
    max-height: 250px;
    overflow: auto;

    input {
      margin: 5px 0;
    }
  }
}
</style>
