import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import i18n from '@/plugins/i18n'
import RouterMiddleware from './middleware'
// import LogRocket from 'logrocket'

import Login from '@/views/login.vue'
// import { FormForgot, FormResetPassword } from '@/plugins/components'
import FormLogin from '../components/form/form-login.vue'
import FormForgot from '../components/form/form-forgot.vue'
import FormResetPassword from '../components/form/form-reset-password.vue'

import LiveSignals from '@/views/live-signals.vue'
import PastSignals from '@/views/past-signals.vue'

import Observation from '@/views/live-tasks.vue'
import InformedObservations from '@/views/informed-observations.vue'
import ObservationDetail from '@/views/observation-detail.vue'

Vue.use(VueRouter)
// LogRocket.init('ykn52o/test-dmc')`

const routes = [
  {
    path: '/',
    name: 'main',
    redirect: { name: 'login' },
    component: Login,
    beforeEnter: RouterMiddleware,
    meta: {
      layout: 'layout-anon'
    },
    children: [
      {
        path: '/login',
        name: 'login',
        component: FormLogin
      },
      {
        path: '/forgot-password',
        name: 'login-forgot',
        component: FormForgot
      },
      {
        path: '/reset-password',
        name: 'login-reset',
        component: FormResetPassword
      },
      {
        path: '/user-registration',
        name: 'user-registration',
        component: FormResetPassword
      }
    ]
  },
  {
    path: '/observation/:category',
    name: 'observation',
    beforeEnter: RouterMiddleware,
    component: Observation,
    meta: {
      requiresAuth: true,
      isInformeds: false
    }
  },
  {
    path: '/informed/:category?',
    name: 'informed',
    beforeEnter: RouterMiddleware,
    component: InformedObservations,
    props: route => ({ category: route.params.category || 'general' }),
    meta: {
      requiresAuth: true,
      isInformeds: true
    }
  },
  {
    path: '/observation/detail/:id',
    name: 'observationDetail',
    beforeEnter: RouterMiddleware,
    component: ObservationDetail,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/informed/detail/:id',
    name: 'informedDetail',
    beforeEnter: RouterMiddleware,
    component: ObservationDetail,
    meta: {
      requiresAuth: true,
      isInformeds: true
    }
  },
  {
    path: '/signals',
    name: 'live-signals',
    component: LiveSignals,
    beforeEnter: RouterMiddleware,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/past-signals',
    name: 'past-signals',
    component: PastSignals,
    beforeEnter: RouterMiddleware,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '*',
    redirect: { name: 'login' }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // Page title
  document.title = 'Hybrone | Data Monitoring Center'

  if (to.name === 'informed' && !to.params.category) {
    next({
      name: 'informed',
      params: {
        category: 'general'
      }
    })
  }

  if (store.state.auth.isLoggedIn && to.name == 'login') {
    next({
      name: 'observation',
      params: { category: 'general' }
    })
  }

  if (typeof to.name === 'undefined') {
    if (!store.state.auth.isLoggedIn) {
      next({
        path: '/login'
      })
    } else {
      next({
        path: '/dashboard'
      })
    }
  }
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.state.auth.isLoggedIn) {
      next({
        path: '/login'
      })
    } else {
      next()
    }
  } else {
    next()
  }
  const lang = to.query.lang
  if (lang && store.state.locale.locales.includes(lang)) {
    i18n.locale = lang
    store.dispatch('locale/setLang', lang)
  }
})

const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error)
}

export default router
