<template>
  <table class="grid-table --dark-theme">
    <thead :style="styles" :class="{ 'opacity-10': loading || !rows.length }">
      <tr class="grid-table__line grid-table__line--unstyle">
        <td
          v-for="(item, key, index) in columns"
          @click="sort({ item, key })"
          :key="index + '-col'"
          :class="{
            'large-td': key == 'location',
            'last-item': index == Object.keys(columns).length - 1,
            'highlight-sort': $route.query.sort == key
          }"
        >
          <span>{{ item }}</span>
          <svg-icon-arrow-fill
            v-if="item && options.column.icon"
            :fill="'transparent'"
            :rotate="$route.query.sort == key ? 'up' : 'down'"
          ></svg-icon-arrow-fill>
        </td>
      </tr>
    </thead>
    <tbody v-if="loading || !rows.length">
      <tr class="loader-grid" v-if="loading || !rows.length">
        <img class="loader" v-if="loading" src="@/assets/image/loader.png" />
        <span
          style="display:grid;grid-row-gap:10px;align-items:center;justify-content:center"
        >
          <p>
            {{
              loading
                ? $t('words.loading')
                : rows.length == 0
                ? emptyMessage
                  ? emptyMessage
                  : $t('words.not-found')
                : ''
            }}
          </p>
        </span>
      </tr>
    </tbody>
    <tbody :class="{ 'opacity-10': loading || !rows.length }">
      <slot name="row" role="tr"></slot>
    </tbody>
  </table>
</template>

<script>
import { SvgIconArrowFill } from '@/plugins/components'

export default {
  name: 'TableAtomic',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    rows: {
      type: Array,
      default: () => []
    },
    columns: {
      type: Object,
      default: () => {}
    },
    styles: {
      type: Object,
      default: () => {}
    },
    options: {
      type: Object,
      default: () => ({
        column: { icon: true }
      })
    },
    isSortIcon: {
      type: Boolean,
      default: true
    },
    emptyMessage: {
      type: String,
      default: null
    }
  },
  methods: {
    sort(item) {
      this.$emit('sort', item)
    }
  }
}
</script>

<style lang="scss" scoped>
.highlight-border {
  border-radius: 4px;
  border: solid 1px #11a3d280;
  padding: 6px 15px;
  color: #11a3d2 !important;
  font-weight: 500 !important;
  background-color: white;
  text-shadow: 0 0 1px #00000024;
}
.highlight-row {
  background-color: #11a3d20c !important;
}
.highlight-sort {
  * {
    font-weight: 700;
  }
}
.opacity-10 {
  opacity: 0.1 !important;
  user-select: none;
  pointer-events: none;
}

.loader-grid {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: auto;
  grid-row-gap: 1rem;
  align-items: center;
  justify-content: center;
  height: max-content;
  padding: 32px 16px;
  position: absolute;
  filter: brightness(0.1);

  .loader {
    margin: auto;
  }

  p {
    color: rgb(44, 44, 44);
  }
}

.--dark-theme {
  thead {
    color: #777a8a;
  }

  > thead > .grid-table__line--unstyle > td {
    cursor: pointer;
    user-select: none;
  }
  > tbody > .grid-table__line {
    background: transparent;
    border: none;
    border-bottom: 1px solid #7a818f25;
    &:hover {
      border-bottom: 1px solid #7a818f25 !important;
      *,
      *:not(.highlight-border) {
        color: black;
        font-weight: 400;
      }
    }
    > td,
    p {
      font-size: 12px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.3;
      letter-spacing: normal;
      color: rgb(58, 58, 58);
      width: 100%;
      display: inline-block;
    }
  }
}
.grid-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  position: relative;
  animation: tableAnimation 0.2s ease both;

  @keyframes tableAnimation {
    from {
      transform-origin: top;
      opacity: 0;
      transform: scale(0.9);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }

  .text {
    &__primary {
      opacity: 1;
    }

    &__secondary {
      font-size: 12px;
      opacity: 0.6;
      margin-top: 5px;
    }
  }

  &__line {
    width: 100%;
    min-height: 50px;
    border-radius: 3px;
    border: solid 1px #cfd1d785;
    background-color: #fff;
    color: rgb(44, 44, 44);
    display: flex;
    align-items: center;
    justify-content: space-between;
    float: left;
    padding: 15px 20px;
    position: relative;

    &.clickable {
      cursor: pointer;
    }

    &:hover {
      border: solid 1px rgba(207, 209, 215, 0.5);
      background-color: #f4f4f8;
    }

    &--unstyle {
      padding: 10px 20px;
      min-height: 50px;
      background: transparent;
      box-shadow: none;
      border: none;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:hover {
        border: none;
        box-shadow: none;
        background: none;
      }
    }

    .large-td {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      &__icon {
        width: 13px;
        height: 16px;
        float: left;
      }

      > p {
        width: calc(100% - 30px);
        float: left;
        margin-left: 7px;
        line-height: 16px;
      }
    }

    .table-badge {
      height: 26px;
      border-radius: 4px;
      background-color: #11a3d2;
      color: #fff;
      text-align: center;
      line-height: 26px;
      font-size: 12px;
      font-weight: 500;

      &--large {
        padding: 0px 15px;
        background: #11a3d2;
      }
    }

    .last-item {
      width: 100%;
      text-align: right;
      display: flex;
      justify-content: flex-end;
      cursor: pointer;
    }

    .image {
      width: 50px !important;
    }

    .gravatar {
      width: 38.7px;
      height: 38.7px;
      border-radius: 5px;
      background-color: #edf4ff;
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;
      color: #061751;
      font-size: 13px;
      font-weight: 300;
    }

    .table-action-icon {
      width: 28px;
      height: 28px;
      border-radius: 28px;
      float: left;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 15px;
      visibility: visible;

      &:hover {
        border: 1px solid #f1f1f6;
      }

      &__item {
        width: 15.5px;
        height: 16px;

        &--big {
          width: 20px;
          height: 19px;
        }
      }
    }

    thead {
      color: #777a8a;
    }

    > td {
      width: 100%;
      font-size: 13px;
      display: flex;
      align-items: center;

      > .grid-table__line-image {
        width: 38px;
        height: 38px;
        border-radius: 5px;
        object-fit: cover;
      }

      > .table-header-icon {
        width: 12px;
        height: 8px;
        float: left;
        margin-left: 4px;
        margin-top: 3px;
      }
      > span {
        float: left;
      }
    }
  }
}
</style>
