<template>
  <div class="header">
    <div
      class="header__logo"
      @click="
        $router.push({ name: 'observation', params: { category: 'general' } })
      "
    ></div>
    <header-navbar></header-navbar>
    <header-avatar></header-avatar>
  </div>
</template>

<script>
import { HeaderAvatar, HeaderNavbar } from '@/plugins/components'

export default {
  name: 'AppHeader'
}
</script>
