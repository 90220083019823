import moment from 'moment-timezone'
import { Turkish } from 'flatpickr/dist/l10n/tr.js'
import flatPickr from 'vue-flatpickr-component'
import store from './../store'
import 'flatpickr/dist/flatpickr.css'

export default {
  components: {
    flatPickr
  },
  created() {},
  data() {
    return {
      datePickrConfig: {
        wrap: false,
        altFormat: 'M j, Y',
        altInput: true,
        dateFormat: 'Z',
        locale: store.state.locale.lang == 'tr' ? Turkish : null
      },
      datePickrConfigWithTime: {
        wrap: false,
        enableTime: true,
        altFormat: 'M j, Y - H:i',
        altInput: true,
        altInputClass: 'input',
        dateFormat: 'Z',
        locale: store.state.locale.lang == 'tr' ? Turkish : null
      },
      datePickrConfigNormal: {
        wrap: false,
        altFormat: 'd.m.Y',
        dateFormat: 'Z',
        altInput: true,
        locale: store.state.locale.lang === 'tr' ? Turkish : null
      },
      datePickrConfigTime: {
        wrap: false,
        enableTime: true,
        noCalendar: true,
        altInput: false,
        altFormat: 'H:i',
        dateFormat: 'H:i'
      }
    }
  },
  methods: {
    dateConvertToFriendlyDate(value, timezone) {
      return moment(new Date(value))
        .tz(store.state.auth.timezone)
        .format('DD.MM.Y')
    },
    dateConvertToUnix(date) {
      if (!date) return
      return moment(date).unix()
    },
    dateConvertToFriendlyString(value, timezone) {
      return moment(new Date(value))
        .tz(timezone)
        .format('DD.MM.Y, HH:mm')
    },
    dateConvertToMonthWithTime(value) {
      return moment(value)
        .tz(store.state.auth.user.timezone)
        .locale('tr')
        .format('Do MMMM YYYY HH:mm:ss')
    }
  },
  filters: {
    filterDateYear(value, timezone, locale) {
      return moment(new Date(value))
        .tz(timezone)
        .format('DD.MM.Y')
    },
    filterDateTime(value, timezone, locale) {
      return moment(new Date(value))
        .tz(timezone)
        .locale(locale)
        .format('HH:mm')
    },
    filterDateYearAndTime(value, timezone, locale) {
      return moment(new Date(value))
        .tz(timezone)
        .format('DD.MM.Y, HH:mm')
    }
  }
}
