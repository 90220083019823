<template>
  <div class="past-actions-wrapper">
    <div class="event-detail">
      <div class="event-detail-header">
        <div class="event-detail-header__title">
          {{ $t('words.event-detail') }}
        </div>
      </div>
      <observation-event-detail-content
        :data="data"
        :isTicketCreated="isTicketCreated"
        @onUpdated="value => $emit('onUpdated', value)"
      ></observation-event-detail-content>
      <ul v-if="!isInformed" class="event-detail-header__button-group">
        <li
          class="event-detail-header__button-group__item"
          v-for="(item, key) in status"
          :key="key + '-event-detail-li'"
        >
          <status-button
            :data="item"
            :isClicked="isClicked"
            @changeTaskStatus="data => handleClickStatusButton(data, item)"
          ></status-button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {
  StatusButton,
  ObservationEventDetailContent
} from '@/plugins/components'
import moment from 'moment-timezone'

export default {
  name: 'ObservationEventDetail',
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    isTicketCreated: {
      type: Boolean,
      default: false
    },
    isClicked: {
      type: Boolean,
      default: false
    },
    isInformed: {
      type: Boolean,
      default: false
    },
    isCalling: {
      type: Boolean
    }
  },
  data() {
    return {
      status: [
        {
          status: 'leave',
          click: () => {
            this.$emit('changeTaskStatus', {
              // taskId: this.data.id,
              // status: 'OPEN',
              // assignee: {},
              // postponedAt: ''
              type: 'unassign'
            })
          }
        },
        {
          status: 'POSTPONED',
          click: data => {
            let date = new Date(data)
            this.$emit('changeTaskStatus', {
              // taskId: this.data.id,
              // status: 'POSTPONED',
              // assignee: {},
              type: 'postpone',
              postponed_at: moment(date).toISOString()
            })
          },
          isTime: true
        },
        {
          status: 'CLOSED',
          click: () => {
            this.$emit('changeTaskStatus', {
              // taskId: this.data.id,
              status: 'CLOSED',
              assignee: {},
              postponedAt: null,
              type: 'close'
            })
          },
          isPrimary: true
        }
      ]
    }
  },
  methods: {
    handleClickStatusButton(data, item) {
      if (this.isCalling) {
        console.log(this.isCalling)
        this.$notify({
          type: 'warn',
          text: 'Çağrı devam ederken işlem yapamazsınız'
        })
        return
      }
      if (!this.isClicked) {
        item.click(data && data)
      }
    }
  }
}
</script>
