<template>
  <component
    :is="_tag"
    class="radio-group"
    :class="direction == 'row' ? 'direction-row' : 'direction-column'"
    role="radiogroup"
    @keydown="handleKeyDown"
    @change="handleChange"
    @blur="focus"
  >
    <slot></slot>
  </component>
</template>

<script>
export default {
  name: 'RadioGroup',
  props: {
    direction: {
      type: String,
      default: 'row',
      validator: function(value) {
        return ['row', 'column'].indexOf(value) !== -1
      }
    }
  },
  computed: {
    _tag() {
      return (this.$vnode.data || {}).tag || 'div'
    }
  },
  methods: {
    handleKeyDown(e) {
      this.$emit('input', e.target.value)
    },
    handleChange(e) {
      this.$emit('change', e.target.value)
    },
    focus(e) {
      console.log(e)
    }
  }
}
</script>
