<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
  >
    <g fill="none" fill-rule="evenodd">
      <path
        :fill="fill"
        :stroke="stroke"
        fill-rule="nonzero"
        d="M18.918 1.463C18.782 1.18 18.496 1 18.182 1H1.818c-.314 0-.601.18-.738.464-.136.283-.097.62.1.866l6.365 7.957v7.895c0 .33.2.63.505.756.1.041.206.062.314.062.217 0 .425-.086.578-.24l3.273-3.272c.153-.154.24-.362.24-.579v-4.622L18.82 2.33c.196-.246.234-.583.097-.867z"
        transform="translate(-1 -1)"
      />
      <path d="M0 0H20V20H0z" transform="translate(-1 -1)" />
    </g>
  </svg>
</template>
<script>
export default {
  name: 'SvgIconFilter',
  props: {
    fill: {
      type: String,
      default: ''
    },
    stroke: {
      type: String,
      default: '#031141'
    },
    strokeWidth: {
      type: String,
      default: '.1'
    }
  }
}
</script>
