<template>
  <details @toggle="onToggle" ref="advanced-filter">
    <summary class="button btn-filter" :disabled="disabled">
      <svg-icon-filter :fill="isFiltered ? '#fff' : null"></svg-icon-filter>
    </summary>
    <div v-if="isOpen" class="popup">
      <div class="popup__grid">
        <div class="popup__grid__btn-group">
          <p @click="removeFilter" role="button">
            {{ $t('signals.filter.clear-select') }}
          </p>
          <p @click="saveAndClose" role="button" class="button button--filter">
            {{ $t('signals.filter.save') }}
          </p>
        </div>
        <filter-collapser
          v-for="(filter, index) in filters"
          :isClearAll="isClearAll"
          :key="index"
          :options="filter"
          @onChangeItem="$emit('onChangeItem', $event)"
        ></filter-collapser>
      </div>
    </div>
  </details>
</template>

<script>
import { FilterCollapser } from '@/plugins/components'

export default {
  name: 'FilterAdvanced',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    filters: {
      type: Array,
      default: () => []
    },
    clearFields: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    isFiltered() {
      let isFiltered = false
      if (this.filters.length > 0) {
        this.filters.forEach(item => {
          if (Object.keys(this.$route.query).includes(item.key)) {
            isFiltered = true
            return
          }
        })
      }

      return isFiltered
    }
  },
  data() {
    return {
      isClearAll: false,
      isOpen: false
    }
  },
  methods: {
    getQueryStringValue(key) {
      return decodeURIComponent(
        /* eslint-disable */
        window.location.search.replace(
          new RegExp(
            '^(?:.*[&\\?]' +
              encodeURIComponent(key).replace(/[\.\+\*]/g, '\\$&') +
              '(?:\\=([^&]*))?)?.*$',
            'i'
          ),
          '$1'
        )
      )
    },
    saveAndClose() {
      this.$refs['advanced-filter'].removeAttribute('open')
      this.$router.replace({ query: { ...this.$route.query, page: 1 } })
      location.reload()
    },
    onToggle(event) {
      this.isOpen = true
      if (event.target.getAttribute('open') != '') {
        this.$emit('onClose')
        this.isOpen = false
      }
    },
    removeFilter() {
      let query = { ...this.$route.query }
      if (!!this.clearFields.length) {
        this.clearFields.forEach(key => {
          delete query[key]
        })
      }
      this.isOpen = false
      this.$router.push({ query })
      location.reload()
    }
  }
}
</script>

<style lang="scss">
.btn-filter {
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  height: 30px;
  color: #031141 !important;
  background-color: transparent;
  border: solid 1px var(--input-border);
  display: flex;
  align-items: center;
}

.popup {
  position: absolute;
  z-index: 99;
  width: 300px;
  right: 0;
  top: 40px;
  border-radius: 2px;
  border: solid 1px var(--input-border);
  background-color: var(--background-color--main);
  overflow: auto;
  max-height: 600px;

  &__grid {
    display: grid;
    grid-template-rows: auto;
    grid-row-gap: 20px;
    padding: 15px;

    &__item {
      border-top: 1px solid var(--input-border);
    }

    &__btn-group {
      display: grid;
      grid-template-columns: 1fr auto;
      align-items: center;
      position: sticky;
      top: 12px;
      z-index: 9;
      background-color: var(--background-color--main);
      box-shadow: 0px -20px 0 20px var(--background-color--main);

      p {
        font-size: 12px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        cursor: pointer;
        user-select: none;
        &.primary {
          color: #11a3d2;
        }
      }
    }
  }
}

details {
  display: inline-flex !important;
  position: relative;
  width: 100%;
  max-width: max-content;

  summary {
    display: inline-flex !important;
    user-select: none;
    list-style: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    &::-webkit-details-marker {
      display: none;
    }
  }

  &[open] > summary:before {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    display: block;
    cursor: default;
    content: ' ';
    background: transparent;
  }

  &[open] > summary {
    &__arrow {
      &--rotate {
        transform: rotate(180deg);
      }
    }
  }
}
</style>
