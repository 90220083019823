<template>
  <button :class="buttonClasses" @click="onClick" :disabled="loading">
    <img v-if="loading" class="loader" src="@/assets/image/loader.png" />
    <img :src="icon" v-if="!loading && icon" />
    <p>{{ text }}</p>
  </button>
</template>
<script>
export default {
  name: 'Button',
  props: {
    className: {
      type: String,
      default: 'btn'
    },
    text: {
      type: String,
      default: 'Button'
    },
    loading: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: null
    }
  },
  computed: {
    buttonClasses() {
      return 'btn ' + this.className
    }
  },
  methods: {
    onClick(e) {
      this.$emit('click', e)
    }
  }
}
</script>

<style lang="scss">
.btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 0 30px;
  border-radius: 3px;
  font-size: 14px;
  cursor: pointer;
  outline: none;
  width: 100%;

  img {
    margin-right: 8px;
  }

  &[height='30'] {
    height: 30px;
  }
  &[width='100'] {
    width: 100px;
  }
  &[width='150'] {
    width: 150px;
  }
  &[width='200'] {
    width: 200px;
  }
  &[width='300'] {
    width: 300px;
  }
  // & + & {
  //   margin-left: 1rem;
  // }
  &:hover {
    filter: brightness(0.9);
  }
  & > p {
    margin-right: 5px;
  }
  &[disabled='disabled'] {
    opacity: 0.7;
    user-select: none;
  }
  &.fill {
    color: white !important;
    &.primary {
      background-color: var(--button-primary);
    }
    &.secondary {
      background-color: var(--button-secondary);
    }
    &.tertiary {
      background-color: var(--button-tertiary);
    }
  }
  &.border {
    color: gray;
    border: 1px solid gray;
    background-color: transparent;
    &.primary {
      color: var(--button-primary);
      border-color: var(--button-primary);
    }
    &.secondary {
      color: var(--button-secondary);
      border-color: var(--button-secondary);
    }
    &.tertiary {
      color: var(--button-tertiary);
      border-color: var(--button-tertiary);
    }
  }
  &.text {
    transition: 1s ease;
    color: gray;
    background-color: transparent;
    &.primary {
      color: var(--button-primary);
    }
    &.secondary {
      color: var(--button-secondary);
    }
    &.tertiary {
      color: var(--button-tertiary);
    }
  }
}
</style>
